// import createHistory from "history/createBrowserHistory"

// export const history = createHistory()

import { createBrowserHistory } from "history/esm/history"

const history = createBrowserHistory()

export default history

//  this should work but doesn't
