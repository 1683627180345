import React from "react"
import Search from "../../containers/global/Search"
import classNames from "classnames/bind"
import styles from "./mobile-search-overlay"

const cx = classNames.bind(styles)

const MobileSearchOverlay = ({ handleClick, customCrossIcon }) => (
  <div className={cx("container")}>
    <div className={cx("inner")}>
      <Search />
      <button className={cx("button")} onClick={handleClick}>
        {customCrossIcon}
      </button>
    </div>
  </div>
)

export default MobileSearchOverlay
