import React, { useState } from "react"
import styles from "./membership-settings"
import classNames from "classnames/bind"
import ProfileFormHeading from "../ProfileFormHeading"
import SubscriptionInfo from "../SubscriptionInfo"
import { format, parseISO } from "date-fns"
import ArtistUpdatePaymentMethod from "../ArtistUpdatePaymentMethod"
import NonArtistUpdatePaymentMethod from "../NonArtistUpdatePaymentMethod"

const cx = classNames.bind(styles)

const couponCoversMembershipCost = (coupon) =>
  coupon && coupon.percentOff === 100 && coupon.duration === "forever"

const MembershipSettings = ({ user: { createdAt, activePaymentMethod }, isArtist }) => {
  const { last4, cardType, coupon, paypalId } = activePaymentMethod || {}
  const isPaymentVisible = !couponCoversMembershipCost(coupon)
  const createdDate = createdAt?.split(" ")[0]

  const [isPaymentUpdateNoticeVisible, showPaymentUpdateNotice] = useState(false)

  return (
    <div className={cx("container")}>
      {isPaymentUpdateNoticeVisible && (
        <div className={cx("payment-update-notice")}>
          <p>Your payment method was successfully updated</p>
          <button onClick={() => showPaymentUpdateNotice(false)} className={cx("close-notice")}>
            &times;
          </button>
        </div>
      )}
      <ProfileFormHeading label={"Membership"} />
      {createdDate && (
        <div className={cx(["field", "first"])}>
          <div className={cx("label")}>{"Member Since"}</div>

          <div className={cx("info")}>{format(new Date(parseISO(createdDate)), "MMMM d, yyyy")}</div>
        </div>
      )}

      {isArtist && <SubscriptionInfo coupon={coupon} />}

      {isPaymentVisible &&
        (isArtist ? (
          <ArtistUpdatePaymentMethod
            isPaypal={paypalId !== null}
            cardType={cardType}
            last4={last4}
            onPaymentMethodUpdated={() => showPaymentUpdateNotice(true)}
          />
        ) : (
          <NonArtistUpdatePaymentMethod onPaymentMethodUpdated={() => showPaymentUpdateNotice(true)} />
        ))}
    </div>
  )
}

export default MembershipSettings
