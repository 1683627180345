import React from "react"
import { array, func } from "prop-types"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ArtworkList from "../../../components/ArtworkList"
import ArtworkListItem from "../../../components/ArtworkListItem"

const SortableArtworkList = ({
  artworks,
  onPublish,
  onDelete,
  onEdit,
  onOrderChange,
  sortingDisabled,
}) => {
  const handleOnDragEnd = ({ source, destination }) => {
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const oldPosition = source.index
    const newPosition = destination.index
    const artwork = artworks[oldPosition]

    onOrderChange(artwork, newPosition)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable
        droppableId="characters"
        isDroppableDisabled={sortingDisabled}
      >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <ArtworkList>
              {artworks?.map((artwork, index) => (
                <Draggable
                  key={artwork.id}
                  draggableId={artwork.id}
                  index={index}
                  isDragDisabled={sortingDisabled}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ArtworkListItem
                        {...artwork}
                        image={artwork.primaryImage ? artwork.primaryImage.thumb : ""}
                        onDelete={() => onDelete(artwork.id)}
                        onEdit={() => onEdit(artwork)}
                        onPublishChange={(published) => onPublish(artwork.id, published)}
                        isProcessing={!artwork.readyToShow}
                        processingProgress={artwork.uploadProgress}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ArtworkList>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

SortableArtworkList.propTypes = {
  artworks: array.isRequired,
  onPublish: func,
  onDelete: func,
  onEdit: func,
  onOrderChange: func,
}

export default SortableArtworkList
