import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import EntryForm from "../../../components/EntryForm"
import LoginFlowWrapper from "../../../components/LoginFlowWrapper"
import SignupNote from "../../../components/SignupNote"
import { homeRoute, forgotPasswordFields } from "../../../constants"
import { forgotPassword } from "../../../services/auth"
import { actions } from "../../../actions"

const copy = `
  <p>
    Enter the email address associated with your account and we’ll email you a link to reset your password.
  </p>
`

class ForgotPasswordWrapper extends Component {
  componentDidMount() {
    document.querySelector("html").classList.remove("no-scroll")
  }

  render() {
    const { error, loading, onSubmit } = this.props
    return (
      <div>
        <LoginFlowWrapper
          title={"Reset Your Password"}
          buttonText={"Send Reset Link"}
          onSubmit={onSubmit}
          error={error}
          loading={loading}
        >
          <SignupNote copy={copy} />
          <EntryForm fields={forgotPasswordFields} />
        </LoginFlowWrapper>
      </div>
    )
  }
}

const mapStateToProps = ({ authForm }) => {
  const { error, loading } = authForm
  return { error, loading }
}

const mapDispatchToProps = dispatch => {
  const {
    requestAuthFormSubmit,
    receiveAuthFormSubmit,
    authFormSubmitFailure
  } = actions

  return {
    onSubmit: form => {
      dispatch(requestAuthFormSubmit())
      forgotPassword(form, res => {
        const { err, response } = res
        if (err || !response.ok) {
          dispatch(
            authFormSubmitFailure({
              error: "An error occurred"
            })
          )
          return
        }
        dispatch(receiveAuthFormSubmit())
        dispatch(push(homeRoute))
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordWrapper)
