import React from "react"
import Input, { InputProps } from "./Input"
import { LabelSize } from "@/ui/forms/Label"
import FormFieldBase, { FormFieldBaseProps } from "@/ui/forms/FormFieldBase"

// TODO
// Talk to Adam about putting the error message on the top right side of the field next to the label, like shown in
// https://www.radix-ui.com/docs/primitives/components/form

export type FormFieldProps = Omit<FormFieldBaseProps, "children"> & InputProps

const FormField = (
  {
    name,
    label,
    labelSize = LabelSize.SMALL,
    error,
    ...inputProps
  }: FormFieldProps,
) => (
  <FormFieldBase name={name} label={label} labelSize={labelSize} error={error}>
    <Input name={name} {...inputProps} />
  </FormFieldBase>
)

export default FormField
