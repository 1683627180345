import React, { Component } from "react"
import styles from "./profile"
import classNames from "classnames/bind"
import Header from "../../../containers/cms/Header"
import ProfileWrapper from "../../../containers/cms/ProfileWrapper"
import CmsBackground from "../../../components/CmsBackground"

const cx = classNames.bind(styles)

class Profile extends Component {
  render() {
    return (
      <div className={cx("")}>
        <Header title="Artist Profile" />
        <CmsBackground>
          <ProfileWrapper />
        </CmsBackground>
      </div>
    )
  }
}

export default Profile
