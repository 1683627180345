import React from "react"
import { Link, NavLink } from "react-router-dom"
import { artistSearchRoute, artworkSearchRoute, dialoguesRoute, guestCuratorsRoute } from "../../constants.js"
import styles from "./home"
import classNames from "classnames/bind"

import ReactMarkdown from "react-markdown"

import ConnectedCarousel from "../../containers/ConnectedCarousel"
import CuratorCarouselSlide from "../CuratorCarouselSlide"
import DialogueCarouselSlide from "../DialogueCarouselSlide"
import Carousel from "../Carousel"

const cx = classNames.bind(styles)

const Home = ({ loggedIn, className, sections, content }) => {
  const bannerSection = sections.find(({ slug }) => slug === "homepage-hero-banner")

  return (
    <div>
      {bannerSection && <Banner {...bannerSection} />}
      <div className={cx(["container", { loggedOut: !loggedIn }])}>
        <div className={cx("intro")}>
          {!loggedIn && <ReactMarkdown className={cx("copy")} children={content} escapeHtml={false} />}

          <div className={cx("buttons")}>
            <Link to={artistSearchRoute} className={cx(["btn-blue", "hero-button"])}>
              <span className={cx("label")}>Research Artists</span>
            </Link>

            <Link to={artworkSearchRoute} className={cx(["btn-dark-gray", "hero-button"])}>
              <span className={cx("label")}>View Artwork</span>
            </Link>
          </div>
        </div>

        <div className={cx("homepage-artwork-carousel")}>
          <ConnectedCarousel name="home" />
        </div>
        <Sections sections={sections} />
      </div>
    </div>
  )
}

const Banner = ({ sections }) => (
  <div className={cx("banner-wrapper")}>
    {sections.length > 1 ? (
      <Carousel>
        {sections.map((slide, index) => (
          <BannerSlide key={index} {...slide} />
        ))}
      </Carousel>
    ) : (
      <BannerSlide {...sections[0]} />
    )}
  </div>
)

const BannerSlide = ({ image, title, content }) => {
  return (
    <a className={cx("banner-slide")} href={content}>
      <div className={cx("banner-slide-background")}>
        <img alt={image.title} src={image.url} />
      </div>
      <h1>{title}</h1>
    </a>
  )
}

const Sections = ({ sections }) =>
  sections.map((section, index) => {
    switch (section.slug) {
      case "homepage-guest-curators-carousel":
        return (
          <div key={index} className={cx(section.slug)}>
            <NavLink exact to={guestCuratorsRoute} className={cx("header-link")}>
              <h1 className={cx("section-title")}>{section.title}</h1>
            </NavLink>
            <div className={cx("carousel-wrapper")}>
              <Carousel>
                {section.sections.map((curator) => (
                  <CuratorCarouselSlide {...curator} key={curator.id} />
                ))}
              </Carousel>
            </div>
          </div>
        )
      case "homepage-dialogues-carousel":
        return (
          <div key={index} className={cx(section.slug)}>
            <NavLink exact to={dialoguesRoute} className={cx("header-link")}>
              <h1 className={cx("section-title")}>{section.title}</h1>
            </NavLink>
            <div className={cx("carousel-wrapper")}>
              <Carousel>
                {section.sections.map((dialogue) => (
                  <DialogueCarouselSlide {...dialogue} key={dialogue.id} />
                ))}
              </Carousel>
            </div>
          </div>
        )
      case "homepage-bottom-banner":
        return (
          <div key={index} className={cx("open-call")}>
            <div className={cx("copy")}>
              <h1>{section.title}</h1>
              <ReactMarkdown className={cx("copy")} children={section.content} escapeHtml={false} />
            </div>
          </div>
        )
      default:
        return null
    }
  })

export default Home
