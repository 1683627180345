import GuestCard from "../../components/GuestCard"

import React, { Component } from "react"
import { connect } from "react-redux"
import { getSection } from "../../services/section"
import { actions } from "../../actions.js"
import LoadingSpinner from "../../components/LoadingSpinner"

class GuestSelectionsDetailPageContainer extends Component {
  componentDidMount() {
    const { slug } = this.props.match.params
    this.props.getSection(slug)
  }

  componentWillUnmount() {
    this.props.clearSection()
  }

  render() {
    return (
      <React.Fragment>
        {this.props.title ? (
          <GuestCard
            {...this.props}
            setMetaTags={true}
            displayBackToGuestSelectionsPageLink={true}
            displayExpandToggle={false}
          />
        ) : (
          <LoadingSpinner />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { section } = state
  return { index: 0, ...section }
}

const mapDispatchToProps = dispatch => {
  const { getSectionSuccess, clearSection } = actions

  return {
    getSection: slug => {
      getSection(slug, ({ err, section }) => {
        if (!err && section) {
          dispatch(getSectionSuccess({ section }))
        } else {
          console.error(err)
        }
      })
    },
    clearSection: () => dispatch(clearSection())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestSelectionsDetailPageContainer)
