import FullOverlay from "../../../components/FullOverlay"
import React from "react"
import styles from "./profileNotReady"
import classNames from "classnames/bind"

const ProfileNotReadyNotice = ({close}) => {
  const cx = classNames.bind(styles)

  return (
    <FullOverlay>
      <div className={cx("container")}>
        <h1>Profile not complete</h1>
        <p className={cx("notice")}>
          To complete your profile, please add the required information (marked with an *) and select a Profile Image.
        </p>
        <button
          className={cx("btn btn-blue")}
          onClick={close}
        >
          OK
        </button>
      </div>
    </FullOverlay>
  )
}

export default ProfileNotReadyNotice
