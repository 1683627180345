import React, { ReactNode, useMemo } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

const buildStripeConnector = (stripeKey?: string) => {
  if (stripeKey === undefined) {
    throw Error("Couldn't find a Stripe key. Please make one available in the application environment at build time.")
  }

  return ({ children }: { children: ReactNode }) => {
    const stripe = useMemo(() => loadStripe(stripeKey), [stripeKey])

    return (
      <Elements
        stripe={stripe}

        options={{
          fonts: [{
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700",
          }],
        }}
      >
        {children}
      </Elements>
    )
  }
}

export default buildStripeConnector
