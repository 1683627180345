import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import Helmet from "../components/Helmet"
import Routes from "../routes"
import store from "../reducers"
import { ApolloProvider } from "@apollo/client"
import { client } from "../services/apolloEnv"
import PaypalSubscriptionConnector from "@/payments/paypal/PaypalSubscriptionConnector"
import StripeSubscriptionsConnector from "@/payments/stripe/StripeSubscriptionsConnector"

const title = "Foundwork | An Artist Platform for the Contemporary Art Community"
const description =
  "Foundwork is a platform designed to help connect artists with curators, gallerists, and other collaborators wherever they are located."

const render = (Component) => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <StripeSubscriptionsConnector>
          <PaypalSubscriptionConnector>
            <>
              {/*<GlobalStyles />*/}
              <Helmet title={title} description={description} />
              <Component />
            </>
          </PaypalSubscriptionConnector>
        </StripeSubscriptionsConnector>
      </Provider>
    </ApolloProvider>,
    document.getElementById("root")
  )
}

document.addEventListener("DOMContentLoaded", () => render(Routes))

if (module.hot) {
  module.hot.accept("../routes", () => {
    render(Routes)
  })
}
