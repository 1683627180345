import React from "react"
import {useMutation, useQuery} from "@apollo/client"
import {connect} from "react-redux"
import {BillingSettingsQuery, UpdateBillingInformation} from "../../../queries/artists"
import BillingSettings from "../../../components/BillingSettings"

const BillingSettingsQueryContainer = ({id}) => {
  const response = useQuery(BillingSettingsQuery, {
    variables: {id},
  })
  const {data} = response || {}
  const {user} = data || {}

  const [mutate] = useMutation(UpdateBillingInformation)

  const updateSettings = (settings) =>
    mutate({
      variables: {
        id,
        ...settings,
      },
      refetchQueries: [
        {
          query: BillingSettingsQuery,
          variables: {
            id,
          },
        }
      ]
    })


  return user ? <BillingSettings
    user={user}
    onSave={updateSettings}
  /> : null
}

const mapStateToProps = ({session}) => ({id: session?.userId})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettingsQueryContainer)
