import React, {useState} from "react"
import {string} from "prop-types"
import {connect} from "react-redux"
import {NavLink} from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./header"
import {
  artistDetailRouteFn,
  editArtistCvRouteFn,
  editArtistProfileRouteFn,
  editArtistStatementRouteFn,
  editArtistWorksRouteFn
} from "../../../constants.js"
import ProfileNotReadyNotice from "./profileNotReadyNotice"
import profileIsReady from "./profileIsReady"
import {push} from "connected-react-router"

const cx = classNames.bind(styles)

const Header = ({title, slug, goToPublicProfileOf}) => {
  const [showNotice, setNoticeVisibility] = useState(false)

  const showNoticeOrRouteToProfilePage = async (slug) => {
    const ready = await profileIsReady(slug)
    if (ready) {
      goToPublicProfileOf(slug)
    } else {
      setNoticeVisibility(true)
    }
  }

  return (
    <>
      {
        showNotice &&
        <ProfileNotReadyNotice close={() => setNoticeVisibility(false)}/>
      }
      <div className={cx("container")}>
        <div className={cx("title-container")}>
          <div className={cx("title")}>
            <h3>
              <span className={cx("cms")}>CMS / </span>
              <span className={cx("name")}>{title}</span>
            </h3>
          </div>
        </div>
        <div className={cx("subheader")}>
          <ul className={cx("links")}>
            <li>
              <NavLink to={editArtistWorksRouteFn(slug)}>Works</NavLink>
            </li>
            <li>
              <NavLink to={editArtistCvRouteFn(slug)}>CV</NavLink>
            </li>
            <li>
              <NavLink to={editArtistStatementRouteFn(slug)}>
                Artist Statement
              </NavLink>
            </li>
            <li>
              <NavLink to={editArtistProfileRouteFn(slug)}>Artist Profile</NavLink>
            </li>
          </ul>
          <div className={cx("profile-link-container")}>
            <a
              href={artistDetailRouteFn(slug)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault()
                showNoticeOrRouteToProfilePage(slug)
              }}
            >
              View Profile &rarr;
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  title: string.isRequired,
  slug: string.isRequired
}

const mapStateToProps = ({session}) => ({slug: session.userSlug})

const mapDispatchToProps = (dispatch) => {
  return {
    goToPublicProfileOf: (slug) => dispatch(push(artistDetailRouteFn(slug)))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
