import CurrentUser from "../currentUser"
import { graphqlUrl, basicAuth, websocketUrl } from "./env"
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache
} from "@apollo/client"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"

const token = CurrentUser.token()

const httpLink = new HttpLink({ uri: graphqlUrl })

const wsURL = new URL(websocketUrl)
wsURL.searchParams.set("token", token)

const cable = createConsumer(wsURL)

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers

  operation.setContext({
    headers: {
      token: token ? `Bearer ${token}` : null,
      Authorization: basicAuth.enabled ? basicAuth.header : null
    }
  })

  return forward(operation)
})

const hasSubscriptionOperation = ({ query: { definitions }, getContext }) => {
  return definitions.some(({ kind, operation }) => {
    return (kind === "OperationDefinition" && operation === "subscription") || getContext().useWebsocket
  })
}

const httpOrWsLink = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({cable}),
  httpLink
)

export const client = new ApolloClient({
  link: authMiddleware.concat(httpOrWsLink),
  cache: new InMemoryCache()
})
