import React from "react"
import { PagingDots } from "nuka-carousel"
import classNames from "classnames/bind"
import styles from "./carousel"

const cx = classNames.bind(styles)
const MAX_DOTS = 9

const CustomDots = ({
  slideCount, slidesToScroll, currentSlide, goToSlide
}) => {

  const indexes = getIndexes(slideCount, slidesToScroll).slice(
    0,
    MAX_DOTS
  )

  return (
    <ul className={cx("controls-dots")}>
      {indexes.map(i => (
        <li key={i} className={cx("dot-wrapper")}>
          <button
            onClick={goToSlide.bind(null, i)}
            className={cx("dot", i === currentSlide ? "current" : null)}
          >
            •
          </button>
        </li>
      ))}
    </ul>
  )
}

const getIndexes = (count, inc) => {
  const arr = []
  for (let i = 0; i < count; i += inc) {
    arr.push(i)
  }
  return arr
}


export default CustomDots
