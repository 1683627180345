import React from "react"
import { connect } from "react-redux"
import { string, func, array } from "prop-types"
import styles from "./sorting-options"
import classNames from "classnames/bind"
import { actions } from "../../../actions"
import Dropdown from "../../../components/Dropdown"

const cx = classNames.bind(styles)

const SortingOptions = ({
  name,
  label,
  format,
  options,
  updateOrder,
  order
}) => {
  return (
    <div className={cx("default")}>
      <div className={cx("options")}>
        <div className={cx("format")} />
        <div className={cx("sort")}>
          <Dropdown
            className={cx("dropdown")}
            name={name}
            label={label}
            onChange={val => updateOrder(format, val)}
            options={options}
            value={order[format]}
          />
        </div>
      </div>
    </div>
  )
}

SortingOptions.propTypes = {
  format: string.isRequired,
  options: array.isRequired,
  updateOrder: func
}

const mapStateToProps = ({ search }) => {
  const { order } = search
  return { order }
}

const mapDispatchToProps = dispatch => {
  return {
    updateOrder: (format, order) => {
      dispatch(actions.updateSearchOrder({ format, order }))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SortingOptions)
