import React, { useState } from "react"
import Title from "@/ui/text/Title"
import TextBlock from "@/ui/text/TextBlock"
import PageMetadata from "./utils/PageMetadata"
import PageSection from "@/ui/pages/PageSection"
import FormField from "@/ui/forms/FormField"
import CreditCardInput from "@/ui/forms/CreditCardInput"
import FieldRow from "@/ui/forms/FieldRow"
import Form, { FormButton } from "@/ui/forms/Form"
import AmountSelector from "@/ui/forms/AmountSelector"
import DonationHandler from "@/donation/DonationHandler"
import ConfirmationDialog from "@/ui/layout/ConfirmationDialog"
import { useHistory } from "react-router"
import { homeRoute } from "../../constants"
import ReactMarkdown from "react-markdown"
import useContentfulSection from "@/contentful/useContentfulSection"
import LoadingFrame from "@/ui/elements/LoadingFrame"

// TODO
// - toast to show error

const Donate = () => {
  const [description, setDescription] = useState<string | null>(null)

  useContentfulSection("support", ({ content }) => setDescription(content))

  const history = useHistory()

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showErrorBanner, setShowErrorBanner] = useState(false)

  const closeAndGoHome = () => {
    setShowConfirmationDialog(false)
    history.push(homeRoute)
  }

  if (!description) return <LoadingFrame />

  return (
    <>
      {/*TODO Ask Adam for some description text (good for SEO)*/}
      <PageMetadata title="Foundwork | Donate" description="" />
      <PageSection>
        <Title>Support Foundwork</Title>
        <ReactMarkdown
          children={description}
          components={{
            p: ({ children }) => <TextBlock $textAlign="center">{children}</TextBlock>,
          }}
        />
      </PageSection>
      <PageSection>
        <DonationHandler
          onDonationSuccessful={() => setShowConfirmationDialog(true)}
          onDonationError={() => setShowErrorBanner(true)}
        >
          {({
            values: { firstName, lastName, email },
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            submitForm,
          }) => (
            <Form>
              <AmountSelector
                name="donationAmount"
                label="Donation Amount"
                amounts={[5, 10, 25, 50, 100].map((amount) => ({ value: amount * 100, label: `$${amount}` }))}
                min={500}
                onAmountUpdated={(amount) => setFieldValue("donationAmount", amount)}
                error={touched.donationAmount ? errors.donationAmount : undefined}
              />
              <FieldRow>
                <FormField
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  $onValueChange={(v) => setFieldValue("firstName", v)}
                  onBlur={() => setFieldTouched("firstName")}
                  error={touched.firstName ? errors.firstName : undefined}
                />
                <FormField
                  name="lastName"
                  label="Last Name"
                  value={lastName}
                  $onValueChange={(v) => setFieldValue("lastName", v)}
                  onBlur={() => setFieldTouched("lastName")}
                  error={touched.lastName ? errors.lastName : undefined}
                />
              </FieldRow>
              <FormField
                name="email"
                label="Email Address"
                value={email}
                $onValueChange={(v) => setFieldValue("email", v)}
                onBlur={() => setFieldTouched("email")}
                error={touched.email ? errors.email : undefined}
              />
              <CreditCardInput
                onChange={({ complete }) => setFieldValue("hasCreditCardData", complete)}
                error={touched.hasCreditCardData ? errors.hasCreditCardData : undefined}
              />
              <FormButton disabled={isSubmitting} onClick={submitForm}>
                {isSubmitting ? "Submitting" : "Donate"}
              </FormButton>
            </Form>
          )}
        </DonationHandler>
      </PageSection>
      <ConfirmationDialog
        open={showConfirmationDialog}
        title="Thank you!"
        description="Your donation was successful. Thank you so much for supporting Foundwork."
        cta="Return to Foundwork"
        onClose={closeAndGoHome}
      />
    </>
  )
}

export default Donate
