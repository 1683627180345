import { types } from "../actions"

const contactForm = (
  state = {
    open: false,
    inProgress: false,
    sent: false,
    subject: "",
    message: "",
    error: "",
    email: "",
    name: "",
  },
  { type, payload }
) => {
  switch (type) {
    case types.TOGGLE_CONTACT_FORM:
      return {
        ...state,
        open: !state.open
      }
    case types.UPDATE_CONTACT_FORM_FIELD:
      return {
        ...state,
        ...payload
      }
    case types.BEGIN_CONTACT_FORM_PROGRESS:
      return {
        ...state,
        inProgress: true,
        error: null
      }
    case types.END_CONTACT_FORM_PROGRESS:
      return {
        ...state,
        inProgress: false,
        error: payload ? payload.error : null
      }
    case types.RESET_CONTACT_FORM:
      return {
        ...state,
        inProgress: false,
        sent: false,
        subject: "",
        message: "",
        error: "",
        email: "",
        name: "",
      }
    default: {
      return state
    }
  }
}

export default contactForm
