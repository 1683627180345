import { CouponResponse } from "@/api"

export class Coupon {
  readonly code: string
  readonly isValid: boolean
  readonly isFree: boolean
  readonly isForever: boolean

  get requiresPayment() {
    return !this.isFree || !this.isForever
  }

  constructor({ code, isValid, isFree, isForever }: { code: string } & CouponResponse) {
    this.code = code
    this.isValid = isValid
    this.isFree = isFree
    this.isForever = isForever
  }

  static readonly invalidCoupon = new Coupon({
    code: "",
    isValid: false,
    isFree: false,
    isForever: false,
  })
}
