import React from "react"
import { string, array, func } from "prop-types"
import styles from "./search-results"
import classNames from "classnames/bind"
import ContentCard from "../ContentCard"

const cx = classNames.bind(styles)

const SearchResults = ({ kind, results, onTitleSelect, onImageSelect }) => {
  return (
    <section className={cx("default")}>
      <div className={cx("results")}>
        {results.map((result, i) => (
          <div className={cx("result")} key={i}>
            <ContentCard
              kind={kind}
              content={result}
              onTitleSelect={onTitleSelect}
              onImageSelect={onImageSelect}
              index={i}
            />
          </div>
        ))}
        <div className={cx("invisible-child")} />
        <div className={cx("invisible-child")} />
      </div>
    </section>
  )
}

SearchResults.propTypes = {
  kind: string.isRequired,
  results: array.isRequired,
  onTitleSelect: func,
  onImageSelect: func
}

export default SearchResults
