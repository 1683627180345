import React, { Component } from "react"
import { push } from "connected-react-router"
import qs from "query-string"
import classNames from "classnames/bind"
import { connect } from "react-redux"
import { number, func, oneOf, object } from "prop-types"
import styles from "./pagination"
import { actions } from "../../../actions"

const cx = classNames.bind(styles)

class Pagination extends Component {
  constructor(props) {
    super(props)
    this.setPage = this.setPage.bind(this)
  }

  componentDidMount() {
    const searchParams = qs.parse(this.props.location.search)
    // Check url for params, otherwise go to page 1
    this.props.goToPage(parseInt(searchParams.page, 10) || 1)
  }

  setPage(page) {
    window.scrollTo(0, 0)
    this.props.goToPage(page)
    // Set URL params for any paging after load
    this.props.setUrlPage(page, this.props.location)
  }

  pagesToShow(page, totalPages) {
    let pages = [page - 2, page - 1, page].filter(n => n > 0)
    const maxPages =
      totalPages - page < 5 ? totalPages - page : 5 - pages.length

    ;[...Array(maxPages).keys()].forEach(i => {
      if (pages.length < 5) {
        pages.push(page + i + 1)
      }
    })

    if (pages.length < 5 && totalPages > 5) {
      const firstPage = pages[0]
      ;[...Array(5 - pages.length).keys()].forEach(i =>
        pages.unshift(firstPage - i - 1)
      )
    }

    return pages
  }

  render() {
    const { page, totalPages } = this.props
    const maxPage = totalPages || 1
    const pages = this.pagesToShow(page, totalPages)

    return totalPages ? (
      <div className={cx("container")}>
        {pages[0] > 1 && (
          <button
            className={cx("page-button")}
            onClick={e => {
              e.preventDefault()
              this.setPage(page - 1)
            }}
          >
            {"←"}
          </button>
        )}
        {pages.map(i => (
          <button
            className={cx(["page-button", page === i ? "active" : ""])}
            key={i}
            onClick={e => {
              e.preventDefault()
              this.setPage(i)
            }}
          >
            {i}
          </button>
        ))}
        {pages[pages.length - 1] < totalPages && (
          <button
            className={cx(["page-button", "arrow"])}
            onClick={e => {
              e.preventDefault()
              if (page < maxPage) {
                this.setPage(page + 1)
              }
            }}
          >
            {"→"}
          </button>
        )}
      </div>
    ) : null
  }
}

Pagination.propTypes = {
  kind: oneOf(["Artwork", "Artist"]), // 'Artwork' or 'Artist'
  page: number,
  totalPages: number,
  location: object,
  goToPage: func
}

const mapStateToProps = state => {
  const { page } = state.search
  const { location } = state.router
  return {
    page,
    location
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goToPage: page => {
      // Change 'page' in search reducer
      dispatch(actions.selectSearchPage({ page }))
    },
    setUrlPage: (page, location) => {
      // Set page param
      let search = qs.parse(location.search)
      search = qs.stringify({ ...search, page })
      dispatch(push({ search }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
