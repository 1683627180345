import React, { Component } from "react"
import { string, array, func, bool } from "prop-types"
import classNames from "classnames/bind"
import styles from "./autocomplete"

const cx = classNames.bind(styles)

class Autocomplete extends Component {
  constructor(props) {
    super(props)
    this.updateText = this.updateText.bind(this)
    this.selectOption = this.selectOption.bind(this)
    this.closeOptions = this.closeOptions.bind(this)
    this.openOptions = this.openOptions.bind(this)
    this.state = {
      text: "",
      activeOptions: props.data,
      isOpen: props.isInitiallyOpen || false
    }
  }

  updateText(text) {
    // Find options that match the text
    const activeOptions = this.props.data.filter(option => {
      // Search for case-insensitive text anywhere in option
      return option.name.toLowerCase().includes(text.toLowerCase())
    })
    const isOpen = activeOptions.length > 0
    this.setState({
      text,
      activeOptions,
      isOpen
    })
  }

  selectOption(option) {
    // Use method from parent here...
    // First update form and remove option
    const activeOptions = this.state.activeOptions.filter(activeOption => {
      return activeOption !== option
    })
    this.props.onUpdate(option)
    // Delay because of the closing behavior of OverlayLink
    // which otherwise would close the entire Overlay from this
    setTimeout(() => {
      this.setState({
        text: "", // Reset input text
        isOpen: false, // Close the dropdown to show selection
        activeOptions // Reflect data changes (remove)
      })
    }, 50)
  }

  closeOptions() {
    this.setState({
      isOpen: false
    })
  }

  openOptions() {
    this.setState({
      isOpen: true
    })
  }

  render() {
    const { label } = this.props
    const { text, activeOptions, isOpen } = this.state
    const optionsClasses = isOpen ? ["options", "active"] : ["options"]

    return (
      <div className={cx("container")}>
        <label htmlFor="autocomplete-text">{label}</label>
        <div className={cx("input-wrapper")}>
          <input
            id="autocomplete-text"
            value={text}
            onChange={e => {
              e.preventDefault()
              this.updateText(e.target.value)
            }}
            onFocus={e => {
              e.preventDefault()
              this.openOptions()
            }}
          />
          {isOpen && (
            <button
              className={cx("reset-button")}
              onClick={this.closeOptions}
            />
          )}
          <div className={cx(optionsClasses)}>
            {activeOptions.map((option, i) => (
              <button
                key={`k-${i}-${option.value}`}
                className={cx("option")}
                onClick={e => {
                  // Don't make a click due to event ordering!!
                  e.preventDefault()
                  this.selectOption(option)
                }}
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

Autocomplete.propTypes = {
  label: string,
  data: array, // should be form [{name: String, value: Any}]
  onUpdate: func,
  isInitiallyOpen: bool
}

export default Autocomplete
