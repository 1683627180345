import { Formik, FormikConfig } from "formik"
import * as Yup from "yup"
import isEmail from "validator/es/lib/isEmail"
import React from "react"
import withStripeDonationConnector from "@/payments/stripe/withStripeDonationConnector"
import useDonationPerformer, { DonationResult } from "@/payments/stripe/useDonationPerformer"

interface DonationForm {
  firstName: string
  lastName: string
  email: string
  donationAmount?: number
  // We don't directly receive the credit card number, but we can know if the user input a potentially correct one
  hasCreditCardData: boolean
}

const errorMessages = {
  firstName: {
    required: "Your first name is required",
  },
  lastName: {
    required: "Your last name is required",
  },
  email: {
    required: "Your email is required",
    invalid: "The provided email is not a valid email",
  },
  donationAmount: {
    required: "The amount of your donation is required",
  },
  hasCreditCardData: {
    oneOf: "Your credit card number is required",
  },
}

type DonationHandlerProps = Pick<FormikConfig<DonationForm>, "children"> & {
  onDonationSuccessful: () => void
  onDonationError: () => void
}

const DonationHandler = ({ onDonationSuccessful, onDonationError, children }: DonationHandlerProps) => {
  const performDonation = useDonationPerformer()

  const initialFormValues: DonationForm = {
    firstName: "",
    lastName: "",
    email: "",
    donationAmount: undefined,
    hasCreditCardData: false,
  }

  const submitDonation: FormikConfig<DonationForm>["onSubmit"] = async (
    values,
    { resetForm, setSubmitting }
  ) => {
    const donationResult = await performDonation({
      // With validation in place, the form cannot be submitted if the donation amount is not set, so this is just to
      // make sure Typescript doesn't complain
      donationAmount: values.donationAmount!,
      ...values,
    })

    if (donationResult === DonationResult.SUCCESS) {
      resetForm()
      onDonationSuccessful()
    } else {
      onDonationError()
    }

    setSubmitting(false)
  }

  const DonationFormSchema = Yup.object().shape({
    firstName: Yup.string().required(errorMessages.firstName.required),
    lastName: Yup.string().required(errorMessages.lastName.required),
    email: Yup.string()
      .required(errorMessages.email.required)
      .test("is-valid", errorMessages.email.invalid, (email) => isEmail(email)),
    donationAmount: Yup.number().required(errorMessages.donationAmount.required),
    hasCreditCardData: Yup.boolean().oneOf([true], errorMessages.hasCreditCardData.oneOf),
  })

  return (
    <Formik initialValues={initialFormValues} validationSchema={DonationFormSchema} onSubmit={submitDonation}>
      {children}
    </Formik>
  )
}

export default withStripeDonationConnector(DonationHandler)
