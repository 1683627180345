import React from "react"
import classNames from "classnames/bind"
import styles from "./search-options"
import SearchControls from "../SearchControls"
import { useQuery, gql } from "@apollo/client"

const cx = classNames.bind(styles)

// This is to populate the search controls with correct options.
const optionQuery = gql`
  query SearchOptionsQuery {
    options {
      artworkFormats {
        id
        name
      }
      artworkCategories {
        id
        name
      }
      organizations {
        id
        name
        country {
          short
        }
      }
      schools {
        id
        name
        country {
          short
        }
      }
      degreeTypes
    }
  }
`

const SearchOptions = () => {
  const response = useQuery(optionQuery)
  const { data } = response || {}
  const { options } = data || {}

  return (
    <div className={cx("container")}>
      {options ? <SearchControls searchOptions={options} /> : null}
    </div>
  )
}

export default SearchOptions
