import React from "react"
import { oneOf, func, number, object } from "prop-types"

import ArtistCard from "../ArtistCard"
import ArtworkCard from "../ArtworkCard"

const CARDS = {
  Artwork: ArtworkCard,
  Artist: ArtistCard
}

const ContentCard = props => {
  const Handler = CARDS[props.kind]

  return <Handler {...props} />
}

ContentCard.propTypes = {
  kind: oneOf(["Artwork", "Artist"]),
  content: object,
  onTitleSelect: func,
  onImageSelect: func,
  index: number
}

export default ContentCard
