import React from "react"

import { NextButton, PreviousButton } from "nuka-carousel"

import classNames from "classnames/bind"

import styles from "./carousel"

const cx = classNames.bind(styles)

export class Prev extends PreviousButton {
  render() {
    const { currentSlide, wrapAround, slideCount, className } = this.props
    const isDisabled = (currentSlide === 0 && !wrapAround) || slideCount === 0
    return (
      <button
        className={cx("carousel-button", "prev", className)}
        disabled={isDisabled}
        onClick={this.handleClick}
      >
        &larr;
      </button>
    )
  }
}

export class Next extends NextButton {
  render() {
    const {
      currentSlide,
      slidesToScroll,
      wrapAround,
      slideCount,
      className
    } = this.props
    const isDisabled =
      currentSlide + slidesToScroll >= slideCount && !wrapAround
    return (
      <button
        className={cx("carousel-button", "next", className)}
        disabled={isDisabled}
        onClick={this.handleClick}
      >
        &rarr;
      </button>
    )
  }
}

export const SwiperPrev = ({ className }) => (
  <button className={cx("carousel-button", "prev", className)}>&larr;</button>
)

export const SwiperNext = ({ className }) => (
  <button className={cx("carousel-button", "next", className)}>&rarr;</button>
)
