import React from "react"
import { connect } from "react-redux"
import { string } from "prop-types"
import { Link } from "react-router-dom"
import FullOverlay from "../../../components/FullOverlay"
import { editArtistProfileRouteFn } from "../../../constants.js"
import classNames from "classnames/bind"
import styles from "./welcome"

const cx = classNames.bind(styles)

const Welcome = ({ userSlug }) => (
  <FullOverlay>
    <div className={cx("container")}>
      <h1>Welcome to Foundwork</h1>
      <p>
        {`To get started, visit the Content Management System (CMS) where you can create your artist profile and upload your artwork. You can access CMS any time by logging in to Foundwork on a computer and clicking the CMS button in the corner of your screen.`}
      </p>
      <Link
        className={cx("btn btn-blue")}
        to={editArtistProfileRouteFn(userSlug)}
      >
        Go to CMS
      </Link>
    </div>
  </FullOverlay>
)

Welcome.propTypes = {
  userSlug: string.isRequired
}

const mapStateToProps = ({ session }) => {
  const { userSlug } = session
  return { userSlug }
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome)
