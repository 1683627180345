import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./artwork-detail"
import { string, array, number } from "prop-types"
import NukaCarousel from "nuka-carousel"
import { displayDimensions, displayVideoDuration } from "../../utils"
import { artistDetailRouteFn } from "../../constants"
import Video from "../Video"

const cx = classNames.bind(styles)

class ArtworkDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageLoaded: true,
      activeImageIndex: 0,
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.imageUrl !== nextProps.imageUrl) {
      this.setState({ imageLoaded: false })
      if (nextProps.imageUrl) {
        const image = new Image()
        image.onload = () => {
          this.setState({ imageLoaded: true })
        }
        image.src = nextProps.imageUrl
      }
    }
  }

  render() {
    const {
      artworkImages,
      imageUrl,
      videoUrl,
      videoDurationMinutes,
      videoDurationSeconds,
      artistName,
      artistSlug,
      title,
      dimensions,
      medium,
      year,
      additionalInformation
    } = this.props

    const { imageLoaded, activeImageIndex, width } = this.state

    const isMobile = width <= 500
    const slideCount = isMobile ? 3 : 5

    return (
      <React.Fragment>
        {!videoUrl && !imageUrl && !artworkImages ? null : (
          <div className={cx("container")}>
            <div className={cx("image-wrapper")}>
              {!videoUrl &&
                (imageUrl || (artworkImages && artworkImages.length)) && (
                  <img
                    alt={title || "Untitled Artwork"}
                    src={
                      artworkImages.length > 1
                        ? artworkImages[activeImageIndex].image.large
                        : imageUrl
                    }
                    className={cx({
                      active: imageLoaded
                    })}
                  />
                )}
              {videoUrl && (
                <div className={cx("video")}>
                  <Video
                    url={videoUrl}
                    width={"100%"}
                    height={360}
                    controls={true}
                    showVideoThumbnail={false}
                  />
                </div>
              )}

              {artworkImages.length > 1 && (
                <div className={cx("image-thumbs-wrapper")}>
                  <NukaCarousel
                    cellSpacing={20}
                    easing="easeCubic"
                    edgeEasing="easeCubic"
                    autoplay={false}
                    wrapAround={true}
                    dragging={true}
                    slidesToShow={slideCount}
                    renderBottomCenterControls={null}
                    renderCenterLeftControls={({ previousSlide }) =>
                      !isMobile && artworkImages.length > slideCount ? (
                        <button onClick={previousSlide}>&lsaquo;</button>
                      ) : null
                    }
                    renderCenterRightControls={({ nextSlide }) =>
                      !isMobile && artworkImages.length > slideCount ? (
                        <button onClick={nextSlide}>&rsaquo;</button>
                      ) : null
                    }
                  >
                    {artworkImages.map((img, i) => (
                      <div
                        className={cx([
                          "image-thumb",
                          `${activeImageIndex === i ? `active` : ``}`
                        ])}
                      >
                        <button
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ activeImageIndex: i })
                          }}
                          style={{
                            "background-image": `url(${img.image.thumb})`
                          }}
                        ></button>
                      </div>
                    ))}
                  </NukaCarousel>
                </div>
              )}
            </div>
            <div className={cx("content")}>
              <h3 className={cx("artist-name")}>
                {artistSlug ? (
                  <Link to={artistDetailRouteFn(artistSlug)}>{artistName}</Link>
                ) : (
                  <span>{artistName}</span>
                )}
              </h3>
              <div className={cx("extra-info")}>
                <div className={cx("field")}>
                  <span className={cx("title")}>{`${title ||
                    "Untitled"}`}</span>
                  , <span>{year}</span>
                </div>
                {medium && <div className={cx("field")}>{`${medium} `}</div>}
                {dimensions && (
                  <div className={cx("field")}>
                    {displayDimensions(dimensions)}
                  </div>
                )}
                {videoDurationMinutes && videoDurationSeconds && (
                  <div className={cx("dimensions")}>
                    {displayVideoDuration(
                      videoDurationMinutes,
                      videoDurationSeconds
                    )}
                  </div>
                )}
                {additionalInformation && (
                  <div className={cx("additional-information")}>
                    {additionalInformation}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

ArtworkDetail.propTypes = {
  imageUrl: string,
  videoUrl: string,
  artistName: string,
  title: string,
  dimensions: array,
  medium: string,
  year: number
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps)(ArtworkDetail)
