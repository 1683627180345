import React from "react"
import Typography, { typographyCss } from "./Typography"
import Variables, { variablesCss } from "./Variables"
import Reset, { resetCss } from "./Reset"
import { css } from "styled-components"

// TODO This is a workaround to prevent injecting global styles that will clash with the existing CSS code. If an when
// the migration is done, we should remove injecting this CSS snippets into every components and just streamline it as
// global rules
export const globalStylesCss = css`
  ${resetCss}
  ${variablesCss}
  ${typographyCss}
`

const GlobalStyles = () => (
  <>
    <Reset />
    <Variables />
    <Typography />
  </>
)

export default GlobalStyles
