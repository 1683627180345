// Apollo wrapper for ProfileForm
import React from "react"
import { useQuery, gql } from "@apollo/client"
import ProfileForm from "../ProfileForm"

const formQuery = gql`
  query ProfileFormWrapperQuery {
    formOptions {
      formats {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`

const ProfileFormWrapper = ({ user }) => {
  const response = useQuery(formQuery)
  const { data } = response || {}
  const { formOptions } = data || {}

  return (
    <div>
      {formOptions && (
        <ProfileForm allFormats={formOptions.formats} allCategories={formOptions.categories} user={user} />
      )}
    </div>
  )
}

export default ProfileFormWrapper
