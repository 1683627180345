import React from "react"
import { string, func } from "prop-types"
import Textarea from "react-textarea-autosize"
import classNames from "classnames/bind"
import styles from "./document-editor"

const cx = classNames.bind(styles)

const handleKeyDown = e => {
  if (e.keyCode === 9 || e.which === 9) {
    e.preventDefault()
    var s = e.target.selectionStart
    e.target.value =
      e.target.value.substring(0, e.target.selectionStart) +
      "\t" +
      e.target.value.substring(e.target.selectionEnd)
    e.target.selectionEnd = s + "\t".length
  }
}

const DocumentEditor = ({ text, handleSubmit, handleChange, saving }) => {
  const buttonClasses = {
    btn: true,
    "btn-blue": true,
    "cms-button": true,
    saving
  }

  return (
    <form className={cx("form")} onSubmit={handleSubmit}>
      <div className={cx("textbox-container")}>
        <div className={cx("btn-container")}>
          <button type="submit" className={cx(buttonClasses)}>
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
        <Textarea
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          name="text"
          value={text}
          minRows={4}
          className={cx("textbox")}
        />
      </div>
    </form>
  )
}

DocumentEditor.propTypes = {
  text: string,
  handleSubmit: func
}

export default DocumentEditor
