import { gql } from "@apollo/client"

export const WorksQuery = gql`
  query WorksQuery($id: ID!) {
    user(id: $id) {
      id
      artworks(includeNotReady: true) {
        id
        title
        formats {
          id
          name
        }
        categories {
          id
          name
        }
        medium
        year
        dimensions
        primaryImage {
          thumb
          large
        }
        artworkImages {
          image {
            thumb
            large
          }
          sort
        }
        published
        readyToShow
        additionalInformation
        videoLink
        videoDurationMinutes
        videoDurationSeconds
        position
      }
    }
  }
`

export const WorksSubscription = gql`
  subscription OnWorkUpdated($id: ID!) {
    userArtworkUpdated(userId: $id) {
      id
      title
      formats {
        id
        name
      }
      categories {
        id
        name
      }
      medium
      year
      dimensions
      primaryImage {
        thumb
        large
      }
      artworkImages {
        image {
          thumb
          large
        }
        sort
      }
      published
      readyToShow
      additionalInformation
      videoLink
      videoDurationMinutes
      videoDurationSeconds
      position
    }
  }
`

export const CreateArtwork = gql`
  mutation CreateArtwork(
    $userId: Int
    $primaryImageBase64: String
    $images: [String!]
    $videoLink: String
    $dimensions: [String!]
    $year: String!
    $formats: [String!]!
    $categories: [String!]
    $medium: String
    $title: String
    $videoDurationMinutes: String
    $videoDurationSeconds: String
    $additionalInformation: String
  ) {
    createArtwork(
      userId: $userId
      primaryImageBase64: $primaryImageBase64
      images: $images
      videoLink: $videoLink
      dimensions: $dimensions
      year: $year
      formats: $formats
      categories: $categories
      medium: $medium
      title: $title
      videoDurationMinutes: $videoDurationMinutes
      videoDurationSeconds: $videoDurationSeconds
      additionalInformation: $additionalInformation
    ) {
      id
      artwork {
        id
        title
        formats {
          id
          name
        }
        categories {
          id
          name
        }
        medium
        year
        dimensions
        primaryImage {
          thumb
          large
        }
        artworkImages {
          image {
            thumb
            large
          }
          sort
        }
        published
        readyToShow
        additionalInformation
        videoLink
        videoDurationMinutes
        videoDurationSeconds
        position
      }
    }
  }
`

export const UpdateArtwork = gql`
  mutation UpdateArtwork(
    $id: ID!
    $images: [String!]
    $primaryImageBase64: String
    $videoLink: String
    $dimensions: [String!]
    $year: String!
    $formats: [String!]!
    $categories: [String!]
    $medium: String
    $title: String
    $videoDurationMinutes: String
    $videoDurationSeconds: String
    $additionalInformation: String
  ) {
    updateArtwork(
      id: $id
      images: $images
      primaryImageBase64: $primaryImageBase64
      videoLink: $videoLink
      dimensions: $dimensions
      year: $year
      formats: $formats
      categories: $categories
      medium: $medium
      title: $title
      videoDurationMinutes: $videoDurationMinutes
      videoDurationSeconds: $videoDurationSeconds
      additionalInformation: $additionalInformation
    ) {
      id
      artwork {
        id
        title
        formats {
          id
          name
        }
        categories {
          id
          name
        }
        medium
        year
        dimensions
        primaryImage {
          thumb
          large
        }
        artworkImages {
          image {
            thumb
            large
          }
          sort
        }
        published
        readyToShow
        additionalInformation
        videoLink
        videoDurationMinutes
        videoDurationSeconds
        position
      }
    }
  }
`

export const PublishArtwork = gql`
  mutation PublishArtworkMutation($id: ID!, $published: Boolean!) {
    publishArtwork(id: $id, published: $published) {
      id
    }
  }
`

export const DeleteArtwork = gql`
  mutation DeleteArtwork($id: ID!) {
    deleteArtwork(id: $id) {
      deletedId
    }
  }
`

export const ReorderArtwork = gql`
  mutation ReorderArtworkMutation($id: ID!, $position: Int!) {
    reorderArtwork(id: $id, position: $position) {
      id
      position
    }
  }
`
