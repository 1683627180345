import React, { Component } from "react"
import Downshift from "downshift"
import { connect } from "react-redux"
import AutoCompleteMenu from "../../containers/global/AutocompleteMenu"
import styles from "./search-box"
import classNames from "classnames/bind"
import { actions } from "../../actions"

const cx = classNames.bind(styles)

class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.closeOutsideClick = this.closeOutsideClick.bind(this)
    this.esc = this.esc.bind(this)
    this.setRef = this.setRef.bind(this)
  }

  setRef(node) {
    this.ref = node
  }

  esc(e) {
    if (e.key === "Escape") {
      this.props.closeOverlay()
    }
  }

  closeOutsideClick(e) {
    if (this.ref && !this.ref.contains(e.target)) {
      this.props.closeOverlay()
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.closeOutsideClick)
    document.addEventListener("keydown", this.esc)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeOutsideClick)
    document.removeEventListener("keydown", this.esc)
  }

  render() {
    const { handleChange } = this.props
    return (
      <div ref={this.setRef}>
        <Downshift
          onChange={selection => {
            handleChange(selection)
          }}
          itemToString={i => (i == null ? "" : String(i.name))}
        >
          {({
            getInputProps,
            getLabelProps,
            getItemProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem
          }) => (
            <div>
              <input
                {...getInputProps({})}
                className={cx("search-input")}
                placeholder={"Search Artists"}
              />
              {isOpen ? (
                <div>
                  <AutoCompleteMenu
                    {...{
                      inputValue,
                      selectedItem,
                      highlightedIndex,
                      getItemProps
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  const { closeMobileSearchOverlay } = actions

  return {
    closeOverlay: () => {
      dispatch(closeMobileSearchOverlay())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBox)
