import React from "react"
import HeaderContainer from "../HeaderContainer"
import FooterContainer from "../FooterContainer"
import CookieNotice from "../../../components/CookieNotice"
import styles from "./layout"

const Layout = (props) => (
  <div>
    <HeaderContainer />
    <div className={styles.container}>{props.children}</div>
    <FooterContainer />
    <CookieNotice />
  </div>
)

export default Layout
