import {gql} from "@apollo/client"
import {client} from "../../../services/apolloEnv"

const userProfileQuery = gql`
  query UserProfile($slug: String!) {
    artist(slug: $slug) {
      firstName
    }
  }
`
const profileIsReady = async (slug) => {
  try {
    await client.query({
      query: userProfileQuery,
      variables: {
        slug
      }
    })
    return true
  } catch {
    return false
  }
}

export default profileIsReady
