import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { func } from "prop-types"
import SearchBox from "../../../components/SearchBox"
import { actions } from "../../../actions"
import { artistDetailRouteFn } from "../../../constants"

const Search = props => <SearchBox {...props} />

Search.propTypes = {
  handleChange: func
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  const {
    closeMobileSearchOverlay,
    invalidateExploreArtwork,
    toggleArtistDetailTab
  } = actions

  return {
    handleChange: ({ slug }) => {
      dispatch(closeMobileSearchOverlay())
      dispatch(invalidateExploreArtwork())
      dispatch(toggleArtistDetailTab("works"))
      dispatch(push(artistDetailRouteFn(slug)))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
