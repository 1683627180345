import React from "react"
import { useMutation, gql } from "@apollo/client"
import classNames from "classnames/bind"
import { toGraduationString } from "../../../utils.js"
import styles from "./graduation-list"

const cx = classNames.bind(styles)

const DeleteGraduation = gql`
  mutation DeleteGraduation($id: ID!, $graduationId: String!) {
    deleteGraduation(id: $id, graduationId: $graduationId) {
      graduations {
        id
        degreeType
        school {
          id
          country {
            name
          }
        }
      }
    }
  }
`

const GraduationList = ({ user }) => {
  const { graduations } = user
  const [mutate] = useMutation(DeleteGraduation)

  const handleDelete = (graduationId) => {
    const isCancelled = !window.confirm("Are you sure you want to remove this school?")

    if (isCancelled) {
      return
    }

    mutate({
      variables: {
        id: user.id,
        graduationId,
      },
      refetchQueries: ["ProfileWrapperQuery"],
    })
  }

  return (
    <div className={cx("graduation-list")}>
      <div className={cx("school")}>
        {graduations.map((graduation, i) => (
          <div key={i} className={cx("school-item")}>
            <div className={cx("controls")}>
              <button className={cx("btn", "btn-gray")} onClick={(e) => handleDelete(graduation.id)}>
                Remove
              </button>
            </div>
            <div className={cx("graduation")}>{toGraduationString(graduation)}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GraduationList
