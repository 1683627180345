import { types } from "../actions"

const header = (
  state = {
    isActive: false,
    mobileSearchOpen: false
  },
  { type, payload }
) => {
  switch (type) {
    case types.TOGGLE_HEADER_USER_MENU: {
      return {
        ...state,
        isActive: !state.isActive
      }
    }
    case types.CLOSE_HEADER_USER_MENU: {
      return {
        ...state,
        isActive: false
      }
    }
    case types.TOGGLE_MOBILE_SEARCH_OVERLAY: {
      return {
        ...state,
        mobileSearchOpen: !state.mobileSearchOpen
      }
    }
    case types.CLOSE_MOBILE_SEARCH_OVERLAY: {
      return {
        ...state,
        mobileSearchOpen: false
      }
    }
    default: {
      return state
    }
  }
}

export default header
