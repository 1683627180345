import classNames from "classnames/bind"
import {push} from "connected-react-router"
import {editArtistWorksRouteFn} from "../../constants"
import CurrentUser from "../../currentUser"
import React from "react"
import {connect} from "react-redux"
import styles from "./profile-image-form"
import {actions} from "../../actions"

const cx = classNames.bind(styles)

function ProfileImageHint({routeToArtistWorksPage, toggleModal}) {
  return (
    <>
      <div className={cx(["hint"])}>
        Before you can choose your profile image, please go to the "Works" section in CMS and add at least one artwork
        with still images (not videos).
      </div>
      <button
        className={cx(["close", "btn", "btn-white", "wide"])}
        onClick={e => {
          e.preventDefault()
          routeToArtistWorksPage()
          toggleModal()
        }}
      >
        Go to your Works &rarr;
      </button>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  const {toggleProfileImageForm} = actions

  return {
    toggleModal: () => dispatch(toggleProfileImageForm()),
    routeToArtistWorksPage: () => dispatch(push(editArtistWorksRouteFn(CurrentUser.slug()))),
  }
}

export default connect(null, mapDispatchToProps)(ProfileImageHint)
