import { snakeKeys } from "../utils.js"
import ApiClient from "../ApiClient.js"

export function sendMessage(data, cb) {
  data = snakeKeys(data)

  ApiClient.post("/contact_artist")
    .send(data)
    .end((err, response) => {
      cb({ err, response })
    })
}
