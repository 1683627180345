import React from "react"
import classNames from "classnames/bind"
import styles from "./static-hero"

const cx = classNames.bind(styles)

export default ({ content, title, children }) => {
  return (
    <div className={cx("static-hero")}>
      <h1>{title}</h1>
      <p>{content}</p>
      {children}
    </div>
  )
}
