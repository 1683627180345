import React from "react"
import { string, bool, oneOfType, number, func } from "prop-types"
import styles from "./input"
import classNames from "classnames/bind"
import Tooltip from "../Tooltip"

const cx = classNames.bind(styles)

export const InputWrapper = ({
  children,
  className,
  name,
  label,
  hint,
  error
}) => (
  <div className={cx(["container", className])}>
    <label htmlFor={name} className={cx("label")}>
      {hint && <Tooltip copy={hint} />}
      {label}
    </label>
    <div className={cx("input-wrapper")}>{children}</div>
    <div className={cx({ error: !!error })}>{error}</div>
  </div>
)

const Input = ({
  name,
  label,
  placeholder,
  type,
  required,
  value,
  step,
  defaultValue,
  handleChange,
  handleBlur,
  className,
  hasHighlight,
  disableAutocomplete,
  hint,
  error
}) => (
  <InputWrapper
    name={name}
    label={label}
    className={className}
    hint={hint}
    error={error}
  >
    {
      type === "textarea" ?
        <textarea
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          required={required}
          className={cx({ highlight: hasHighlight }, "input")}
          rows={2} // TODO This component can be replaced by the already present <Textarea/>, but we need to fix the font size first
        />
      :
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          step={step}
          required={required}
          autoComplete={disableAutocomplete ? "off" : "on"}
          className={cx({ highlight: hasHighlight }, "input")}
        />
    }
  </InputWrapper>
)

Input.propTypes = {
  name: string,
  label: string,
  placeholder: string,
  type: string,
  required: bool,
  step: string,
  className: string,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  handleChange: func,
  handleBlur: func,
  hasHighlight: bool,
  disableAutocomplete: bool,
  hint: string,
  error: string
}

export default Input
