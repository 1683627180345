import React from "react"
import ForgotPasswordWrapper from "../../../containers/login/ForgotPasswordWrapper"
import styles from "./forgot-password"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ForgotPassword = () => (
  <div className={cx("container")}>
    <ForgotPasswordWrapper />
  </div>
)

export default ForgotPassword
