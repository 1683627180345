import React from "react"
import classNames from "classnames/bind"
import { Link, NavLink } from "react-router-dom"
import MediaQuery from "react-responsive"
import HeaderUserMenuContainer from "../../containers/global/HeaderUserMenuContainer"
import styles from "./header-logged-in"
import {
  aboutRoute,
  artistSearchRoute,
  artworkSearchRoute,
  dialoguesRoute,
  editArtistWorksRouteFn,
  faqRoute,
  guestCuratorsRoute,
  loginRoute,
  prizeRoute,
  signupRoute,
  donateRoute,
} from "../../constants.js"

const cx = classNames.bind(styles)

const HeaderInner = ({ userSlug, isArtist, logOut, isCms, loggedIn, closeHeaderMenu }) => (
  <div className={cx({ header: true, loggedIn: loggedIn })}>
    <div className={cx("header-menu")}>
      <div className={cx({ top: true, loggedIn })}>
        {!isCms && (
          <>
            <NavLink exact to={artistSearchRoute}>
              Artists
            </NavLink>
            <NavLink exact to={artworkSearchRoute}>
              Artwork
            </NavLink>
            <NavLink exact to={guestCuratorsRoute}>
              Guest Curators
            </NavLink>
            <NavLink exact to={dialoguesRoute}>
              Dialogues
            </NavLink>
            <NavLink exact to={prizeRoute}>
              Artist Prize
            </NavLink>
            <NavLink exact to={aboutRoute}>
              About
            </NavLink>
            <NavLink className={cx("mobile-only")} exact to={faqRoute}>
              FAQ
            </NavLink>
            <NavLink exact to={donateRoute}>
              Donate
            </NavLink>
          </>
        )}
      </div>

      {loggedIn ? (
        <div className={cx("bottom")}>
          <HeaderUserMenuContainer />
        </div>
      ) : (
        <div className={cx("options")}>
          <MediaQuery maxWidth={936}>
            <Link to={loginRoute}>Log In</Link>
            <Link to={signupRoute}>Sign Up</Link>
          </MediaQuery>
          <MediaQuery minWidth={937}>
            <Link className={cx(["btn", "btn-white"])} to={loginRoute}>
              Log In
            </Link>
            <Link className={cx(["btn", "btn-dark-gray", "btn-sign-up"])} to={signupRoute}>
              Sign Up
            </Link>
          </MediaQuery>
        </div>
      )}
    </div>

    {isArtist && loggedIn && (
      <div className={cx({ cms: true, isCms })}>
        <NavLink to={editArtistWorksRouteFn(userSlug)}>CMS</NavLink>
      </div>
    )}
  </div>
)

export default HeaderInner
