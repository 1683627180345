import React from "react"
import styles from "./dropdown"
import { string, array, func, bool, oneOfType, object } from "prop-types"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const Dropdown = ({
  name,
  label,
  options,
  onChange,
  placeholder,
  className,
  value,
  resets,
  children
}) => (
  <div className={cx(["container", className])}>
    {label && <label htmlFor={name}>{label}</label>}
    <div className={cx("select-wrapper")}>
      <select
        name={name}
        id={name}
        value={value}
        onChange={e => {
          if (onChange) {
            e.preventDefault()
            onChange(e.target.value)
            if (resets) {
              e.target.value = ""
            }
          }
        }}
        onBlur={e => {
          if (onChange) {
            e.preventDefault()
            onChange(e.target.value)
            if (resets) {
              e.target.value = ""
            }
          }
        }}
      >
        {placeholder && <option value="">{placeholder}</option>}

        {options.map(({ name, value }, i) => (
          <option value={value} key={i}>
            {name || value}
          </option>
        ))}
      </select>
      {children}
    </div>
  </div>
)

Dropdown.propTypes = {
  name: string,
  label: string,
  options: array,
  onChange: func,
  className: string,
  placeholder: string,
  value: string,
  resets: bool,
  // children: oneOfType([array, object])
}

export default Dropdown
