import React, {useMemo} from "react"
import styles from "./artist-detail-header"
import { toGraduationString } from "../../utils"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ArtistDetailHeader = ({
  name,
  livesAndWorks,
  genderPronoun,
  birthYear,
  hometown,
  website,
  graduations,
  affiliations
}) => {
    const genderPronounDisplay = useMemo(
        () => genderPronoun && genderPronoun !== "" ? `Preferred pronouns: ${genderPronoun}` : undefined,
    [genderPronoun])

  return (
    <div className={cx("container")}>
      <h1 className={cx("name")}>{name}</h1>
      {livesAndWorks && (
        <p className={`small ${cx("livesAndWorks")}`}>{livesAndWorks}</p>
      )}
      <div className={cx("hometown")}>
        {(birthYear || hometown) && <span>{"b. "}</span>}
        {birthYear && <span>{birthYear}</span>}
        {birthYear && hometown && <span>{", "}</span>}
        {hometown && <span>{hometown}</span>}
      </div>
      {graduations.map((graduation, i) => {
        const { school } = graduation
        return (
          <div className={cx("graduation")} key={i}>
            {`${toGraduationString(graduation)}, ${school.city}${
              school.state ? `, ${school.state.short}` : ""
            }, ${school.country.name}`}
          </div>
        )
      })}
      {genderPronounDisplay && <div className={cx("genderPronouns")}>{genderPronounDisplay}</div>}
      {affiliations.map((affiliation, i) => {
        const { organization } = affiliation
        return (
          <div className={cx("graduation")} key={i}>
            {`${organization.name}, ${organization.city}${
              organization.state ? `, ${organization.state.short}` : ""
            }, ${organization.country.name}`}
          </div>
        )
      })}
      {website && (
        <p className={`small ${cx("website")}`}>
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website.split("//")[1]}
          </a>
        </p>
      )}
    </div>
  )
}

export default ArtistDetailHeader
