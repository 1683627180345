import React from "react"
import classNames from "classnames/bind"
import styles from "./signup-terms"
import { Link } from "react-router-dom"
import { termsRoute, privacyRoute } from "../../constants.js"

const cx = classNames.bind(styles)

export default () => (
  <div className={cx("container")}>
    <span>{"By signing up, you agree to our "}</span>
    <div className={cx("mobile-secondary-container")}>
      <Link to={termsRoute} target="_blank">
        Terms
      </Link>
      <span>{" and "}</span>
      <Link to={privacyRoute} target="_blank">
        Privacy Policy
      </Link>
      <span>{"."}</span>
    </div>
  </div>
)
