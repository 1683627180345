import request, { SuperAgentRequest } from "superagent"
// @ts-ignore
import Throttle from "superagent-throttle"
import CurrentUser from "../../currentUser" // TODO port to new architecture
import { basicAuth } from "../../services/env"

class RequestBuilder<Req extends SuperAgentRequest> {
  static get(url: string) {
    return (new RequestBuilder(request.get(url)))
  }

  static post(url: string) {
    return (new RequestBuilder(request.post(url)))
  }

  private request: Req

  constructor(request: Req) {
    this.request = request
  }

  exchangingJson() {
    this.request = this.request.type("json").accept("json")

    return this
  }

  preventingConcurrent() {
    this.request = this.request.use((new Throttle({ concurrent: 1 })).plugin())

    return this
  }

  authenticated() {
    if (CurrentUser.token()) {
      this.request = this.request.set("token", `Bearer ${CurrentUser.token()}`)
    }

    if (basicAuth.enabled) {
      this.request = this.request.set("Authorization", basicAuth.header)
    }

    return this
  }

  build() {
    return this.request
  }
}

export default RequestBuilder
