import { types } from "../actions"
import CurrentUser from "../currentUser.js"

const session = (
  state = {
    loggedIn: !!CurrentUser.token(),
    userId: CurrentUser.id(),
    userSlug: CurrentUser.slug(),
    isArtist: CurrentUser.isArtist(),
    showReactivationModal: false
  },
  { type, payload }
) => {
  switch (type) {
    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        loggedIn: !!payload.currentUser.token(),
        userId: payload.currentUser.id(),
        userSlug: payload.currentUser.slug(),
        isArtist: payload.currentUser.isArtist(),
        showReactivationModal: payload.reactivation
      }
    case types.SET_AS_ARTIST:
      return {
        ...state,
        isArtist: true
      }
    case types.LOG_OUT:
      return {
        ...state,
        loggedIn: null,
        userId: null,
        role: null
      }
    case types.CLOSE_REACTIVATION_MODAL:
      return {
        ...state,
        showReactivationModal: false
      }
    default:
      return state
  }
}

export default session
