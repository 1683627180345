import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import EntryForm from "../../../components/EntryForm"
import LoginFlowWrapper from "../../../components/LoginFlowWrapper"
import SignupNote from "../../../components/SignupNote"
import { loginRoute, resetPasswordFields } from "../../../constants"
import { resetPassword } from "../../../services/auth"
import { actions } from "../../../actions"

const copy = `
  <p>
    Set your new password below,<br/>then we'll return you to the login page.
  </p>
`

const ResetPasswordWrapper = ({ error, loading, onSubmit, token }) => {
  return (
    <div>
      <LoginFlowWrapper
        title={"Reset Your Password"}
        buttonText={"Set New Password"}
        onSubmit={form => onSubmit(form, token)}
        error={error}
        loading={loading}
      >
        <SignupNote copy={copy} />
        <EntryForm fields={resetPasswordFields} />
      </LoginFlowWrapper>
    </div>
  )
}

const mapStateToProps = ({ authForm }) => {
  const { error, loading } = authForm
  return { error, loading }
}

const mapDispatchToProps = dispatch => {
  const {
    requestAuthFormSubmit,
    receiveAuthFormSubmit,
    authFormSubmitFailure
  } = actions

  return {
    onSubmit: (form, token) => {
      dispatch(requestAuthFormSubmit())
      resetPassword(form, token, res => {
        const { err, response } = res
        if (err || !response.ok) {
          dispatch(
            authFormSubmitFailure({
              error: "An error occurred"
            })
          )
          return
        }
        dispatch(receiveAuthFormSubmit())
        dispatch(push(loginRoute))
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordWrapper)
