import request from "superagent"
import Throttle from "superagent-throttle"
import CurrentUser from "./currentUser.js"
import { basicAuth } from "./services/env"

class ApiClient {
  static get(url) {
    return this.withOptions(request.get(url))
  }

  static post(url) {
    return this.withOptions(request.post(url))
  }

  static upload(url) {
    return this.withUploadOptions(request.post(url))
  }

  static put(url) {
    return this.withOptions(request.put(url))
  }

  static delete(url) {
    return this.withOptions(request.delete(url))
  }

  static withOptions(req) {
    req = this.withDefaults(req)
    req = CurrentUser.token() ? this.withJwt(req) : req
    req = basicAuth.enabled ? this.withBasicAuth(req) : req
    return req
  }

  static withUploadOptions(req) {
    req = CurrentUser.token() ? this.withJwt(req) : req
    req = this.withBasicAuth(req)
    return req
  }

  static withJwt(req) {
    return req.set("token", `Bearer ${CurrentUser.token()}`)
  }

  static withDefaults(req) {
    return req.type("json").accept("json")
  }

  static withBasicAuth(req) {
    return req.set("Authorization", basicAuth.header)
  }

  static withSerial(req) {
    const throttle = new Throttle({ concurrent: 1 })

    return req.use(throttle.plugin())
  }
}

export default ApiClient
