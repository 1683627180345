import { types } from "../actions"
import _ from "lodash"

// When changing the list of artworks, sometimes it can happen that the position
// within the array is not reflected in the `position` field, due to the fact
// that changes are done in memory and with partial data (i.e. we don't always
// get the whole array from the backend with the correct position assigned).
// So for this reason, we call this method that makes sure that the `position`
// field reflects the position in the array (which we decide during the state
// update and is authoritative in this case)
function withPositionsUpdated(artworks) {
  return artworks.map(
    (artwork, index) => ({ ...artwork, position: index + 1 })
  )
}

const artworksListPage = (
  state = {
    userArtworks: [],
  },
  { type, payload }
) => {
  switch (type) {
    case types.PARTIALLY_UPDATE_USER_ARTWORK:
      const artwork = _.find(state.userArtworks, { id: payload.id }) || {}
      const updatedArtwork = { ...artwork, ...payload }

      return {
        ...state,
        userArtworks: withPositionsUpdated(_.sortBy(
          _.unionBy([updatedArtwork], state.userArtworks, _.property("id")),
          _.property("position")
        ))
      }
    case types.REMOVE_USER_ARTWORK_FROM_LIST:
      return {
        ...state,
        userArtworks: withPositionsUpdated(_.sortBy(
          _.reject(state.userArtworks, { id: payload }),
          _.property("position")
        ))
      }
    case types.UPDATE_USER_ARTWORK_LIST:
      return {
        ...state,
        userArtworks: withPositionsUpdated(_.sortBy(
          _.unionBy(payload, state.userArtworks, _.property("id")),
          _.property("position")
        ))
      }
    case types.CHANGE_ARTWORK_POSITION:
      const { artwork: toBeReordered, position: newPosition } = payload
      const oldPosition = toBeReordered.position - 1 // NOTE Position is 1-based, array index is 0-based

      const reducedList = _(state.userArtworks).reject({ id: toBeReordered.id }).value()

      return {
        ...state,
        userArtworks: withPositionsUpdated([
          ...reducedList.slice(0, newPosition),
          toBeReordered,
          ...reducedList.slice(newPosition)
        ])
      }
    default:
      return state
  }
}

export default artworksListPage
