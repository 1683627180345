import React from "react"
import { array, func } from "prop-types"
import classNames from "classnames/bind"
import styles from "./selected-tags"

const cx = classNames.bind(styles)

const SelectedTags = ({ tags, onRemove }) => (
  <div className={cx("selected-tags")}>
    {tags.map((tag, index) => (
      <div className={cx("tag")} key={tag.name}>
        {tag.name}
        <button
          onClick={e => {
            e.preventDefault()
            onRemove(index)
          }}
        >
          &times;
        </button>
      </div>
    ))}
  </div>
)

SelectedTags.propTypes = {
  tags: array.isRequired,
  onRemove: func.isRequired
}

export default SelectedTags
