import React from "react"
import { useQuery, gql } from "@apollo/client"
import ArtworkDetailModal from "../../explore/ArtworkDetailModal"
import ArtworkResults from "../../explore/ArtworkResults"
import LoadingSpinner from "../../../components/LoadingSpinner"
import classNames from "classnames/bind"
import styles from "./detail-works"

const cx = classNames.bind(styles)

const detailWorksQuery = gql`
  query DetailWorksQuery($slug: String!) {
    user(slug: $slug) {
      slug
      artworks(publishedOnly: true) {
        title
        year
        dimensions
        medium
        artist {
          name
        }
        primaryImage {
          medium
          large
          thumb
        }
        artworkImages {
          id
          artworkId
          image {
            medium
            large
            thumb
          }
          sort
        }
        additionalInformation
        videoLink
        videoDurationMinutes
        videoDurationSeconds
      }
    }
  }
`

const DetailWorks = ({ previewIndex, slug }) => {
  const response = useQuery(detailWorksQuery, {
    variables: { slug }
  })

  const { data } = response || {}
  const { loading, user } = data || {}

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {user && user.artworks ? (
            <div>
              <div className={cx("artwork-count")}>
                {`${user.artworks.length} Artworks`}
              </div>
              <ArtworkResults artworks={user.artworks} loading={loading || false} />
            </div>
          ) : null}
          {previewIndex !== null && (
            <ArtworkDetailModal artworks={user.artworks} />
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default DetailWorks
