import React from "react"
import classNames from "classnames/bind"
import { string, func, array } from "prop-types"
import styles from "./overlay-link"

const cx = classNames.bind(styles)

class OverlayLink extends React.Component {
  constructor(props) {
    super(props)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.closeOutsideClick = this.closeOutsideClick.bind(this)
    this.closeByButton = this.closeByButton.bind(this)
    this.apply = this.apply.bind(this)
    this.reset = this.reset.bind(this)
    this.esc = this.esc.bind(this)
    this.setRef = this.setRef.bind(this)
    this.state = { isOpen: false }
  }
  setRef(node) {
    this.ref = node
  }
  closeByButton(e) {
    this.close()
  }
  closeOutsideClick(e) {
    if (this.ref && !this.ref.contains(e.target)) {
      this.close()
    }
  }
  esc(e) {
    if (e.key === "Escape") {
      this.close()
    }
  }
  toggleOpen() {
    if (this.state.isOpen) {
      this.props.onOpen && this.props.onOpen()
    } else {
      this.props.onClose && this.props.onClose()
    }
    this.setState({ isOpen: !this.state.isOpen })
  }
  close() {
    this.setState({ isOpen: false })
    this.props.onClose && this.props.onClose()
  }
  reset() {
    this.setState({ isOpen: false })
    this.props.onReset && this.props.onReset()
  }
  apply() {
    this.setState({ isOpen: false })
    this.props.onApply && this.props.onApply()
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.closeOutsideClick)
    document.addEventListener("keydown", this.esc)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeOutsideClick)
    document.removeEventListener("keydown", this.esc)
  }
  render() {
    const { label, children, alignment, activeFilters } = this.props
    const { isOpen } = this.state
    let wrapperClasses = isOpen ? ["default", "is-open"] : ["default"]
    if (alignment) {
      wrapperClasses.push(alignment)
    }
    let activeLabel
    if (activeFilters && activeFilters.length) {
      wrapperClasses.push("active")
      activeLabel = activeFilters.join(", ")
    }

    return (
      <div className={cx(wrapperClasses)} ref={this.setRef}>
        <div className={cx("container")}>
          <button className={cx("label")} onClick={this.toggleOpen}>
            {activeLabel || label}
            {!activeLabel && (
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.243 0L5 4.243.757 0" stroke="#4990E2" fill="none" />
              </svg>
            )}
          </button>
          {activeLabel && (
            <button className={cx("reset-button")} onClick={this.reset} />
          )}
          <div className={cx("overlay")}>
            {children}
            <button className={cx("close-button")} onClick={this.closeByButton}>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }
}

OverlayLink.propTypes = {
  label: string.isRequired,
  onOpen: func,
  onClose: func,
  onApply: func,
  onReset: func,
  alignment: string,
  activeFilters: array // List of active values
}

export default OverlayLink
