import React, {Component, useEffect} from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import EntryForm from "../../../components/EntryForm"
import { login } from "../../../services/auth"
import { actions } from "../../../actions.js"
import CurrentUser from "../../../currentUser.js"
import {
  editArtistWorksRouteFn,
  signupRoute,
  forgotPasswordRoute,
  loginFields
} from "../../../constants"
import LoginFlowWrapper from "../../../components/LoginFlowWrapper"
import LoginFlowPrompt from "../../../components/LoginFlowPrompt"
import {createConsumer} from "@rails/actioncable/src"
import {useLoggedInUser} from "../../misc/loggedInUserContext"

const LoginForm = ({ title, onSubmit, error, loading, resetForm }) => {
  const {setLoggedInUser} = useLoggedInUser()

  const attemptLogin = (form) => {
    onSubmit(form, () => {
      setLoggedInUser(CurrentUser.id())
    })
  }

  useEffect(() => {
    return () => resetForm()
  })

  return (
    <div>
      <LoginFlowWrapper
        title={title || "Log In"}
        buttonText={"Log In"}
        onSubmit={attemptLogin}
        error={error}
        loading={loading}
      >
        <EntryForm fields={loginFields} />
      </LoginFlowWrapper>

      <LoginFlowPrompt
        text={"Don't have an account? "}
        url={signupRoute}
        linkText={"Sign Up"}
      />
      <LoginFlowPrompt
        url={forgotPasswordRoute}
        linkText={"Forgot Password?"}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const { error, loading } = state.authForm
  return {
    error,
    loading
  }
}

const mapDispatchToProps = dispatch => {
  const {
    authenticationSuccess,
    requestAuthFormSubmit,
    receiveAuthFormSubmit,
    authFormSubmitFailure,
    resetAuthForm,
    closeLoginModal
  } = actions

  return {
    resetForm: () => {
      dispatch(resetAuthForm())
    },
    onSubmit: (form, callback) => {
      dispatch(requestAuthFormSubmit())
      login(form, res => {
        //todo handle form error
        const { err, response } = res
        if (err || !response.ok) {
          dispatch(
            authFormSubmitFailure({ error: "Incorrect login information" })
          )
          return
        }

        const { body } = response

        document.querySelector("html").classList.remove("no-scroll")
        CurrentUser.create(body)
        const { reactivation } = body
        dispatch(closeLoginModal())
        dispatch(
          authenticationSuccess({
            currentUser: CurrentUser,
            reactivation
          })
        )
        dispatch(receiveAuthFormSubmit())
        dispatch(push(editArtistWorksRouteFn(CurrentUser.slug())))

        callback()
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
