import React, { Fragment } from "react"
import StaticPageContainer from "../../../containers/misc/StaticPageContainer"
import Helmet from "../../../components/Helmet"

const title = "Foundwork | Guest Curators"
const description =
  "Leading contemporary art practitioners highlight artists on Foundwork, an artist platform for the global contemporary art community."

export default props => (
  <Fragment>
    <Helmet title={title} description={description} />
    <StaticPageContainer slug="guest-selections" {...props} />
  </Fragment>
)
