import React from "react"
import styles from "./signup"
import classNames from "classnames/bind"
import SignupForm from "../../../containers/login/SignupForm"
import Helmet from "../../../components/Helmet"

const cx = classNames.bind(styles)

export default props => (
  <div className={cx("container")}>
    <Helmet title={`Foundwork | Sign Up`} />
    <SignupForm />
  </div>
)
