import config from "@/config"
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import React from "react"
import LoadingSpinner from "@/ui/elements/LoadingSpinner"
import styled from "styled-components"

interface PapyPalSubscriptionButtonProps {
  onSubscriptionCreated: (id: string) => void
  onError: (error: Record<string, any>) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// NOTE This is necessary to make the PayPal button responsive while still aligning the loading spinner at its center
const ButtonWrapper = styled.div`
  width: 100%;
`

const PayPalSubscriptionButton = ({ onSubscriptionCreated, onError }: PapyPalSubscriptionButtonProps) => {
  const [{ isPending }] = usePayPalScriptReducer()

  return (
    <Container>
      {isPending && <LoadingSpinner />}
      <ButtonWrapper>
        <PayPalButtons
          fundingSource="paypal"
          style={{
            shape: "rect",
            color: "silver",
            layout: "vertical",
            label: "paypal",
            height: 51,
          }}
          createSubscription={(data, actions) =>
            actions.subscription.create({
              plan_id: config.paypal.subscriptionPlanId,
            })
          }
          onApprove={async ({ subscriptionID }) => {
            subscriptionID && onSubscriptionCreated(subscriptionID)
          }}
          onError={(error) => {
            // Rollbar.error("Failed to create PayPal subscription", error) // TODO Rollbar is a global name, not sure how it works in this case
            onError(error)
          }}
        />
      </ButtonWrapper>
    </Container>
  )
}

export default PayPalSubscriptionButton
