import React from "react"
import styles from "./school-select"
import { array, func } from "prop-types"
import classNames from "classnames/bind"
import { rangeRight, sortBy } from "lodash"
import Dropdown from "../Dropdown"

const cx = classNames.bind(styles)

const SchoolSelect = ({ schools, onChange, onCancel }) => {
  return (
    <div className={cx("school-select-form", "container", "wide")}>
      <Dropdown
        className="wide"
        name="school_id"
        label="School"
        onChange={schoolId => onChange({ schoolId })}
        options={sortBy(schools, ["name"]).map(school => ({
          name: `${school.name} (${school.country.short})`,
          value: school.id
        }))}
        placeholder="School"
      />
      <Dropdown
        className="wide"
        name="degree_type"
        label="Degree Type"
        onChange={degreeType => onChange({ degreeType })}
        options={[
          "BA",
          "BA (Hons)",
          "BArch",
          "BFA",
          "BS",
          "BSArch",
          "Diploma",
          "DNAP",
          "DNSEP",
          "MA",
          "MFA",
          "MLitt",
          "PhD"
        ].map(degree => ({
          name: degree,
          value: degree
        }))}
        placeholder="Degree Type"
      />
      <Dropdown
        className="wide"
        name="year"
        label="Graduation Year"
        onChange={year => onChange({ year })}
        options={rangeRight(1950, new Date().getFullYear() + 4).map(year => ({
          name: year,
          value: year
        }))}
        placeholder="Graduation Year"
      />
      <div className={cx("container", "wide")}>
        <div className={cx("cancel-wrapper")}>
          <button className={cx("btn", "btn-gray")} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default SchoolSelect

SchoolSelect.propTypes = {
  schools: array,
  onChange: func,
  onCancel: func
}
