import React from "react"
import classNames from "classnames/bind"
import styles from "./subscription-info"

const cx = classNames.bind(styles)

const SubscriptionInfo = ({coupon}) => {
  return (
    <div className={cx("field")}>
      <div className={cx("label")}>{"Subscription"}</div>
      <div className={cx("info")}>
        {priceString(coupon)}
        {coupon ? <div>{expirationString(coupon)}</div> : null}
      </div>
    </div>
  )
}

const priceString = coupon => {
  const defaultPriceString = "$4.00 / Monthly"

  if (!coupon || coupon.duration !== "forever") {
    return defaultPriceString
  }

  const price = 4 // magic numbers!
  const priceFloat = price - price * (coupon.percentOff / 100)
  const priceString = Number.parseFloat(priceFloat).toFixed(2)
  return `$${priceString} / Monthly`
}

const expirationString = coupon => {
  if (!coupon.expiration) {
    return `Member Code: ${coupon.id}`
  }

  const expiration = coupon.expiration ? new Date(coupon.expiration) : null
  const daysLeft = getDaysLeft(expiration)
  const expString =
    daysLeft !== null
      ? ` (free trial expires in ${daysLeft} ${
        daysLeft === 1 ? "day" : "days"
      })`
      : ""

  if (daysLeft < 0) {
    return `Member Code: ${coupon.id} (free trial expired)`
  }

  return `Member Code: ${coupon.id}${expString}`
}

const ONE_DAY = 24 * 60 * 60 * 1000
const getDaysLeft = expiry => {
  if (!expiry) {
    return null
  }

  const today = new Date()
  const diff = expiry.getTime() - today.getTime()

  return Math.ceil(diff / ONE_DAY)
}

export default SubscriptionInfo
