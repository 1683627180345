import React from "react"
import styles from "./cookieNotice"
import classNames from "classnames/bind"
import useCookieNotice from "@/cookies/useCookieNotice"

export default function CookieNotice() {
  const cx = classNames.bind(styles)

  const [showCookieNotice, acceptCookieNotice] = useCookieNotice()
  return (
    <>
      {showCookieNotice && (
        <div className={cx("sticky-bottom")}>
          <div className={cx("notice")}>
            <p className={cx("notice-content")}>
              We and our service providers use cookies to personalize your experience and for analytics
              purposes. By using our website, you agree to the use of cookies as described in our{" "}
              <a href="/privacy">Privacy Policy</a>.
            </p>
            <button className={cx("close-notice")} onClick={acceptCookieNotice}>
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  )
}
