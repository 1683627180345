import React, { ReactNode } from "react"
import * as RadixDialog from "@radix-ui/react-dialog"
import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import Button, { ButtonVariant } from "@/ui/elements/Button"

type PopupProps = Pick<RadixDialog.DialogProps, "open"> & {
  children: ReactNode
  onClose: () => void
}

const Overlay = styled(RadixDialog.Overlay)`
  ${globalStylesCss};

  background-color: transparent;
  position: fixed;
  inset: 0;
  z-index: 2; /* TODO Investigate why this is needed, without the navbar floats on top */
`

const ContentBox = styled(RadixDialog.Content)`
  ${globalStylesCss};

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: var(--popup-max-width);
  margin: 34px 37px 42px; /* TODO These margins are not related to any standard size/scale */
  background: var(--background-color-primary);
  border: 1px solid var(--color-gray-17);
  box-shadow: var(--box-shadow);
  outline: none;
  z-index: 3; /* This is related to setting the z-index for the Overlay component */
`

const Content = styled.div`
  ${globalStylesCss};

  display: flex;
  flex-direction: column;
  margin: 45px;
`

const Close = styled.div`
  ${globalStylesCss};

  border-top: 1px solid var(--color-gray-17);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px; // TODO Whatever this is...
`

const CloseButton = styled(Button)`
  font-size: var(--font-size-medium);
`

const Popup = ({ open, onClose, children }: PopupProps) => (
  <RadixDialog.Root open={open} defaultOpen={false} modal={true} onOpenChange={(open) => !open && onClose()}>
    <RadixDialog.Portal>
      <Overlay />
      <ContentBox>
        <Content>{children}</Content>
        <RadixDialog.Close asChild>
          <Close>
            <CloseButton variant={ButtonVariant.LIGHT}>Close</CloseButton>
          </Close>
        </RadixDialog.Close>
      </ContentBox>
    </RadixDialog.Portal>
  </RadixDialog.Root>
)

export default Popup
