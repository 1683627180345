import React from "react"
import { actions } from "../../../actions"
import { connect } from "react-redux"
import { useQuery, useMutation, gql } from "@apollo/client"
import ProfileImageForm from "../../../components/ProfileImageForm"

const profileQuery = gql`
  query ProfileWrapperQuery($id: ID!) {
    user(id: $id) {
      id
      artworks {
        id
        title
        videoLink
        primaryImage {
          thumb
        }
      }
    }
  }
`

const UpdateProfileImage = gql`
  mutation UpdateProfileImage($id: ID!, $artworkId: String!) {
    updateProfileImage(id: $id, artworkId: $artworkId) {
      id
      profileImage {
        thumb
      }
    }
  }
`

const ProfileImageFormContainer = ({ id, handleClick, closeModal, selectedArtworkId }) => {
  const response = useQuery(profileQuery, {
    variables: { id },
    fetchPolicy: "network-only",
  })

  const { data } = response || {}
  const { user } = data || {}

  const [mutate] = useMutation(UpdateProfileImage, {
    variables: {
      id,
      artworkId: selectedArtworkId,
    },
    refetchQueries: ["ProfileWrapperQuery"],
  })

  const handleSubmit = () => {
    mutate({ variables: { id, selectedArtworkId } }).then((res) => {
      closeModal()
    })
  }

  return (
    <ProfileImageForm
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      closeModal={closeModal}
      selectedArtworkId={selectedArtworkId}
      user={user}
    />
  )
}

const mapStateToProps = ({ profileImageForm, session }) => {
  const { selectedArtworkId } = profileImageForm
  const { userId } = session

  return { selectedArtworkId, id: userId }
}

const mapDispatchToProps = (dispatch) => {
  const { selectProfileImage, closeProfileImageForm } = actions
  return {
    handleClick: (id) => dispatch(selectProfileImage(id)),
    closeModal: () => dispatch(closeProfileImageForm()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImageFormContainer)
