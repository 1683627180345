// Apollo wrapper for ArtworkForm
import React from "react"
import { useQuery, gql } from "@apollo/client"
import ArtworkForm from "../ArtworkForm"

const formQuery = gql`
  query ArtworkFormQuery {
    formOptions {
      formats {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`

const ArtworkFormWrapper = (props) => {
  const response = useQuery(formQuery)
  const { data } = response || {}
  const { formOptions, loading } = data || {}

  return (
    <div>
      {formOptions && (
        <ArtworkForm
          {...props}
          allFormats={formOptions.formats}
          allCategories={formOptions.categories}
        />
      )}
    </div>
  )
}

export default ArtworkFormWrapper
