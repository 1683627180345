import React, {useEffect} from "react"
import {connect} from "react-redux"
import PersonalSettings from "../../../components/PersonalSettings"
import {actions} from "../../../actions"
import {useMutation} from "@apollo/client"
import {PersonalSettingsQuery, UpdateUser} from "../../../queries/artists"

const PersonalSettingsContainer = (
  {
    id,
    firstName,
    lastName,
    email,
    toggleSaving,
    user,
    open,
    saving,
    handleChange,
    toggleOpen,
    updateField,
  }
) => {
  const [mutate] = useMutation(UpdateUser)
  useEffect(() => {

    const {firstName, lastName, email} = user
    updateField({firstName, lastName, email})
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    toggleSaving()
    mutate({
      variables: {
        id,
        firstName,
        lastName,
        email
      },
      refetchQueries: [
        {
          query: PersonalSettingsQuery,
          variables: {
            id,
          },
        }
      ]
    })
      .then(res => {
        toggleSaving()
      })
      .catch(e => {
        toggleSaving(e.graphQLErrors[0].message)
      })
  }

  const personalInfo = {firstName, lastName, email, open, saving}

  return (
    <PersonalSettings
      {...personalInfo}
      toggleOpen={toggleOpen}
      saving={saving}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  )
}

const mapStateToProps = ({session, settings}) => {
  const {userId: id} = session
  const {
    personalInfo: {firstName, lastName, email, open, saving}
  } = settings

  return {id, firstName, lastName, email, open, saving}
}

const mapDispatchToProps = dispatch => {
  const {
    updatePersonalInfo,
    toggleSettingsForm,
    toggleSettingsSaving
  } = actions

  return {
    updateField: params => dispatch(updatePersonalInfo(params)),
    toggleOpen: () => dispatch(toggleSettingsForm("personalInfo")),
    toggleSaving: error =>
      dispatch(
        toggleSettingsSaving({
          kind: "personalInfo",
          error
        })
      ),
    handleChange: e => {
      dispatch(updatePersonalInfo({[e.target.name]: e.target.value}))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalSettingsContainer)
