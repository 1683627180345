import React from "react"
import Header from "../../../containers/cms/Header"
import CvWrapper from "../../../containers/cms/CvWrapper"
import CmsBackground from "../../../components/CmsBackground"

export default () => (
  <div>
    <Header title="CV" />
    <CmsBackground>
      <CvWrapper />
    </CmsBackground>
  </div>
)
