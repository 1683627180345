import React from "react"
import { useQuery, gql } from "@apollo/client"
import { connect } from "react-redux"
import PersonalSettingsContainer from "../PersonalSettings"
import {PersonalSettingsQuery} from "../../../queries/artists"

const PersonalSettingsQueryContainer = ({id}) => {
  const response = useQuery(PersonalSettingsQuery, {
      variables: { id }
  })
  const { data } = response || {}
  const { user } = data || {}

  return user ? <PersonalSettingsContainer user={user} /> : null
}

const mapStateToProps = ({ session }) => {
  const { userId } = session

  return { id: userId }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalSettingsQueryContainer)
