import React, {Component} from "react"
import {withRouter} from "react-router"
import {connect} from "react-redux"
import {actions} from "../../actions"
import {action as toggleMenu} from "redux-burger-menu"

class App extends Component {
  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.closeHeaderMenu()
      this.props.closeHeaderUserMenu()
      if (!location.hash) {
        document.body.scrollTop = 0 // For Chrome, Safari and Opera
        document.documentElement.scrollTop = 0 // For IE and Firefox
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  const {closeHeaderUserMenu} = actions

  return {
    closeHeaderMenu: () => dispatch(toggleMenu(false)),
    closeHeaderUserMenu: () => dispatch(closeHeaderUserMenu())
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
