import React from "react"
import {func, string} from "prop-types"
import ProfileImageOption from "../ProfileImageOption"
import styles from "./profile-image-form"
import classNames from "classnames/bind"
import ProfileImageHint from "./profileImageHint"

const cx = classNames.bind(styles)

const ProfileImageForm = (
  {
    handleClick,
    handleSubmit,
    closeModal,
    selectedArtworkId,
    user
  }
) => {
  const artworks = user ? user.artworks : []

  const selectableArtworks = artworks.filter((artwork) => !artwork.videoLink)

  return (
    <div className={cx("container")}>
      <div className={cx("artworks-container")}>
        {_.isEmpty(selectableArtworks) && <ProfileImageHint/>}
        {selectableArtworks.map(artwork => {
          return (
            <ProfileImageOption
              {...artwork}
              key={artwork.id}
              selected={artwork.id.toString() === selectedArtworkId}
              handleClick={e => {
                e.preventDefault()
                handleClick(artwork.id)
              }}
            />
          )
        })}
      </div>
      <div className={cx("button-container")}>
        <button
          className={cx(["cta", "blue"])}
          onClick={e => {
            e.preventDefault()
            handleSubmit(selectedArtworkId)
          }}
        >
          Apply
        </button>
        <button
          className={cx(["cta", "gray"])}
          onClick={e => {
            e.preventDefault()
            closeModal()
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

ProfileImageForm.propTypes = {
  handleClick: func,
  handleSubmit: func,
  closeModal: func,
  selectedArtworkId: string
}

export default ProfileImageForm
