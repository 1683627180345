import React from "react"
import styled from "styled-components"
import LoadingSpinner from "@/ui/elements/LoadingSpinner"

const CenterFrame = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const LoadingFrame = () => (
  <CenterFrame>
    <LoadingSpinner />
  </CenterFrame>
)

export default LoadingFrame
