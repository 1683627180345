import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

const Title = styled.h1`
  ${globalStylesCss};

  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-heading);
  color: var(--font-color-secondary);
  text-align: center;
  line-height: normal;
  margin: 0 0 1em 0;
`

export default Title
