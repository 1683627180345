import { types } from "../actions"

const funnel = (
  state = {
    user: {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    },
    school: {
      school_id: "",
      degree_type: "",
      year: ""
    }
  },
  { type, payload }
) => {
  switch (type) {
    case types.SET_FUNNEL_DATA: {
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          ...payload.data
        }
      }
    }
    default: {
      return state
    }
  }
}

export default funnel
