import * as RadixDialog from "@radix-ui/react-dialog"
import React from "react"
import styled, { css } from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import Button from "@/ui/elements/Button"

// TODO
// These rules are in place to counteract the global effect of _layout.css
// To be removed once the migration is complete.
const resetRules = css`
  color: var(--font-color-inverted-primary);
`

type DialogProps = Pick<RadixDialog.DialogProps, "open"> & {
  title: string
  description: string
  cta: string
  onClose: () => void
}

const Overlay = styled(RadixDialog.Overlay)`
  ${globalStylesCss};

  background-color: var(--dialog-background-color);
  backdrop-filter: blur(4px);
  position: fixed;
  inset: 0;
  z-index: 2; /* TODO Investigate why this is needed, without the navbar floats on top */
`

const Content = styled(RadixDialog.Content)`
  ${globalStylesCss};

  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 180px;
  width: 100%;
  max-width: var(--dialog-message-max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--font-color-inverted-primary);
  z-index: 3; /* This is related to setting the z-index for the Overlay component */
`

const Title = styled(RadixDialog.Title)`
  ${globalStylesCss};

  font: var(--font-weight-heading) var(--font-size-heading) / normal var(--font-family-heading);
  color: var(--font-color-inverted-primary);
  letter-spacing: 1px;
`

const Description = styled(RadixDialog.Description)`
  ${globalStylesCss};
  ${resetRules};

  text-align: center;
  padding-bottom: 2em;
`

const ConfirmationDialog = ({ open, title, description, cta, onClose }: DialogProps) => (
  <RadixDialog.Root open={open} defaultOpen={false} modal={true} onOpenChange={(open) => !open && onClose()}>
    <RadixDialog.Portal>
      <Overlay />
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <RadixDialog.Close asChild>
          <Button>{cta}</Button>
        </RadixDialog.Close>
      </Content>
    </RadixDialog.Portal>
  </RadixDialog.Root>
)

export default ConfirmationDialog
