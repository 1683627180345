import React, { Component } from "react"
import { Link } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import classNames from "classnames/bind"
import GuestArtistCard from "../GuestArtistCard"
import Helmet from "../Helmet"
import MediaQuery from "react-responsive"
import { guestCuratorsRoute, guestSelectionsDetailRouteFn } from "../../constants"
import styles from "./guest-card"
const cx = classNames.bind(styles)

const DisplayCard = (props) => {
  const {
    content: guestBio,
    sections: artists,
    image,
    title: guestName,
    slug,
    isMobile,
    isExpand,
    setMetaTags,
    onClick,
    displayExpandToggle,
  } = props
  return (
    <div>
      {setMetaTags && <Helmet title={`Foundwork | Guest Curators | ${guestName}`} description={guestBio} />}
      <div
        className={cx({
          "display-card": true,
          "is-mobile": isMobile,
          "is-expand": isMobile && isExpand,
        })}
      >
        <div className={cx("artist-portrait")}>
          <img src={`${image.url}?w=400`} alt="artist portrait" />
        </div>
        <h2 className={cx("guest-name")}>
          <Link to={guestSelectionsDetailRouteFn(slug)}>{guestName}</Link>
        </h2>
        {isMobile && !isExpand && <span className={cx("description")}>{image.description}</span>}
        {(!isMobile || isExpand) && (
          <ReactMarkdown
            children={guestBio}
            renderers={{
              link: (props) => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              ),
            }}
          />
        )}
        {isMobile && displayExpandToggle && (
          <button onClick={onClick} className={cx("toggle-is-expand", { "is-expand": !isExpand })}>
            {isExpand ? <CloseIcon /> : <OpenIcon />}
          </button>
        )}
      </div>
      {(!isMobile || isExpand) && (
        <div className={cx("artist-cards")}>
          {artists.map((artist, i) => (
            <GuestArtistCard key={i} {...artist} />
          ))}
        </div>
      )}
    </div>
  )
}

export const OpenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path fill="#9b9b9b" d="M6 6H2v2h4v4h2V8h4V6H8V2H6v4z" />
  </svg>
)

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path fill="#9b9b9b" d="M2 6h10v2H2z" />
  </svg>
)

// On mobile, GuestCard has collapse / expand state
export default class GuestCard extends Component {
  constructor(props) {
    super(props)
    // On initialization for mobile, the first GuestCard renders in expanded state and the rest are collapsed
    this.state = {
      isExpand: Boolean(props.index === 0),
    }
  }

  toggleIsExpand = () => {
    this.setState(() => ({ isExpand: !this.state.isExpand }))
  }

  render() {
    return (
      <div className={cx("guest-card")} id={this.props.slug}>
        <MediaQuery maxWidth={500}>
          {(isMobile) => (
            <DisplayCard
              {...this.props}
              onClick={this.toggleIsExpand}
              isMobile={isMobile}
              isExpand={this.state.isExpand}
              index={this.props.index}
              displayExpandToggle={this.props.displayExpandToggle}
            />
          )}
        </MediaQuery>
        {this.props.displayBackToGuestSelectionsPageLink && (
          <p className={cx("back-to-guest-selections-cta")}>
            <a href={guestCuratorsRoute}>View more Guest Curators</a>
          </p>
        )}
      </div>
    )
  }
}
