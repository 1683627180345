import React from "react"
import ReactMarkdown from "react-markdown"
import { Link } from "react-router-dom"
import MediaQuery from "react-responsive"
import slugify from "slugify"

import classNames from "classnames/bind"
import styles from "./prize"
import { artistDetailRouteFn } from "../../constants"
import PrizeAnnouncement from "../PrizeAnnouncement"
import JuryCard from "../JuryCard"
import StaticHero from "../StaticHero"
import Helmet from "../Helmet"
import LoadingSpinner from "../LoadingSpinner"

const cx = classNames.bind(styles)
const createArtistUrl = (name) => {
  const exceptions = {
    "Elliott Jerome Brown Jr.": "elliottbrownjr", // Short list 2019
    "Clifford Prince King": "cliffordking", // Short list 2023
  }

  name = exceptions[name] ? exceptions[name] : name

  return slugify(name).toLowerCase().replace(/-/g, "")
}

const getArtistUrlFromSlug = (slug) => {
  const exceptions = {
    "valerie-asiimwe-amani": "valerie-amani", // Honoree 2023
  }

  let name = slug.split("prize-page-honoree-")[1]

  if (exceptions[name] !== undefined) {
    name = exceptions[name]
  }

  return slugify(name).toLowerCase().replace(/-/g, "")
}

export default ({ content, sections, title }) => {
  return (
    <div>
      <Helmet title={`Foundwork | Artist Prize`} />
      {sections.length ? (
        <div>
          <StaticHero title={title} content={content} />
          <ComponentList sections={sections} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}

const ComponentList = ({ sections = [] }) => (
  <React.Fragment>
    {sections.map((section, index) => {
      switch (true) {
        case section.slug.includes("prize-page-jury"):
          return <Jury {...section} key={section.slug} />
        case section.slug.includes("prize-page-announcement"):
          return <PrizeAnnouncement {...section} key={section.slug} />
        case section.slug.includes("prize-page-honoree"):
          return <Hero {...section} key={section.slug} />
        case section.slug.includes("prize-page-about"):
          return <About {...section} key={section.slug} />
        case section.slug.includes("prize-page-shortlist"):
          return <Shortlist {...section} key={section.slug} />
        default:
          return null
      }
    })}
  </React.Fragment>
)

const Hero = ({ slug, title, content, image, sections }) => (
  <div className={cx("hero-wrapper")}>
    <div className={cx("hero-outer")}>
      <div className={cx("hero-top")}>
        <div className={cx("hero-top-details")}>
          <h3>{title}</h3>
          <ReactMarkdown children={content} className={cx("content")} />
          <p className={cx("hero-image-details")} dangerouslySetInnerHTML={{ __html: image.title }}></p>
        </div>

        <div className={cx("hero-image-wrapper")}>
          <img alt={image.title} src={image.url}></img>
          <p className={cx("hero-image-details")} dangerouslySetInnerHTML={{ __html: image.title }}></p>
        </div>
      </div>

      <div className={cx("hero-description")}>
        <ReactMarkdown children={image.description} />
      </div>
    </div>

    <div className={cx("hero-works")}>
      {sections.map((work, index) => (
        <div key={index} className={cx("hero-work")}>
          <Link to={artistDetailRouteFn(getArtistUrlFromSlug(slug))}>
            <img alt={image.title} src={work.image.url}></img>
          </Link>
          <ReactMarkdown children={work.content} className={cx("content")} />
        </div>
      ))}
    </div>
  </div>
)

const Jury = ({ title, content, sections }) => (
  <div className={cx("jury-wrapper")}>
    <div className={cx("jury")}>
      <div className={cx("jury-title")}>
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
      {sections.map((member, i) => (
        <div key={member.id} className={cx("jury-member")}>
          <MediaQuery maxWidth={500}>
            {(isMobile) => <JuryCard {...member} isMobile={isMobile} index={i} />}
          </MediaQuery>
        </div>
      ))}
    </div>
  </div>
)

const Shortlist = ({ title, sections }) => (
  <div className={cx("shortlist")}>
    <h2>{title}</h2>
    <DesktopShortlistMembers sections={sections} />
    <MobileShortlistMembers sections={sections} />
  </div>
)

const DesktopShortlistMembers = ({ sections }) => (
  <div className={cx("shortlist-members-desktop")}>
    <div className={cx("shortlist-flex-container")}>
      {sections.map((member, index) => (
        <div key={index} className={cx("shortlist-name")}>
          <Link to={artistDetailRouteFn(createArtistUrl(member.title))}>
            <h4>{member.title}</h4>
          </Link>
          <ReactMarkdown children={member.content} className={cx("content")} />
        </div>
      ))}
    </div>

    <div className={cx("shortlist-flex-container")}>
      {sections.map((member, index) => (
        <div key={index} className={cx("shortlist-work")}>
          <Link key={member.title} to={artistDetailRouteFn(createArtistUrl(member.title))}>
            <img alt={member.image.title} src={member.image.url} />
          </Link>
        </div>
      ))}
    </div>

    <div className={cx("shortlist-flex-container")}>
      {sections.map(({ image }, index) => (
        <div key={index} className={cx("shortlist-work-details")}>
          <p dangerouslySetInnerHTML={{ __html: image.title }}></p>
          <ReactMarkdown children={image.description} />
        </div>
      ))}
    </div>
  </div>
)

const MobileShortlistMembers = ({ sections }) => (
  <div className={cx("shortlist-members-mobile")}>
    {sections.map(({ title, content, image }) => (
      <div className={cx("shortlist-member-mobile")} key={title}>
        <Link to={artistDetailRouteFn(createArtistUrl(title))}>
          <h4>{title}</h4>
        </Link>
        <ReactMarkdown children={content} className={cx("lives-works")} />
        <Link to={artistDetailRouteFn(createArtistUrl(title))}>
          <img alt={image.title} src={image.url} />
        </Link>
        <p dangerouslySetInnerHTML={{ __html: image.title }}></p>
        <ReactMarkdown children={image.description} className={cx("")} />
      </div>
    ))}
  </div>
)

const About = ({ title, content }) => (
  <div className={cx("about")}>
    <h2>{title}</h2>
    <ReactMarkdown children={content} />
  </div>
)
