import React from "react"
import PersonalSettingsQuery from "../../../containers/settings/PersonalSettingsQuery"
import MembershipSettingsQuery from "../../../containers/settings/MembershipSettingsQuery"
import BillingSettingsQuery from "../../../containers/settings/BillingSettingsQuery"
import PageTitleHeader from "../../../components/PageTitleHeader"
import styles from "./settings"
import classNames from "classnames/bind"
import CancelMembershipQuery from "../../../containers/settings/CancelMembership"
import {connect} from "react-redux"
import InvoiceList from "../../../components/InvoiceList"

const cx = classNames.bind(styles)

const Settings = ({isArtist}) => {
  return (
    <div className={cx("")}>
      <PageTitleHeader title={"Settings"}/>
      <div className={cx("content-container")}>
        <div className={cx("inner-container")}>
          <PersonalSettingsQuery/>
          <MembershipSettingsQuery/>
          {
            isArtist &&
            <>
              <BillingSettingsQuery/>
              <InvoiceList/>
            </>
          }
          <CancelMembershipQuery/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({session}) => ({
  isArtist: session.isArtist,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
