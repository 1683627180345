import React from "react"
import { Link } from "react-router-dom"
import styles from "./login-flow-prompt"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

export default ({ text, url, linkText, linkColor = "blue" }) => (
  <p className={cx("signup-prompt")}>
    {text}{" "}
    <Link className={cx(linkColor)} to={url}>
      {linkText}
    </Link>
  </p>
)
