import api from "@/api"
import { Coupon } from "./Coupon"

const couponValidator = {
  async validate(code?: string): Promise<Coupon | undefined> {
    if (code && code.length > 3) {
      const { error, coupon } = await api.fetchCoupon(code)
      return error ? Coupon.invalidCoupon : new Coupon({ code, ...coupon })
    } else {
      return undefined
    }
  },
}

export default couponValidator
