import React from "react"
import styles from "./password-update-form"
import classNames from "classnames/bind"
import Input from "../Input"

const cx = classNames.bind(styles)

const PasswordUpdateForm = ({
  changePassword,
  handleChange,
  saving,
  fields,
  currentPassword,
  error,
  newPassword
}) => (
  <div className={cx("container")}>
    <div className={cx("form")}>
      <div className={cx("inputs")}>
        <Input
          id="currentPassword"
          name="currentPassword"
          label="Current Password"
          type="password"
          value={currentPassword}
          handleChange={handleChange}
          required={true}
        />
        <Input
          id="newPassword"
          name="newPassword"
          label="New Password"
          type="password"
          value={newPassword}
          handleChange={handleChange}
          required={true}
        />
      </div>
      {error && <div className={cx("error")}>{error}</div>}
      <button className={"btn btn-blue"} onClick={changePassword}>
        {saving ? "Saving..." : "Update"}
      </button>
    </div>
  </div>
)

export default PasswordUpdateForm
