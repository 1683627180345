import React from "react"
import classNames from "classnames/bind"
import { connect } from "react-redux"
import { func, object, array } from "prop-types"
import { push } from "connected-react-router"
import { actions } from "../../../actions"
import styles from "./search-controls"
import Dropdown from "../../../components/Dropdown"
import OverlayLink from "../../../components/OverlayLink"
import RadioFilter from "../../../components/RadioFilter"
import Autocomplete from "../../../components/Autocomplete"
import ActiveFilters from "../../../components/ActiveFilters"
import LocationFilter from "../../../components/LocationFilter"
import { rangeRight } from "lodash"

const endRange = new Date().getFullYear() + 4

const formattedYears = years => {
  if (years && years.length > 1) {
    const sortedYears = years.sort()
    return [`Class of ${sortedYears[0]}-${sortedYears[1]}`]
  } else if (years.length === 0) {
    return []
  }
  return [`Class of ${years[0]}`]
}

const alphabeticalOrder = (a, b) => {
  if (a.name === b.name) return 0
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1

  throw new Error(`{a.name} neither ===, <, or > than ${b.name}`)
}

const cx = classNames.bind(styles)

class SearchControls extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMobile = this.toggleMobile.bind(this)
    this.state = {
      showFilters: false
    }
  }

  toggleMobile() {
    this.setState({
      showFilters: !this.state.showFilters
    })

    if (this.state.showFilters) {
      document.querySelector("html").classList.remove("no-scroll")
    } else {
      document.querySelector("html").classList.add("no-scroll")
    }
  }

  render() {
    const {
      addCategory,
      addDegreeType,
      addFormat,
      addOrgOrSchool,
      categories,
      distance,
      formats,
      lat,
      place,
      programYears,
      removeCategory,
      removeDegreeType,
      removeFormat,
      removeOrgOrSchool,
      resetCategories,
      resetFilters,
      resetFormats,
      resetLocationSearch,
      resetOrgsAndSchools,
      selectedDegreeTypes,
      selectedOrgsAndSchools,
      selectProgramYear,
      selectProgramYearRange,
      unit,
      updateLocationSearch
    } = this.props

    const {
      artworkFormats,
      artworkCategories,
      degreeTypes,
      schools,
      organizations
    } = this.props.searchOptions

    const { showFilters } = this.state
    const locationFilterLength = place.valid ? +1 : +0
    const filterCount =
      formats.length +
      categories.length +
      programYears.length +
      selectedOrgsAndSchools.length +
      selectedDegreeTypes.length +
      locationFilterLength
    const hasFilters = filterCount > 0

    // Rework data to fit standards for Components Schools and Orgs
    // are visually represented as the same thing in search, but diff
    // when passed to the query.
    const mfaProgramOptions = schools.map(val => ({
      name: `${val.name} (${val.country.short})`,
      value: val.id,
      type: "school"
    }))

    const organizationOptions = organizations.map(org => ({
      name: `${org.name} (${org.country.short})`,
      value: org.id,
      type: "organization"
    }))

    return (
      <div className={cx("container")}>
        <button
          className={cx({ "mobile-button": true, active: hasFilters })}
          onClick={e => {
            e.preventDefault()
            this.toggleMobile()
          }}
        >
          {`Filters${hasFilters ? ` (${filterCount})` : ""}`}
        </button>
        <div className={cx({ default: true, active: showFilters })}>
          <div className={cx("mobile-header")}>
            <button
              onClick={e => {
                e.preventDefault()
                this.toggleMobile()
                resetFilters()
              }}
            >
              Clear
            </button>
            <span>Filters</span>
            <button
              className={cx("save-button")}
              onClick={e => {
                e.preventDefault()
                this.toggleMobile()
              }}
            >
              Save
            </button>
          </div>
          <OverlayLink
            label="Format"
            onReset={resetFormats}
            activeFilters={formats.map(
              a => artworkFormats.find(b => b.id === a.toString()).name
            )}
          >
            <div className={cx("mobile-label")}>Formats</div>
            <ul className={cx("filter-formats")}>
              {artworkFormats.map(format => (
                <RadioFilter
                  key={format.id}
                  value={format.id}
                  name={format.name}
                  isActive={formats.indexOf(format.id) > -1}
                  onActiveClick={removeFormat}
                  onInactiveClick={addFormat}
                />
              ))}
            </ul>
          </OverlayLink>
          <OverlayLink
            label="Attributes"
            onReset={resetCategories}
            activeFilters={categories.map(
              a => artworkCategories.find(b => b.id === a.toString()).name
            )}
          >
            <div className={cx("mobile-label")}>Attributes</div>
            <ul className={cx("filter-formats")}>
              {artworkCategories.map(category => (
                <RadioFilter
                  key={category.id}
                  value={category.id}
                  name={category.name}
                  isActive={categories.indexOf(category.id) > -1}
                  onActiveClick={removeCategory}
                  onInactiveClick={addCategory}
                />
              ))}
            </ul>
          </OverlayLink>
          <OverlayLink
            label="Location"
            onReset={resetLocationSearch}
            activeFilters={
              lat
                ? [
                    `Within ${distance} ${unit === "MI" ? "miles" : "km"} of ${
                      place.value
                    }`
                  ]
                : []
            }
          >
            <LocationFilter
              updateLocationSearch={updateLocationSearch}
              place={place}
              distance={distance}
              unit={unit}
            />
          </OverlayLink>
          <OverlayLink
            label="Affiliation"
            onReset={resetOrgsAndSchools}
            alignment="right"
            activeFilters={[
              ...formattedYears(programYears),
              ...selectedOrgsAndSchools.map(a => a.name),
              ...selectedDegreeTypes
            ]}
          >
            <div className={cx("filter-split")}>
              <div className={cx(["filter-column", "school-column"])}>
                <Autocomplete
                  label="School or Organization"
                  data={organizationOptions
                    .concat(mfaProgramOptions)
                    .sort(alphabeticalOrder)
                    .filter(option => {
                      const selectedNames = selectedOrgsAndSchools.map(
                        val => val.name
                      )
                      return selectedNames.indexOf(option.name) < 0
                    })}
                  onUpdate={addOrgOrSchool}
                  isInitiallyOpen={true}
                />
                <ActiveFilters
                  filters={selectedOrgsAndSchools}
                  onRemove={removeOrgOrSchool}
                />
              </div>
              <div className={cx(["filter-column", "year-column"])}>
                <div className={cx("dropdown")}>
                  <Dropdown
                    name="classYear"
                    label="Class Year"
                    onChange={selectProgramYear}
                    placeholder="Year"
                    value={programYears[0] || ""}
                    options={rangeRight(1950, endRange).map(year => ({
                      name: year,
                      value: year
                    }))}
                  />
                </div>
                <div className={cx("dropdown")}>
                  <Dropdown
                    name="classYearOptional"
                    label="Add Range (Optional)"
                    onChange={selectProgramYearRange}
                    placeholder="Year"
                    value={programYears[1] || ""}
                    options={rangeRight(1950, endRange).map(year => ({
                      name: year,
                      value: year
                    }))}
                  />
                </div>

                <div className={cx(["dropdown", "degree-dropdown"])}>
                  <Dropdown
                    name="degreeTypes"
                    label="Degree Type"
                    onChange={addDegreeType}
                    placeholder="Degree"
                    options={degreeTypes
                      .filter(d => selectedDegreeTypes.indexOf(d) === -1)
                      .map(degree => ({
                        name: degree,
                        value: degree
                      }))}
                    resets
                  />
                </div>
                <ActiveFilters
                  filters={selectedDegreeTypes}
                  onRemove={removeDegreeType}
                />
              </div>
            </div>
          </OverlayLink>
        </div>
      </div>
    )
  }
}

SearchControls.propTypes = {
  searchOptions: object.isRequired,
  formats: array,
  categories: array,
  programYears: array,
  selectedOrgsAndSchools: array,
  addFormat: func,
  removeFormat: func,
  resetFormats: func,
  selectProgramYear: func,
  selectProgramYearRange: func,
  addOrgOrSchool: func,
  removeOrgOrSchool: func,
  resetOrgsAndSchools: func,
  resetArtistDetails: func,
  resetFilters: func
}

const mapStateToProps = ({ search }) => {
  const {
    formats,
    categories,
    location,
    programYears,
    mfaPrograms,
    organizations,
    degreeTypes
  } = search
  const { place, distance, unit, lat } = location

  return {
    formats,
    categories,
    place,
    distance,
    unit,
    lat,
    programYears,
    selectedOrgsAndSchools: organizations.concat(mfaPrograms),
    selectedDegreeTypes: degreeTypes
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addFormat: id => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.addSearchFormat({ id }))
    },
    removeFormat: id => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.removeSearchFormat({ id }))
    },
    resetFormats: () => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.resetSearchFormats())
    },
    addCategory: id => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.addSearchCategory({ id }))
    },
    removeCategory: id => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.removeSearchCategory({ id }))
    },
    resetCategories: () => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.resetSearchCategories())
    },
    updateLocationSearch: data => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.updateLocationSearch(data))
    },
    resetLocationSearch: () => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.resetLocationSearch())
    },
    selectProgramYear: year => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      if (year) {
        dispatch(actions.selectSearchProgramYear({ year }))
      } else {
        dispatch(actions.removeProgramYear({ index: 0 }))
      }
    },
    selectProgramYearRange: year => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      if (year) {
        dispatch(actions.selectSearchProgramYearRange({ year }))
      } else {
        dispatch(actions.removeProgramYear({ index: 1 }))
      }
    },
    addDegreeType: degreeType => {
      if (!degreeType) return
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.addSearchDegreeType(degreeType))
    },
    removeDegreeType: degreeType => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.removeSearchDegreeType(degreeType))
    },
    addOrgOrSchool: program => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      if (program.type === "school") {
        dispatch(actions.addSearchMfaProgram({ program }))
      } else {
        dispatch(actions.addSearchOrganization({ organization: program }))
      }
    },
    removeOrgOrSchool: program => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      if (program.type === "school") {
        dispatch(actions.removeSearchMfaProgram({ program }))
      } else {
        dispatch(actions.removeSearchOrganization({ organization: program }))
      }
    },
    resetOrgsAndSchools: program => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.resetSearchOrgOrSchool())
    },
    resetFilters: () => {
      dispatch(actions.selectSearchPage({ page: 1 }))
      dispatch(push({ search: "page=1" }))
      dispatch(actions.resetSearchFilters())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchControls)
