import React from "react"
import classNames from "classnames/bind"
import { Link } from "react-router-dom"
import styles from "../ContentCard/content-card"
import { artistDetailRouteFn } from "../../constants"

const cx = classNames.bind(styles)

const ArtistCard = ({ content, onTitleSelect, onImageSelect, index }) => {
  const artist = content
  return (
    <div className={cx(["container", "artist-card"])}>
      {artist && (
        <div className={cx(["content", "artist-content"])}>
          <h4 className={cx("artist-name")}>
            <Link to={artistDetailRouteFn(content.slug)}>{artist.name}</Link>
          </h4>
          <div className={cx("lives-works")}>
            {artist.livesAndWorks || "Nomadic"}
          </div>
          <div className={cx("extra-info")}>
            <div className={cx("formats")}>
              {artist.formats &&
                artist.formats.map((f, i) => f.name).join(", ")}
            </div>
          </div>
        </div>
      )}
      <div className={cx("image-wrapper")}>
        <Link
          to={artistDetailRouteFn(content.slug)}
          name={`${artist.name} Profile`}
        >
          <img alt={artist.name} src={artist.profileImage.medium} />
        </Link>
      </div>
    </div>
  )
}

export default ArtistCard
