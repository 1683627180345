// Apollo wrapper for SchoolForm
import React from "react"
import { useQuery, gql } from "@apollo/client"
import SchoolForm from "../SchoolForm"
import GraduationList from "../GraduationList"

const formQuery = gql`
  query SchoolFormWrapperQuery {
    schools {
      id
      name
      country {
        short
      }
    }
  }
`

const SchoolFormWrapper = ({ user }) => {
  const response = useQuery(formQuery)

  const { data } = response || {}
  const { schools } = data || {}

  return (
    <div>
      {schools ? <SchoolForm schools={schools} user={user} /> : null}

      {user ? <GraduationList user={user} /> : null}
    </div>
  )
}

export default SchoolFormWrapper
