import React from "react"
import styles from "./cancel-membership"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const CancelMembership = (
  {
    canceling,
    cancelAccount,
  }
) => {
  return (
    <button
      onClick={cancelAccount}
      className={cx(["btn", "btn-gray", "btn-cancel", "pointer"])}
      disabled={canceling}
    >
      {canceling ? "Canceling..." : "Cancel Account"}
    </button>
  )
}

export default CancelMembership
