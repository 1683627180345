import React from "react"
import styles from "./artwork-list"
import classNames from "classnames/bind"
import { array } from "prop-types"

const cx = classNames.bind(styles)

const ArtworkList = ({ children }) => (
  <ul className={cx("list")}>
    {children}
  </ul>
)

ArtworkList.propTypes = {
  children: array
}

export default ArtworkList
