import React from "react"
import ResetPasswordWrapper from "../../../containers/login/ResetPasswordWrapper"
import styles from "./reset-password"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ResetPassword = ({
  match: {
    params: { token }
  }
}) => {
  return (
    <div className={cx("container")}>
      <ResetPasswordWrapper token={token} />
    </div>
  )
}

export default ResetPassword
