import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { connect } from 'react-redux'
import { string, number, array, obj } from 'prop-types'
import { omit } from 'lodash'
import classNames from 'classnames/bind'
import styles from './artist-search'
import SearchOptions from '../../../containers/explore/SearchOptions'
import SortingOptions from '../../../containers/explore/SortingOptions'
import ArtistResults from '../../../containers/explore/ArtistResults'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Helmet from '../../../components/Helmet'

const cx = classNames.bind(styles)

const artistQuery = gql`
  query ArtistSearchQuery($params: SearchParams) {
    artists(params: $params) {
      currentPage
      totalPages
      data {
        slug
        name
        livesAndWorks
        graduations {
          year
          degreeType
          school {
            name
          }
        }
        profileImage {
          medium
        }
        formats {
          name
        }
      }
    }
  }
`

const sortingOptions = [
  {
    name: 'Featured',
    value: '-featured,-updated_at',
  },
  {
    name: 'Recently Updated',
    value: '-updated_at',
  },
  {
    name: 'Artist A-Z',
    value: 'last_name',
  },
  {
    name: 'Artist Z-A',
    value: '-last_name',
  },
]

const ArtistSearch = ({
  order,
  page,
  formats,
  categories,
  location,
  programs,
  organizations,
  years,
  degreeTypes,
}) => {
  const response = useQuery(artistQuery, {
    variables: {
      params: {
        order,
        page,
        formats,
        categories,
        organizations,
        programs,
        location,
        years,
        degreeTypes,
      },
    },
  })
  const { data, loading } = response || {}
  const { artists } = data || {}

  return (
    <div className={cx('container')}>
      <Helmet title={`Foundwork | Artists`} />
      <SearchOptions />
      <SortingOptions label={'Sort by'} name={'artist_sort'} format={'artists'} options={sortingOptions} />
      {loading && <LoadingSpinner />}
      <ArtistResults
        items={artists ? artists.data : []}
        loading={loading}
        currentPage={artists ? artists.currentPage : null}
        totalPages={artists ? artists.totalPages : null}
      />
    </div>
  )
}

ArtistSearch.propTypes = {
  order: string,
  page: number,
  formats: array,
  categories: array,
  location: obj,
  programs: array,
  organizations: array,
  years: array,
}

const mapStateToProps = ({ search }) => {
  const {
    order,
    page,
    formats,
    categories,
    location,
    mfaPrograms,
    organizations,
    programYears,
    degreeTypes,
  } = search
  return {
    order: order.artists,
    page,
    formats,
    categories,
    location: omit(location, 'place'),
    organizations: organizations.map((o) => o.value) || [],
    programs: mfaPrograms.map((a) => a.value) || [],
    years: programYears.map((a) => parseInt(a, 10)),
    degreeTypes,
  }
}

export default connect(mapStateToProps)(ArtistSearch)
