import { createGlobalStyle, css } from "styled-components"

const typographyRules = css`
  font-family: var(--font-family-primary);
  font-size: var(--font-size-primary);
  color: var(--font-color-primary);
  line-height: var(--line-height-default);
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
`

// TODO This is a workaround to prevent injecting global styles that will clash with the existing CSS code. If an when
// the migration is done, we should remove injecting this CSS snippets into every components and just streamline it as
// global rules
export const typographyCss = typographyRules

const Typography = createGlobalStyle`
  :root {
    ${typographyRules}
  }

  * {
    ${typographyRules}
  }
`

export default Typography
