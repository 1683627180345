import React from "react"
import { connect } from "react-redux"
import { bool, func } from "prop-types"
import CurrentUser from "../../../currentUser"
import { actions } from "../../../actions.js"
import { useQuery, gql } from "@apollo/client"
import HeaderUserMenu from "../../../components/HeaderUserMenu"
import {useLoggedInUser} from "../../misc/loggedInUserContext"

const headerQuery = gql`
  query headerQuery($id: ID!) {
    user(id: $id) {
      id
      name
    }
  }
`

const HeaderUserMenuContainer = ({ id, loggedIn, isArtist, logOut }) => {
  const response = useQuery(headerQuery, {
    variables: { id }
  })
  const { data } = response || {}
  const { user } = data || {}

  const {setLoggedInUser} = useLoggedInUser()

  const doLogOut = () => {
    logOut()
    setLoggedInUser(null)
  }

  return (
    <HeaderUserMenu
      username={user ? user.name : ""}
      loggedIn={loggedIn}
      isArtist={isArtist}
      logOut={doLogOut}
    />
  )
}

HeaderUserMenuContainer.propTypes = {
  loggedIn: bool,
  logOut: func
}

const mapStateToProps = state => {
  const { session } = state
  const { loggedIn, userId, isArtist } = session

  return {
    loggedIn,
    isArtist,
    id: userId
  }
}

const mapDispatchToProps = dispatch => {
  const { logOut } = actions

  return {
    logOut: () => {
      CurrentUser.clear()
      dispatch(logOut())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderUserMenuContainer)
