import React, { Fragment } from "react"
import ReactMarkdown from "react-markdown"
import DialoguesSection from "../DialoguesSection"
import classNames from "classnames/bind"
import { Link } from "react-router-dom"
import styles from "./dialogues-detail"
import Helmet from "../Helmet"
import LoadingSpinner from "../LoadingSpinner"
import Mail from "../../images/mail"
import Facebook from "../../images/facebook"
import Twitter from "../../images/twitter"

const cx = classNames.bind(styles)

export default props => {
  const { sections } = props
  const interviewer = sections.find(({ title }) => title === "interviewer")
  const previews = sections.filter(({ slug }) =>
    slug.includes("dialogues-preview")
  )
  return (
    <div>
      <Helmet title={`${props.title} | Foundwork Dialogues`} />
      {sections.length ? (
        <Fragment>
          <div className={cx("container")}>
            {props.title && <Hero {...props} />}
            {sections.map(section => matcher(section))}
          </div>
          {interviewer && <Interviewer {...interviewer} />}
          {previews && <PreviousInterviews previews={previews} />}
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}

const matcher = section => {
  const { title, id } = section
  return {
    conversation: <Conversation key={id} {...section} />,
    pullquote: <PullQuote key={id} {...section} />,
    image: <Image key={id} {...section} />
  }[title]
}
const Hero = ({ content, title, image, slug }) => (
  <div className={cx("hero")}>
    <div className={cx("hero-image")}>
      <img alt={image.title} src={`${image.url}`} />
    </div>
    <SocialIcons />
    <h1>{title}</h1>
    <ReactMarkdown children={content} className={cx("content")} />
  </div>
)

const SocialIcons = () => {
  const location = window.location.href

  const socialLinks = [
    {
      title: "share with facebook",
      icon: Facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${location}`
    },
    {
      title: "share with twitter",
      icon: Twitter,
      url: `https://twitter.com/intent/tweet?url=${location}`
    }
  ]

  return (
    <div className={cx("social-icons-container")}>
      {socialLinks.map(({ url, icon, title }) => (
        <a href={url} key={title} target="_blank">
          <img src={icon} alt={title} />
        </a>
      ))}
    </div>
  )
}

const Image = ({ image, content }) => (
  <div className={cx("image-container")}>
    <div className={cx("image-wrapper")}>
      <div>
        <img alt={image.title} src={`${image.url}`} />
      </div>
      <ReactMarkdown children={content} className={cx("image-description")} />
    </div>
  </div>
)

const Conversation = ({ content }) => (
  <ReactMarkdown children={content} className={cx("conversation")} />
)

const PullQuote = ({ content }) => (
  <ReactMarkdown children={content} className={cx("pull-quote")} />
)

const Interviewer = ({ content, image }) => (
  <div className={cx("interviewer")}>
    <img alt={image.title} src={`${image.url}?w=400`} />
    <ReactMarkdown children={content} className={cx("interviewer-details")} />
  </div>
)

const PreviousInterviews = ({ previews }) => (
  <Fragment>
    {previews.length > 0 && (
      <div className={cx("previous")}>
        <Fragment>
          <h3>Past Dialogues</h3>
          <div>
            {previews.map(section => (
              <DialoguesSection key={section.id} {...section} />
            ))}
          </div>
          <div className={cx(["view-all", { white: previews.length % 2 }])}>
            <Link to={`/dialogues`}>View All Dialogues</Link>
          </div>
        </Fragment>
      </div>
    )}
  </Fragment>
)
