import React from "react"
import Input from "../Input"
import styles from "./entry-form"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const EntryForm = ({ fields }) => (
  <div className={cx("entry-form")}>
    {fields.map((field, i) => (
      <Input {...field} key={i} />
    ))}
  </div>
)

export default EntryForm
