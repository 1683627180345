import React, { Component } from "react"
import styles from "./tooltip"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.showTip = this.showTip.bind(this)
    this.hideTip = this.hideTip.bind(this)
  }

  showTip() {
    this.setState({
      isOpen: true
    })
  }

  hideTip() {
    this.setState({
      isOpen: false
    })
  }

  render() {
    const { copy, html } = this.props
    const { isOpen } = this.state

    return (
      <div className={cx("container")}>
        <button
          className={cx("button")}
          onMouseOver={this.showTip}
          onFocus={this.showTip}
          onMouseOut={this.hideTip}
          onBlur={this.hideTip}
        >
          ?
        </button>
        {html ? (
          <div
            className={cx(["overlay", isOpen ? "active" : ""])}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          <div className={cx(["overlay", isOpen ? "active" : ""])}>{copy}</div>
        )}
      </div>
    )
  }
}

export default Tooltip
