import React, { Component } from "react"
import { connect } from "react-redux"
import { editArtistRouteFn } from "../../../constants.js"
import Footer from "../../../components/Footer"
import { actions } from "../../../actions"

class FooterContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <Footer {...this.props} />
  }
}

const mapStateToProps = (state) => {
  const { session, router } = state
  const { userSlug } = session
  const isCms = router.location.pathname.includes(editArtistRouteFn(userSlug))

  return {
    isCms,
  }
}

const mapDispatchToProps = () => {}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer)
