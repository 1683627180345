import React from "react"
import { array, func } from "prop-types"
import classNames from "classnames/bind"
import styles from "./active-filter"

const cx = classNames.bind(styles)

// Note:
// Filters should be formatted { name: String, value: Any }

const ActiveFilters = ({ filters, onRemove }) => (
  <div className={cx("container")}>
    {filters.map((filter, i) => (
      <div className={cx("filter")} key={i}>
        <span>{filter.name || filter}</span>
        <button
          className={cx("remove-button")}
          name={`Remove ${filter.name}`}
          onClick={e => {
            e.preventDefault()
            onRemove(filter, i)
          }}
        ></button>
      </div>
    ))}
  </div>
)

ActiveFilters.propTypes = {
  filters: array.isRequired,
  onRemove: func
}

export default ActiveFilters
