import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useMutation, gql } from "@apollo/client"
import { actions } from "../../../actions"
import DocumentEditor from "../../../components/DocumentEditor"
import {UpdateUserCV} from "../../../queries/artists"

const CvForm = ({ doc, updateText, userId, cv, setAsSaving, saving, setAsNotSaving }) => {
  const [mutate] = useMutation(UpdateUserCV)

  useEffect(() => {
    if (doc) {
      updateText(doc)
    }
  }, [])

  const handleChange = (e) => {
    updateText(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setAsSaving()
    mutate({ variables: { id: userId, cv } }).then((res) => {
      setAsNotSaving(res)
    })
  }

  return <DocumentEditor handleSubmit={handleSubmit} handleChange={handleChange} saving={saving} text={cv} />
}

const mapStateToProps = ({ cms, session }) => {
  const { cv, saving } = cms
  const { userId } = session
  return { cv, userId, saving }
}

const mapDispatchToProps = (dispatch) => {
  const { updateCvForm, beginCmsMutation, endCmsMutation } = actions

  return {
    updateText: (text) => dispatch(updateCvForm(text)),
    setAsSaving: () => dispatch(beginCmsMutation()),
    setAsNotSaving: (res) => dispatch(endCmsMutation(res)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CvForm)
