import React from "react"
import { gql, useQuery } from "@apollo/client"
import { connect } from "react-redux"
import MembershipSettingsContainer from "../MembershipSettings"
import { actions } from "../../../actions"
import ReactivationPopup from "../../../nextgen/subscription/ReactivationPopup"

export const accountSettingsQuery = gql`
  query MembershipSettingsQuery($id: ID!) {
    user(id: $id) {
      id
      createdAt
      paypalId
      activePaymentMethod {
        last4
        cardType
        paypalId
        coupon {
          id
          percentOff
          durationInMonths
          duration
          expiration
        }
      }
    }
  }
`

const MembershipSettingsQueryContainer = ({ id, showReactivationModal, closeReactivationModal }) => {
  const response = useQuery(accountSettingsQuery, {
    variables: { id },
  })
  const { data } = response || {}
  const { user } = data || {}

  if (showReactivationModal) {
    return user ? <ReactivationPopup isOpen={showReactivationModal} onClose={closeReactivationModal} /> : null
  }
  return user ? <MembershipSettingsContainer user={user} /> : null
}

const mapStateToProps = ({ session }) => {
  const { userId, showReactivationModal } = session

  return { id: userId, showReactivationModal }
}

const mapDispatchToProps = (dispatch) => {
  const { closeReactivationModal } = actions
  return {
    closeReactivationModal: () => dispatch(closeReactivationModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipSettingsQueryContainer)
