import React, { Component } from "react"
import { array, number, bool } from "prop-types"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import styles from "./artist-results"
import classNames from "classnames/bind"
import SearchResults from "../../../components/SearchResults"
import Pagination from "../Pagination"
import { artistDetailRouteFn } from "../../../constants"
import { actions } from "../../../actions"

const cx = classNames.bind(styles)

class ArtistResults extends Component {
  constructor() {
    super()
    this.selectArtist = this.selectArtist.bind(this)
  }

  componentWillUnmount() {
    // Reset the paging when leaving
    this.props.goToPage(1)
  }

  selectArtist(index) {
    const { items } = this.props
    this.props.goToArtist(items[index].slug)
  }

  render() {
    const { items, currentPage, totalPages, loading } = this.props

    return (
      <div className={cx("container")}>
        {!loading && (
          <SearchResults
            kind="Artist"
            results={items}
            onTitleSelect={this.selectArtist}
            onImageSelect={this.selectArtist}
          />
        )}
        {!loading && items.length > 0 && (
          <Pagination
            kind="Artist"
            page={currentPage}
            totalPages={totalPages}
          />
        )}
        {!loading && items.length <= 0 && (
          <div className={cx("no-results")}>No results found</div>
        )}
      </div>
    )
  }
}

ArtistResults.propTypes = {
  items: array.isRequired,
  currentPage: number,
  totalPages: number,
  loading: bool.isRequired
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  const { selectSearchPage, toggleArtistDetailTab } = actions
  return {
    goToPage: page => {
      dispatch(selectSearchPage({ page }))
    },
    goToArtist: slug => {
      dispatch(toggleArtistDetailTab("works"))
      dispatch(push(artistDetailRouteFn(slug)))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistResults)
