import React from "react"
import styles from "../ContentCard/content-card"
import classNames from "classnames/bind"
import Video from "../Video"
import { Link } from "react-router-dom"
import { artistDetailRouteFn } from "../../constants"
import { displayDimensions, displayVideoDuration } from "../../utils"
import NukaCarousel from "nuka-carousel"
import Dots from "../Carousel/PagingDots"

const cx = classNames.bind(styles)

const ArtworkCard = ({
  content: artwork,
  onTitleSelect,
  onImageSelect,
  index
}) => {
  const {
    artist,
    dimensions,
    medium,
    videoLink,
    videoDurationMinutes,
    videoDurationSeconds,
    primaryImage,
    artworkImages,
    title,
    year
  } = artwork
  const { slug } = artist

  return (
    <div className={cx(["container", "artwork-card"])}>
      {!videoLink && artworkImages && artworkImages.length > 1 ? (
        <ImagesCarousel
          title={title}
          artworkImages={artworkImages}
          index={index}
          onImageSelect={onImageSelect}
        />
      ) : (
        <button
          className={cx("image-wrapper")}
          onClick={e => {
            e.preventDefault()
            onImageSelect(index)
          }}
        >
          {!videoLink &&
            primaryImage &&
            (!artworkImages || artworkImages.length <= 1) && (
              <img
                alt={title || "Untitled Artwork"}
                src={primaryImage.medium}
              />
            )}
          {videoLink && (
            <div className={cx("video")}>
              <Video
                url={videoLink}
                width={"100%"}
                height={240}
                controls={true}
              />
            </div>
          )}
        </button>
      )}

      <div className={cx(["content", "artwork-content"])}>
        <h4 className={cx("artist-name")}>
          {slug ? (
            <Link to={artistDetailRouteFn(slug)} name={artist.name}>
              {artist.name ? artist.name : "Unknown"}
            </Link>
          ) : (
            <span>{artist.name ? artist.name : "Unknown"}</span>
          )}
        </h4>
        <div className={cx("extra-info")}>
          <div className={cx("title-wrapper")}>
            <span className={cx("title")}>{`${title || "Untitled"}`}</span>,{" "}
            {year}{" "}
          </div>
          {medium && <div className={cx("medium")}>{medium}</div>}
          {dimensions && (
            <div className={cx("dimensions")}>
              {displayDimensions(dimensions)}
            </div>
          )}
          {videoDurationMinutes && videoDurationSeconds && (
            <div className={cx("dimensions")}>
              {displayVideoDuration(videoDurationMinutes, videoDurationSeconds)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const ImagesCarousel = ({ artworkImages, title, onImageSelect, index }) => (
  <div className={cx(["container", "artwork-card"])}>
    <div className={cx("image-wrapper")}>
      <NukaCarousel
        easing="easeCubic"
        edgeEasing="easeCubic"
        autoplay={false}
        autoplayInterval={5000}
        wrapAround={true}
        renderBottomCenterControls={props => <Dots {...props} /> }
        renderCenterLeftControls={({ previousSlide }) => (
          <button onClick={previousSlide}>&lsaquo;</button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide}>&rsaquo;</button>
        )}
      >
        {artworkImages.map(({ image }, i) => (
          <div>
            <button
              className={cx("image-wrapper")}
              onClick={e => {
                e.preventDefault()
                onImageSelect(index)
              }}
            >
              <img className={cx("on-a-slider")} alt={title || "Untitled Artwork"} src={image.medium} />
            </button>
          </div>
        ))}
      </NukaCarousel>
    </div>
  </div>
)

export default ArtworkCard
