import {createContext, useContext} from "react"

const defaultValue = {
  loggedInUser: null,
  setLoggedInUser: () => {},
}

const LoggedInUserContext = createContext(defaultValue)

const useLoggedInUser = () => useContext(LoggedInUserContext)

export { useLoggedInUser, LoggedInUserContext }
