import { types } from "../actions"

const emptyState = {
  content: "",
  id: "",
  image: {},
  sections: [],
  slug: "",
  title: ""
}

const section = (state = emptyState, { type, payload }) => {
  switch (type) {
    case types.GET_SECTION_SUCCESS: {
      return {
        ...state,
        ...payload.section
      }
    }
    case types.CLEAR_SECTION: {
      return emptyState
    }
    default: {
      return state
    }
  }
}

export default section
