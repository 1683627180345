import React from "react"
import { useQuery } from "@apollo/client"
import { connect } from "react-redux"
import SortingOptions from "../../../containers/explore/SortingOptions"
import ArtistResults from "../../../containers/explore/ArtistResults"
import LoadingSpinner from "../../../components/LoadingSpinner"
import PageTitleHeader from "../../../components/PageTitleHeader"
import classNames from "classnames/bind"
import styles from "../../../pages/explore/ArtistSearch/artist-search"
import { SavedArtistQuery } from "../../../queries/artists.js"

const cx = classNames.bind(styles)

const sortingOptions = [
  {
    name: "Recently Saved",
    value: "-created_at"
  },
  {
    name: "Recently Updated",
    value: "-updated_at"
  }
]

const SavedArtists = ({ id, order, page }) => {
  const response = useQuery(SavedArtistQuery, {
    variables: {
      params: {
        order,
        page
      },
      id
    },
    fetchPolicy: "network-only"
  })

  const { data, loading } = response || {}
  const { savedArtists } = data || {}

  return (
    <div className={cx("container")}>
      <PageTitleHeader title={"Saved Artists"} />
      <SortingOptions
        label={"Sort by"}
        name={"saved_artist_sort"}
        format={"savedArtists"}
        options={sortingOptions}
      />
      {loading && <LoadingSpinner />}
      <ArtistResults
        items={savedArtists ? savedArtists.data.map(el => el.artist) : []}
        loading={loading}
        currentPage={savedArtists ? savedArtists.currentPage : null}
        totalPages={savedArtists ? savedArtists.totalPages : null}
      />
    </div>
  )
}

const mapStateToProps = ({ session, search }) => {
  const { userId } = session
  const { order, page } = search
  return {
    order: order.savedArtists,
    page,
    id: userId
  }
}
export default connect(mapStateToProps)(SavedArtists)
