import React from "react"
import ProfileImageWrapper from "../ProfileImageWrapper"
import ProfileFormWrapper from "../ProfileFormWrapper"
import SchoolFormWrapper from "../SchoolFormWrapper"
import { useQuery, gql } from "@apollo/client"
import { connect } from "react-redux"
import styles from "./profile-wrapper"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const profileQuery = gql`
  query ProfileWrapperQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      livesAndWorks
      contactEmail
      genderPronoun
      formats {
        id
        name
      }
      categories {
        id
        name
      }
      birthYear
      hometown
      website
      profileImage {
        thumb
      }
      graduations {
        id
        year
        degreeType
        school {
          id
          name
          country {
            short
          }
        }
      }
    }
  }
`

const ProfileWrapper = ({ id }) => {
  const response = useQuery(profileQuery, {
    variables: { id }
  })

  const { data } = response || {}
  const { user } = data || {}
  return (
    <React.Fragment>
      {user ? (
        <div className={cx("container")}>
          <div className={cx("image-container")}>
            <ProfileImageWrapper {...user} />
          </div>
          <div className={cx("form-container")}>
            <ProfileFormWrapper user={user} />
            <SchoolFormWrapper user={user} />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = ({ session }) => {
  const { userId } = session

  return { id: userId }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper)
