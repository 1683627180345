import { types } from "../actions"

const profile = (
  state = {
    firstName: "",
    lastName: "",
    livesAndWorks: {
      value: "",
      valid: false
    },
    contactEmail: "",
    genderPronoun: "",
    formats: [],
    categories: [],
    birthYear: "",
    hometown: {
      value: "",
      valid: false
    },
    hometownValid: false,
    nationality: "",
    website: ""
  },
  { type, payload }
) => {
  switch (type) {
    case types.UPDATE_PROFILE_INPUT:
      return {
        ...state,
        ...payload
      }
    case types.UPDATE_PROFILE_LOCATION:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          ...payload.data
        }
      }
    case types.ADD_USER_RELATION:
      return {
        ...state,
        [payload.type]: [...new Set([...state[payload.type], payload.id])]
      }
    case types.REMOVE_USER_RELATION:
      return {
        ...state,
        [payload.type]: [
          ...state[payload.type].slice(0, payload.index),
          ...state[payload.type].slice(payload.index + 1)
        ]
      }
    default:
      return state
  }
}

export default profile
