import React from "react"
import styles from "./loading-spinner"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const LoadingSpinner = () => <div className={cx("spinner")} />

LoadingSpinner.propTypes = {}

export default LoadingSpinner
