import { useState } from "react"
import Cookies from "js-cookie"

const useCookieNotice = () => {
  const [cookieNoticeIsHidden, hideCookieNotice] = useState(
    Cookies.get("foundwork_cookie_policy_accepted") || false
  )

  const acceptCookieNotice = () => {
    Cookies.set("foundwork_cookie_policy_accepted", String(true), { expires: 3650 })
    hideCookieNotice(true)
  }

  return [!cookieNoticeIsHidden, acceptCookieNotice]
}

export default useCookieNotice
