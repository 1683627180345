import React from "react"
import StaticHero from "../StaticHero"
import DialoguesSection from "../DialoguesSection"
import Helmet from "../Helmet"
import LoadingSpinner from "../LoadingSpinner"

export default ({ content, sections, title }) => {
  return (
    <div>
      <Helmet title={`Foundwork | Dialogues`} />
      {title ? (
        <div>
          <StaticHero title={title} content={content} />
          {sections.map(section => (
            <DialoguesSection key={section.slug} {...section} />
          ))}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}
