import styled, { keyframes } from "styled-components"

const spin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`

const LoadingSpinner = styled.div`
  animation: ${spin} 1s infinite linear;
  border: 0.125em solid rgba(74, 144, 226, 0.2);
  border-radius: 50%;
  border-top-color: rgb(74, 144, 226);
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  background-color: transparent;
`

export default LoadingSpinner
