import React, { Component } from "react"
import Header from "../../../containers/cms/Header"
import StatementWrapper from "../../../containers/cms/StatementWrapper"
import CmsBackground from "../../../components/CmsBackground"

class Statement extends Component {
  render() {
    return (
      <div>
        <Header title="Artist Statement" />
        <CmsBackground>
          <StatementWrapper />
        </CmsBackground>
      </div>
    )
  }
}

export default Statement
