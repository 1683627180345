import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import React from "react"

type ButtonProps = {
  variant?: ButtonVariant
} & Partial<BaseButtonProps> &
  React.ComponentProps<typeof BaseButton>

interface BaseButtonProps {
  $size: ButtonSize
}

export enum ButtonVariant {
  FILLED,
  LIGHT,
}

export enum ButtonSize {
  SMALL,
  DEFAULT,
}

const BaseButton = styled.button<BaseButtonProps>`
  ${globalStylesCss};

  font-size: ${({ $size }) =>
    $size === ButtonSize.DEFAULT ? "var(--font-size-primary)" : "var(--font-size-small)"};

  text-align: center;
  text-decoration: none;
  line-height: normal;
  height: auto;
  padding: 1em 3em;
  min-width: min-content;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const FilledButton = styled(BaseButton)`
  color: var(--font-color-inverted-primary);
  background-color: var(--color-blue);
  border-radius: 2px;
  border: none;
  letter-spacing: var(--letter-spacing-extra-large);
  transition: opacity 0.2s ease;

  &[disabled] {
    background-color: var(--color-gray-17);
  }

  &:hover,
  &:active {
    opacity: 0.6;
  }
`

const LightButton = styled(BaseButton)`
  color: var(--font-color-secondary);
  appearance: none;
  background: transparent;
  border-color: transparent;

  &:hover {
    color: var(--color-blue);
  }
`

const Button = ({ variant = ButtonVariant.FILLED, ...buttonArgs }: ButtonProps) => {
  const variantsMap = {
    [ButtonVariant.FILLED]: FilledButton,
    [ButtonVariant.LIGHT]: LightButton,
  }

  return React.createElement(variantsMap[variant], { $size: ButtonSize.DEFAULT, ...buttonArgs })
}

export default Button
