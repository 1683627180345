import { types } from "../actions"

const search = (
  state = {
    formats: [],
    categories: [],
    programYears: [],
    degreeTypes: [],
    mfaPrograms: [],
    organizations: [],
    location: {
      place: {
        value: "",
        valid: false
      },
      lat: null,
      lon: null,
      distance: 10,
      unit: "MI"
    },
    order: {
      artists: "-featured,-updated_at",
      artwork: "-featured,-updated_at",
      savedArtists: "-created_at"
    },
    page: 1,
    didInvalidate: true
  },
  { type, payload }
) => {
  switch (type) {
    case types.RESET_SEARCH_FILTERS:
      return {
        ...state,
        formats: [],
        categories: [],
        programYears: [],
        mfaPrograms: [],
        organizations: [],
        location: {
          place: {
            value: "",
            valid: false
          },
          lat: null,
          lon: null,
          distance: 10,
          unit: "MI"
        },
        order: {
          artists: "-featured,-updated_at",
          artwork: "-featured,-updated_at",
          savedArtists: "-created_at"
        },
        page: 1
      }
    case types.UPDATE_SEARCH_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          [payload.format]: payload.order
        },
        page: 1,
        didInvalidate: true
      }
    case types.ADD_SEARCH_FORMAT:
      return {
        ...state,
        formats: [...state.formats, payload.id],
        page: 1,
        didInvalidate: true
      }
    case types.REMOVE_SEARCH_FORMAT:
      return {
        ...state,
        formats: [
          ...state.formats.slice(0, state.formats.indexOf(payload.id)),
          ...state.formats.slice(state.formats.indexOf(payload.id) + 1)
        ],
        page: 1,
        didInvalidate: true
      }
    case types.RESET_SEARCH_FORMATS:
      return {
        ...state,
        formats: [],
        page: 1,
        didInvalidate: true
      }
    case types.ADD_SEARCH_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload.id],
        page: 1,
        didInvalidate: true
      }
    case types.REMOVE_SEARCH_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.slice(0, state.categories.indexOf(payload.id)),
          ...state.categories.slice(state.categories.indexOf(payload.id) + 1)
        ],
        page: 1,
        didInvalidate: true
      }
    case types.UPDATE_LOCATION_SEARCH:
      return {
        ...state,
        location: {
          ...state.location,
          ...payload
        },
        page: 1,
        didInvalidate: true
      }
    case types.RESET_LOCATION_SEARCH:
      return {
        ...state,
        location: {
          place: {
            value: "",
            valid: false
          },
          lat: null,
          lon: null,
          distance: 10,
          unit: "MI"
        },
        page: 1,
        didInvalidate: true
      }
    case types.RESET_SEARCH_CATEGORIES:
      return {
        ...state,
        categories: [],
        page: 1,
        didInvalidate: true
      }
    case types.SELECT_SEARCH_PROGRAM_YEAR:
      return {
        ...state,
        programYears: [payload.year, ...state.programYears.slice(1)],
        page: 1,
        didInvalidate: true
      }
    case types.SELECT_SEARCH_PROGRAM_YEAR_RANGE:
      return {
        ...state,
        programYears: [...state.programYears.slice(0, 1), payload.year],
        page: 1,
        didInvalidate: true
      }
    case types.REMOVE_PROGRAM_YEAR:
      return {
        ...state,
        programYears: [
          ...state.programYears.slice(0, payload.index),
          ...state.categories.slice(payload.index + 1)
        ],
        page: 1,
        didInvalidate: true
      }
    case types.ADD_SEARCH_DEGREE_TYPE:
      return {
        ...state,
        degreeTypes: [...state.degreeTypes, payload],
        page: 1,
        didInvalidate: true
      }
    case types.REMOVE_SEARCH_DEGREE_TYPE:
      return {
        ...state,
        degreeTypes: [
          ...state.degreeTypes.slice(0, state.degreeTypes.indexOf(payload)),
          ...state.degreeTypes.slice(state.degreeTypes.indexOf(payload) + 1)
        ],
        page: 1,
        didInvalidate: true
      }
    case types.ADD_SEARCH_ORGANIZATION:
      return {
        ...state,
        organizations: [...state.organizations, payload.organization],
        page: 1,
        didInvalidate: true
      }
    case types.REMOVE_SEARCH_ORGANIZATION:
      return {
        ...state,
        organizations: [
          ...state.organizations.slice(
            0,
            state.mfaPrograms.indexOf(payload.organization)
          ),
          ...state.organizations.slice(
            state.organizations.indexOf(payload.organization) + 1
          )
        ]
      }
    case types.ADD_SEARCH_MFA_PROGRAM:
      return {
        ...state,
        mfaPrograms: [...state.mfaPrograms, payload.program],
        page: 1,
        didInvalidate: true
      }
    case types.REMOVE_SEARCH_MFA_PROGRAM:
      return {
        ...state,
        mfaPrograms: [
          ...state.mfaPrograms.slice(
            0,
            state.mfaPrograms.indexOf(payload.program)
          ),
          ...state.mfaPrograms.slice(
            state.mfaPrograms.indexOf(payload.program) + 1
          )
        ],
        page: 1,
        didInvalidate: true
      }
    case types.RESET_SEARCH_ORG_OR_SCHOOL:
      return {
        ...state,
        programYears: [],
        mfaPrograms: [],
        organizations: [],
        degreeTypes: [],
        page: 1,
        didInvalidate: true
      }
    case types.RESET_SEARCH_ARTIST_DETAILS:
      return {
        ...state,
        nationalities: [],
        page: 1,
        didInvalidate: true
      }
    case types.SELECT_SEARCH_PAGE:
      return {
        ...state,
        page: payload.page,
        didInvalidate: true
      }
    case types.VALIDATE_SEARCH:
      return {
        ...state,
        didInvalidate: false
      }
    default:
      return state
  }
}

export default search
