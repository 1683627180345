import React from "react"
import classNames from "classnames/bind"
import styles from "./location-filter"
import Dropdown from "../Dropdown"
import LocationAutofillContainer from "../../containers/explore/LocationAutofillContainer"

const cx = classNames.bind(styles)

const distances = [10, 20, 50, 100, 250, 500, 1000, 2000].map(n => {
  return {
    name: `${n}`,
    val: `${n}`
  }
})

const units = [
  {
    name: "miles",
    value: "MI"
  },
  {
    name: "km",
    value: "KM"
  }
]

const LocationFilter = ({ updateLocationSearch, place, distance, unit }) => (
  <div className={cx("location-filter")}>
    <div className={cx("mobile-label")}>Artist Location</div>
    <div className={cx("location-filter-top")}>
      Within
      <div className={cx("location-dropdown")}>
        <Dropdown
          name="distance"
          onChange={selection =>
            updateLocationSearch({ distance: Number(selection) })
          }
          value={distance}
          options={distances}
        />
      </div>
      <div className={cx("location-dropdown")}>
        <Dropdown
          name="unit"
          onChange={selection => updateLocationSearch({ unit: selection })}
          value={unit}
          options={units}
        />
      </div>
    </div>
    <div className={cx("location-filter-bottom")}>
      <LocationAutofillContainer label={"of"} className={"location"} />
    </div>
  </div>
)

export default LocationFilter
