import React from "react"
import styles from "./contact-form"
import classNames from "classnames/bind"
import Input from "../Input"
import Textarea from "../Textarea"

const cx = classNames.bind(styles)

const ContactForm = (
  {
    toggleOpen,
    handleChange,
    handleSubmit,
    inProgress,
    error,
    subject,
    sent,
    message,
    asGuest,
    name,
    email,
  }
) => (
  <div className={cx("overlay")}>
    <div className={cx("contact-form")}>
      <h2>Contact Artist</h2>
      <p className={cx("error")}>{error}</p>
      <button className={cx("close-button")} onClick={toggleOpen}>
        &times;
      </button>
      <div className={cx("inputs")}>
        {asGuest && (
          <>
            <Input
              id="name"
              name="name"
              type="text"
              value={name}
              handleChange={handleChange}
              placeholder="Please include your first and last name"
              label="Your Name"
              required={true}
            />
            <Input
              id="email"
              name="email"
              type="text"
              value={email}
              handleChange={handleChange}
              placeholder=""
              label="Your Email Address"
              required={true}
            />
          </>
        )}
        <Input
          id="subject"
          name="subject"
          type="text"
          value={subject}
          handleChange={handleChange}
          placeholder="Hello, from Foundwork"
          label="Subject"
          required={true}
        />
        <Textarea
          rows={8}
          id="message"
          name="message"
          value={message}
          handleChange={handleChange}
          placeholder="Hello, from Foundwork"
          label="Message"
          required={true}
        />
      </div>
      <div className={cx("bottom")}>
        <div className={cx("message")}>
          Messages are sent by email. The artist will receive your name and
          email address.
        </div>
        <button
          className={cx(["btn", "btn-blue", "btn-submit"])}
          onClick={handleSubmit}
          disabled={inProgress}
        >
          {sent ? "Message Sent" : "Send Message"}
        </button>
      </div>
    </div>
  </div>
)

export default ContactForm
