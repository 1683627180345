import React, { ReactNode } from "react"
import { Label, LabelSize } from "./Label"
import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

export interface FormFieldBaseProps {
  name: string,
  label: string,
  labelSize?: LabelSize,
  error?: string,
  children: ReactNode,
}

const Container = styled.div`
  ${globalStylesCss};

  flex: 1 1 100%;
  text-align: left;
  align-items: center;
`

const ErrorMessage = styled.div`
  ${globalStylesCss};

  font-size: var(--font-size-small);
  color: var(--color-red);
  letter-spacing: normal; // TODO Investigate if from a design perspective it's a good choice to have 3 different letter spacings in one component
`

const FormFieldBase = (
  {
    name,
    label,
    labelSize = LabelSize.SMALL,
    error,
    children,
  }: FormFieldBaseProps,
) => (
  <Container>
    <Label htmlFor={name} size={labelSize}>{label}</Label>
    {children}
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Container>
)

export default FormFieldBase
