import React from "react"
import FormField, { FormFieldProps } from "@/ui/forms/FormField"
import { CardElement, CardElementProps } from "@stripe/react-stripe-js"
import { ErrorBoundary } from "react-error-boundary"
import { LabelSize } from "@/ui/forms/Label"
import FormFieldBase from "@/ui/forms/FormFieldBase"
import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import { programmaticallyAccessibleVariables } from "@/ui/styles/Variables"

type CreditCardInputProps = Pick<FormFieldProps, "labelSize" | "error"> & CardElementProps

const Container = styled.div`
  ${globalStylesCss};

  display: flex;
  flex-direction: column;
  gap: var(--padding-primary);
`

// TODO Refine fallback component
const GracefulCardElement = (props: CardElementProps) => (
  <ErrorBoundary fallback={<div>ERROR</div>}>
    <CardElement {...props} />
  </ErrorBoundary>
)

// NOTE The styles of this component should be kept in sync with those of Input
const StyledGracefulCardElement = styled(GracefulCardElement)`
  ${globalStylesCss};

  background-color: var(--background-color-primary);
  border: 1px solid var(--color-gray-19);
  border-radius: 2px;
  height: var(--input-height-primary);
  padding: calc((var(--input-height-primary) - var(--line-height-default) * var(--font-size-primary)) / 2)
    var(--padding-primary); // 7px = height - programmaticallyAccessibleVariables.lineHeightDefault / 2 because we need to center the input (in the iframe) inside the container
  width: 100%;

  &.StripeElement--focus {
    outline: none;

    border-color: var(--color-blue);
  }
`

const CreditCardInput = ({
  labelSize = LabelSize.SMALL,
  error,
  ...cardElementProps
}: CreditCardInputProps) => (
  <Container>
    <FormFieldBase
      name="creditCard"
      label="Card Number / Exp. Date / CVC"
      labelSize={labelSize}
      error={error}
    >
      <StyledGracefulCardElement
        {...cardElementProps}
        options={{
          hidePostalCode: true,
          hideIcon: true,
          style: {
            base: {
              fontFamily: programmaticallyAccessibleVariables.fontFamilyPrimary,
              fontWeight: "400",
              color: programmaticallyAccessibleVariables.fontColorPrimary,
              backgroundColor: programmaticallyAccessibleVariables.backgroundColorPrimary,
              letterSpacing: programmaticallyAccessibleVariables.letterSpacingDefault,
              fontSize: programmaticallyAccessibleVariables.fontSizePrimary,
              lineHeight: programmaticallyAccessibleVariables.lineHeightDefault,
              fontSmoothing: "antialiased",
              "::placeholder": {
                color: programmaticallyAccessibleVariables.colorGray19,
              },
            },
          },
        }}
      />
    </FormFieldBase>
  </Container>
)

export default CreditCardInput
