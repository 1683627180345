import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import styles from "./artwork-results"
import classNames from "classnames/bind"
import { array, func, number, bool } from "prop-types"
import SearchResults from "../../../components/SearchResults"
import Pagination from "../Pagination"
import { artistDetailRouteFn } from "../../../constants"
import { actions } from "../../../actions"

const cx = classNames.bind(styles)

class ArtworkResults extends Component {
  constructor() {
    super()
    this.selectArtist = this.selectArtist.bind(this)
  }

  componentWillUnmount() {
    this.props.goToPage(1)
  }

  selectArtist(index) {
    const { artworks } = this.props
    this.props.goToArtist(artworks[index].artist.slug)
  }

  render() {
    const {
      artworks,
      currentPage,
      totalPages,
      selectArtwork,
      loading
    } = this.props

    return (
      <div className={cx("container")}>
        {!loading && (
          <SearchResults
            kind="Artwork"
            results={artworks}
            onTitleSelect={this.selectArtist}
            onImageSelect={selectArtwork}
          />
        )}
        {!loading && artworks.length > 0 && (
          <Pagination
            kind="Artwork"
            page={currentPage}
            totalPages={totalPages}
          />
        )}
        {!loading && artworks.length <= 0 && (
          <div className={cx("no-results")}>No results found</div>
        )}
      </div>
    )
  }
}

ArtworkResults.propTypes = {
  artworks: array,
  currentPage: number,
  totalPages: number,
  selectArtwork: func.isRequired,
  loading: bool.isRequired
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  const { openArtworkPreview, toggleArtistDetailTab } = actions

  return {
    goToPage: page => {
      // Change 'page' in search reducer
      dispatch(actions.selectSearchPage({ page }))
    },
    selectArtwork: index => {
      const element = document.getElementsByTagName("html")[0]
      element.classList.add("locked")
      dispatch(openArtworkPreview({ index }))
    },
    goToArtist: slug => {
      if (slug) {
        dispatch(toggleArtistDetailTab("works"))
        dispatch(push(artistDetailRouteFn(slug)))
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkResults)
