import React, { Component } from "react"
import { connect } from "react-redux"
import { getSection } from "../../services/section"
import { actions } from "../../actions.js"

import About from "../../components/About"
import Guests from "../../components/Guests"
import Faq from "../../components/Faq"
import Terms from "../../components/Terms"
import PrizeRules from "../../components/PrizeRules"
import Prize from "../../components/Prize"
import Dialogues from "../../components/Dialogues"
import Privacy from "../../components/Privacy"
import Home from "../../components/Home"

class StaticPageContainer extends Component {
  constructor(props) {
    super()

    const pages = {
      about: About,
      faq: Faq,
      terms: Terms,
      "prize-rules": PrizeRules,
      "artist-prize-2020": Prize,
      "foundwork-dialogues": Dialogues,
      privacy: Privacy,
      homeLoggedOut: Home,
      "guest-selections": Guests
    }

    this.Handler = pages[props.slug]
  }

  componentDidMount() {
    this.props.clearSection()
    this.props.getSection(this.props.slug)
  }

  componentWillUnmount() {
    this.props.clearSection()
  }

  render() {
    const { Handler } = this
    return <Handler {...this.props.section} />
  }
}

const mapStateToProps = state => {
  const { section } = state
  return { section }
}

const mapDispatchToProps = dispatch => {
  const { getSectionSuccess, clearSection } = actions

  return {
    getSection: slug => {
      getSection(slug, ({ err, section }) => {
        if (!err && section) {
          dispatch(getSectionSuccess({ section }))
        } else {
          console.error(err)
        }
      })
    },
    clearSection: () => dispatch(clearSection())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPageContainer)
