import React, { Component } from "react"
import { connect } from "react-redux"
import InputAddress from "../../../components/InputAddress"
import { actions } from "../../../actions"

class InputAddressContainer extends Component {
  handleBlur() {
    if (!this.props.valid) {
      this.props.clearValue()
    }
  }

  render() {
    return <InputAddress {...this.props} handleBlur={this.handleBlur.bind(this)} />
  }
}
const mapStateToProps = ({ profile }, ownProps) => {
  const obj = profile[ownProps.type]

  return obj
}

const cleanLocation = (location) => {
  return location.includes("United States") ? location.split(", ").slice(0, -1).join(", ") : location
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { updateProfileLocation } = actions

  return {
    handleChange: (location) => {
      location = cleanLocation(location)

      dispatch(
        updateProfileLocation({
          type: ownProps.type,
          data: { value: location },
        })
      )
    },
    handleSelect: (location, placeId) => {
      location = cleanLocation(location)

      dispatch(
        updateProfileLocation({
          type: ownProps.type,
          data: {
            value: location,
            valid: !!placeId,
          },
        })
      )
    },
    clearValue: () => {
      dispatch(
        updateProfileLocation({
          type: [ownProps.type],
          data: { value: "" },
        })
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputAddressContainer)
