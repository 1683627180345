import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Home from "../../../components/Home"
import StaticPageContainer from "../../../containers/misc/StaticPageContainer"

const HomeWrapper = ({ loggedIn }) => {
  const [section, setSection] = useState({
    sections: [],
    content: "",
  })

  useEffect(() => {
    if (window !== "undefined" && window.section) {
      setSection(window.section)
    }

    return () =>
      setSection({
        sections: [],
        content: "",
      })
  }, [])

  return window.section ? (
    <Home loggedIn={loggedIn} {...section} />
  ) : (
    <StaticPageContainer slug="homeLoggedOut" />
  )
}

const mapStateToProps = ({ session }) => {
  const { loggedIn } = session
  return { loggedIn }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeWrapper)
