import React, { Component } from "react"
import { connect } from "react-redux"
import InputAddress from "../../../components/InputAddress"
import { actions } from "../../../actions"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"

class LocationAutofillContainer extends Component {
  handleBlur() {
    if (!this.props.valid) {
      this.props.clearValue()
    }
  }

  render() {
    return (
      <InputAddress {...this.props} handleBlur={this.handleBlur.bind(this)} />
    )
  }
}
const mapStateToProps = ({ search }) => {
  const { location } = search
  const { place } = location

  return place
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { updateLocationSearch } = actions

  return {
    handleChange: location => {
      dispatch(
        updateLocationSearch({
          place: { value: location }
        })
      )
    },
    handleSelect: (location, placeId) => {
      if (!!placeId) {
        geocodeByAddress(location)
          .then(results => getLatLng(results[0]))
          .then(coordinates => {
            dispatch(
              updateLocationSearch({
                place: {
                  value: location,
                  valid: !!placeId
                },
                lat: coordinates.lat,
                lon: coordinates.lng
              })
            )
          })
      }
    },
    clearValue: () => {
      dispatch(
        updateLocationSearch({
          place: { value: "" }
        })
      )
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationAutofillContainer)
