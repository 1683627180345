import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./prize-announcement"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

export default ({ content, title }) => (
  <div className={cx("prize-announcement-wrapper")}>
    <div className={cx("prize-announcement")}>
      <h3>{title}</h3>
      <ReactMarkdown
        className={cx("prize-announcement--copy")}
        children={content}
      />
    </div>
  </div>
)
