import React from "react"
import { string } from "prop-types"
import { useQuery, gql } from "@apollo/client"
import { connect } from "react-redux"
import Carousel from "../../components/Carousel"
import ArtworkCarouselSlide from "../../components/ArtworkCarouselSlide"
import devImages from "../../components/Carousel/sample.data.json"

const toDimensionString = dimensions => {
  if (!dimensions || !dimensions.length || (dimensions.length === 1 && dimensions[0] === 0)) {
    return null
  }

  return `${dimensions.join(" x ")}  in.`
}

const ConnectedCarousel = ({ name }) => {

  const carouselQuery = gql`
    query Carousel($name: String!) {
      carousel(name: $name) {
        artworks {
          artist {
            name
            slug
          }
          title
          dimensions
          medium
          year
          primaryImage {
            large
          }
        }
      }
    }
    `

  const response = useQuery(carouselQuery, {
    variables: {
      name
    }
  })
  const { data } = response || {}
  const { carousel } = data || {}
  const { loading, artworks } = carousel || {}

  if (loading) {
    return <div />
  }

  const images = artworks
    ? artworks.map(
        ({ artist, primaryImage, title, dimensions, medium, year }) => ({
          artist: artist.name,
          src: primaryImage.large,
          link: artist.slug,
          title,
          dimensions: toDimensionString(dimensions),
          medium,
          year
        })
      )
    : devImages

  return (
    <Carousel>
      {images.map((image, i) => (
        <ArtworkCarouselSlide key={`c=${i}`} {...image} />
      ))}
    </Carousel>
  )
}

ConnectedCarousel.propTypes = {
  name: string.isRequired
}

export default ConnectedCarousel
