import React, { Component } from "react"
import ReactMarkdown from "react-markdown"
import classNames from "classnames/bind"
import { OpenIcon, CloseIcon } from "../GuestCard"
import styles from "../GuestCard/guest-card"

const cx = classNames.bind(styles)

export default class JuryCard extends Component {
  constructor(props) {
    super(props)
    // On initialization for mobile, the first GuestCard renders in expanded state and the rest are collapsed
    this.state = {
      isExpand: false
    }
  }

  toggleIsExpand = () => {
    this.setState(() => ({ isExpand: !this.state.isExpand }))
  }

  render() {
    const { isMobile, image, title, content } = this.props
    const { isExpand } = this.state
    return (
      <div
        className={cx({
          "display-card": true,
          "is-mobile": isMobile,
          "is-expand": isMobile && isExpand
        })}
      >
        <div className={cx("artist-portrait")}>
          <img src={`${image.url}?w=400`} alt="artist portrait" />
        </div>
        <h2 className={cx("guest-name")}>{title}</h2>
        {isMobile && !isExpand && (
          <span className={cx("description")}>{image.description}</span>
        )}
        {(!isMobile || isExpand) && <ReactMarkdown children={content} />}
        {isMobile && (
          <button
            className={cx("toggle-is-expand", { "is-expand": !isExpand })}
            onClick={this.toggleIsExpand}
          >
            {isExpand ? <CloseIcon /> : <OpenIcon />}
          </button>
        )}
      </div>
    )
  }
}
