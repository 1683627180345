import React, { ReactNode } from "react"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import config from "@/config"

const PaypalSubscriptionConnector = ({ children }: { children: ReactNode }) => (
  <PayPalScriptProvider
    options={{
      clientId: config.paypal.clientId,
      vault: true,
      intent: "subscription",
    }}
  >
    {children}
  </PayPalScriptProvider>
)

export default PaypalSubscriptionConnector
