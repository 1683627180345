// Routes

export const homeRoute = "/"
export const loginRoute = "/login"
export const signupRoute = "/signup"
export const subscriptionRoute = "/artists/new/subscribe"
export const welcomeRoute = "/artists/welcome"
export const artistSearchRoute = "/artists"
export const artistDetailRoute = "/artists/:slug"
export const settingsRoute = "/settings"
export const artworkSearchRoute = "/artwork"
export const guestCuratorsRoute = "/guest-curators"
export const guestSelectionsDetailRoute = "/guest-curators/:slug"
export const savedArtistsRoute = "/artists/saved"
export const faqRoute = "/faq"
export const aboutRoute = "/about"
export const termsRoute = "/terms"
export const privacyRoute = "/privacy"
export const prizeRulesRoute = "/prize-rules"
export const prizeRoute = "/artist-prize"
export const dialoguesRoute = "/dialogues"
export const dialoguesDetailRoute = "/dialogues/:slug"
export const forgotPasswordRoute = "/forgot_password"
export const resetPasswordRoute = "/reset_password/:token"
export const donateRoute = "/donate"

// Route Functions
export const artistDetailRouteFn = (slug) => `/artists/${slug}`
export const editArtistRouteFn = (slug) => `/artists/edit/${slug}`
export const editArtistProfileRouteFn = (slug) => `/artists/edit/${slug}/profile`
export const editArtistCvRouteFn = (slug) => `/artists/edit/${slug}/cv`
export const editArtistStatementRouteFn = (slug) => `/artists/edit/${slug}/statement`
export const editArtistWorksRouteFn = (slug) => `/artists/edit/${slug}/works`
export const guestSelectionsDetailRouteFn = (slug) => `/guest-curators/${slug}`
export const DialoguesDetailRouteFn = (slug) => `/dialogues/${slug}`

export const loginFields = [
  {
    name: "login",
    label: "Email Address",
    placeholder: "Email Address",
    type: "email",
    required: true,
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
    required: true,
  },
]

export const signupFields = [
  {
    name: "firstName",
    label: "First Name",
    placeholder: "First Name",
    type: "text",
    required: true,
    className: "narrow",
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Last Name",
    type: "text",
    required: true,
    className: "narrow",
  },
  {
    name: "email",
    label: "Email Address",
    placeholder: "Email Address",
    type: "email",
    required: true,
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Password (at least 8 characters)",
    type: "password",
    required: true,
  },
]

export const forgotPasswordFields = [
  {
    name: "email",
    label: "Email Address",
    placeholder: "Email Address",
    type: "email",
    required: true,
  },
]

export const resetPasswordFields = [
  {
    name: "password",
    label: "New Password  (at least 8 characters)",
    placeholder: "New Password  (at least 8 characters)",
    type: "password",
    required: true,
  },
  {
    name: "confirmPassword",
    label: "Confirm New Password",
    placeholder: "Confirm New Password",
    type: "password",
    required: true,
  },
]
