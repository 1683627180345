import React from "react"
import { Helmet } from "react-helmet"

interface PageMetadataProps {
  title: string,
  description: string,
}

const PageMetadata = ({ title, description }: PageMetadataProps) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
)

export default PageMetadata
