import React from "react"
import { string, bool, oneOfType, number } from "prop-types"
import styles from "./textarea"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const Textarea = ({
  name,
  label,
  placeholder,
  required,
  value,
  handleChange,
  title,
  rows,
  className
}) => (
  <div className={cx(["container", className])}>
    <label htmlFor={name}>{label}</label>
    <div className={cx("textarea-wrapper")}>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        title={title}
        rows={rows}
      />
    </div>
  </div>
)

Textarea.propTypes = {
  name: string,
  label: string,
  placeholder: string,
  required: bool,
  className: string,
  title: string,
  rows: number,
  value: oneOfType([string, number])
}

export default Textarea
