import React, { useEffect } from "react"
import { connect } from "react-redux"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { useMutation, gql } from "@apollo/client"
import Dropdown from "../../../components/Dropdown"
import FormSelect from "../../../components/FormSelect"
import Input from "../../../components/Input"
import InputAddressContainer from "../InputAddressContainer"
import ProfileFormHeading from "../../../components/ProfileFormHeading"
import SelectedTags from "../../../components/SelectedTags"
import { actions } from "../../../actions"
import styles from "./profile-primary-form"
import classNames from "classnames/bind"
import { getYears } from "../../../utils"
import {UpdateUserProfile} from "../../../queries/artists"

const cx = classNames.bind(styles)

const ProfileForm = ({
  user: {
    formats: initialFormats,
    categories: initialCategories,
    firstName: initialFirstName,
    lastName: initialLastName,
    livesAndWorks: initialLivesAndWorks,
    contactEmail: initialContactEmail,
    genderPronoun: initialGenderPronoun,
    birthYear: initialBirthYear,
    hometown: initialHometown,
    website: initialWebsite,
  },
  updateField,
  updateLocation,
  id,
  categories,
  firstName,
  lastName,
  livesAndWorks,
  contactEmail,
  genderPronoun,
  birthYear,
  hometown,
  website,
  setAsSaving,
  setAsNotSaving,
  saving,
  allFormats,
  allCategories,
  formats,
  handleChange,
  addRelation,
  removeRelation,
}) => {
  useEffect(() => {
    updateField({
      formats: initialFormats ? initialFormats.map((f) => f.id) : [],
      categories: initialCategories ? initialCategories.map((c) => c.id) : [],
      firstName: initialFirstName,
      lastName: initialLastName,
      contactEmail: initialContactEmail,
      genderPronoun: initialGenderPronoun ? initialGenderPronoun : "",
      birthYear: initialBirthYear ? initialBirthYear : "",
      website: initialWebsite ? initialWebsite : "",
    })
    updateLocation({
      type: "livesAndWorks",
      data: {
        value: initialLivesAndWorks,
      },
    })
    updateLocation({
      type: "hometown",
      data: {
        value: initialHometown ? initialHometown : "",
      },
    })
  }, [])

  const [mutate] = useMutation(UpdateUserProfile)

  const handleSubmit = (e) => {
    e.preventDefault()
    setAsSaving()

    geocodeByAddress(livesAndWorks.value)
      .then((results) => getLatLng(results[0]))
      .then((coordinates) => {
        const livesAndWorksPoint = `POINT(${coordinates.lng} ${coordinates.lat})`

        mutate({
          variables: {
            id,
            formats,
            categories,
            firstName,
            lastName,
            livesAndWorks: livesAndWorks.value,
            livesAndWorksPoint,
            contactEmail,
            genderPronoun,
            birthYear,
            hometown: hometown ? hometown.value : "",
            website,
          },
        }).then((res) => {
          setAsNotSaving(res)
        })
      })
      .catch((error) => setAsNotSaving(error))
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={cx("heading")}>
          <ProfileFormHeading label={"Profile Information"} buttonText={"save"}
          helperText={"(fields marked with an * are required)"}
          saving={saving} />
        </div>
        <Input
          id="firstName"
          name="firstName"
          type="text"
          value={firstName}
          handleChange={handleChange}
          label="First Name *"
          required={true}
          className={"wide"}
        />
        <Input
          id="lastName"
          name="lastName"
          type="text"
          value={lastName}
          handleChange={handleChange}
          label="Last Name *"
          required={true}
          className={"wide"}
        />
        <InputAddressContainer
          label="Lives / Works Location *"
          name="livesAndWorks"
          type={"livesAndWorks"}
          required={true}
          className={"wide"}
        />
        <Input
          id="contactEmail"
          name="contactEmail"
          type="email"
          value={contactEmail}
          handleChange={handleChange}
          label="Contact Email *"
          className={"wide"}
          required={true}
          hint={`Your Contact Email is linked with the Contact Artist button
              in your artist profile so people can send you messages to this
              address. Your Contact Email address itself is not part of your
              public profile and won't be displayed on Foundwork unless you
              otherwise choose to share it.`}
        />
        <FormSelect
            name="genderPronoun"
            type="text"
            label="Preferred Pronouns"
            placeholder={"Choose Pronoun"}
            options={["he/him/his", "she/her/hers", "they/them/theirs", "ze/zir/zirs", "Just my name please"].map((pronoun) => ({ value: pronoun, name: pronoun }))}
            handleChange={handleChange}
            active={genderPronoun}
            className={"wide"}
        />
        <FormSelect
          name="birthYear"
          type="text"
          label="Birth Year"
          placeholder={"Choose Year"}
          options={getYears(100).map((year) => ({ value: year, name: year }))}
          handleChange={handleChange}
          active={birthYear}
          className={"wide"}
        />
        <InputAddressContainer label="Hometown" name="hometown" type={"hometown"} className={"wide"} />
        <Input
          name="website"
          type="text"
          value={website}
          handleChange={handleChange}
          label="Website"
          className={"wide"}
        />
        <Dropdown
          name="formats"
          label="Formats (add up to 7) *"
          options={allFormats.map((format) => ({
            name: format.name,
            value: format.id,
          }))}
          onChange={(value) => addRelation("formats", value, formats)}
          placeholder="Formats"
          className={"wide"}
          resets
        >
          <SelectedTags
            tags={formats.map((format, index) => ({
              name: allFormats.find((f) => f.id === format.toString()).name,
            }))}
            onRemove={(index) => removeRelation("formats", index)}
          />
        </Dropdown>
        <Dropdown
          name="categories"
          label="Attributes (add up to 10)"
          options={allCategories.map((category) => ({
            name: category.name,
            value: category.id,
          }))}
          onChange={(value) => addRelation("categories", value, categories)}
          placeholder="Attributes"
          className={"wide"}
          resets
        >
          <SelectedTags
            tags={categories.map((category, index) => ({
              name: allCategories.find((c) => c.id === category.toString()).name,
            }))}
            onRemove={(index) => removeRelation("categories", index)}
          />
        </Dropdown>
      </form>
    </div>
  )
}

const mapStateToProps = ({ profile, session, cms }) => {
  const { saving } = cms
  const { userId: id } = session

  return {
    saving,
    id,
    ...profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  const {
    updateProfileInput,
    updateProfileLocation,
    addUserRelation,
    removeUserRelation,
    beginCmsMutation,
    endCmsMutation,
  } = actions

  return {
    updateField: (params) => dispatch(updateProfileInput(params)),
    updateLocation: ({ type, data }) => dispatch(updateProfileLocation({ type, data })),
    handleChange: (e) => {
      dispatch(updateProfileInput({ [e.target.name]: e.target.value }))
    },
    addRelation: (type, id, selected) => {
      if (!id) {
        return
      }
      if (type === "formats" && selected.length === 7) {
        return
      } else if (type === "categories" && selected.length === 10) {
        return
      }
      dispatch(addUserRelation({ type, id }))
    },
    removeRelation: (type, index) => dispatch(removeUserRelation({ type, index })),
    setAsSaving: () => dispatch(beginCmsMutation()),
    setAsNotSaving: (res) => dispatch(endCmsMutation(res)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
