import React from "react"
import styles from "./form-select"
import { string, array, func } from "prop-types"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const FormSelect = ({
  name,
  label,
  options,
  active,
  handleChange,
  placeholder,
  className,
  required
}) => {
  return (
    <div className={cx(["container", className])}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={cx("select-wrapper")}>
        <select
          name={name}
          id={name}
          value={active ? active : ""}
          onBlur={handleChange}
          onChange={handleChange}
          required={required}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          }<option value="">{""}</option>
          {options.map((option, i) => {
            const { name, value } = option
            return (
              <option value={value} key={value}>
                {name}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

FormSelect.propTypes = {
  name: string,
  label: string,
  options: array,
  handleChange: func,
  className: string,
  placeholder: string
}

export default FormSelect
