import React from "react"
import { string, func, array, bool, number } from "prop-types"
import classNames from "classnames/bind"
import styles from "./artwork-list-item"
import { displayDimensions, displayVideoDuration } from "../../utils"
import Video from "../Video"
import ProgressIndicator from "../ProgressIndicator"

const ArtworkListItem = ({
  // TODO Compact props into separate objects
  // Artwork props
  title,
  image,
  year,
  medium,
  dimensions,
  videoLink,
  videoDurationMinutes,
  videoDurationSeconds,
  published,

  // Action props
  onDelete,
  onEdit,
  onPublishChange,

  // UI props
  isProcessing,
  processingProgress,
}) => {
  const cx = classNames.bind(styles)

  return (
    <li className={cx("list-item")}>
      {isProcessing && (
        <div className={cx("overlay")}>
          <div className={cx("progress")}>
            <ProgressIndicator
              trackProgress={isProcessing && processingProgress != null}
              progress={processingProgress}
            />
          </div>
        </div>
      )}
      <div className={cx("container")}>
        <div className={cx("image")}>
          {!isProcessing && !videoLink && <img src={image} alt={title} />}
          {!isProcessing && videoLink && <Video url={videoLink} height="80px" width="100%" />}
        </div>

        <div className={cx("info")}>
          <div className={cx("title")}>{title || "Untitled"}</div>
          <div className={cx("year")}>{year}</div>
          <div className={cx("medium")}>{medium}</div>

          {dimensions && !!dimensions.length && dimensions[0] && (
            <div className={cx("dimensions")}>{displayDimensions(dimensions)}</div>
          )}

          {videoDurationMinutes && videoDurationSeconds && (
            <div className={cx("medium")}>
              {displayVideoDuration(videoDurationMinutes, videoDurationSeconds)}
            </div>
          )}

          <div
            className={cx("status")}
            onChange={e => {
              e.preventDefault()
              onPublishChange(!published)
            }}
          >
            <select
              disabled={isProcessing}
              defaultValue={`${published ? "Published" : "Unpublished"}`}
              className={cx(`${published ? "published" : ""}`)}
            >
              <option>Published</option>
              <option>Unpublished</option>
            </select>
          </div>
        </div>

        <div className={cx("actions")}>
          <div className={cx("button-stack")}>
            <button
              disabled={isProcessing}
              onClick={ e => {
                e.preventDefault()
                onEdit()
              }}
            >
              Edit
            </button>

            <button
              disabled={isProcessing}
              onClick={ e => {
                e.preventDefault()
                onDelete()
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

ArtworkListItem.defaultProps = {
  isProcessing: false
}

ArtworkListItem.propTypes = {
  // Artwork props
  title: string,
  image: string,
  year: number,
  medium: string,
  dimensions: array,
  videoLink: string,
  videoDurationMinutes: string,
  videoDurationSeconds: string,
  published: bool,

  // Action props
  onDelete: func,
  onEdit: func,
  isProcessing: bool,

  // UI props
  isProcessing: bool,
  processingProgress: number,
}

export default ArtworkListItem
