import React from "react"
import { connect } from "react-redux"
import { useQuery, gql } from "@apollo/client"
import CvForm from "../CvForm"

const cvQuery = gql`
  query CvWrapperQuery($id: ID!) {
    user(id: $id) {
      id
      cv
    }
  }
`

const CvWrapper = ({ id }) => {
  const response = useQuery(cvQuery, {
    variables: { id }
  })

  const { data } = response || {}
  const { user } = data || {}

  return (
    <React.Fragment>{user ? <CvForm doc={user.cv} /> : null}</React.Fragment>
  )
}

const mapstatetoprops = ({ session }) => {
  const { userId } = session

  return { id: userId }
}

const mapdispatchtoprops = dispatch => ({})

export default connect(mapstatetoprops, mapdispatchtoprops)(CvWrapper)
