import React from "react"

import { Link } from "react-router-dom"
import { homeRoute } from "../../constants.js"

import styles from "./logo"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const Logo = props => (
  <Link to={homeRoute} className={cx("logo")}>
    Foundwork
  </Link>
)

export default Logo
