import React, { useEffect } from "react"
import { useMutation, gql } from "@apollo/client"
import { connect } from "react-redux"
import { actions } from "../../../actions"
import DocumentEditor from "../../../components/DocumentEditor"
import {UpdateUserStatement} from "../../../queries/artists"

const StatementForm = ({ doc, updateText, userId, statement, setAsSaving, saving, setAsNotSaving }) => {
  const [mutate] = useMutation(UpdateUserStatement)

  useEffect(() => {
    if (doc) {
      updateText(doc)
    }
  }, [])

  const handleChange = (e) => {
    updateText(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setAsSaving()
    mutate({ variables: { id: userId, statement } }).then((res) => {
      setAsNotSaving(res)
    })
  }

  return (
    <DocumentEditor
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      saving={saving}
      text={statement}
    />
  )
}

const mapStateToProps = ({ cms, session }) => {
  const { statement, saving } = cms
  const { userId } = session
  return { statement, userId, saving }
}

const mapDispatchToProps = (dispatch) => {
  const { updateArtistStatementForm, beginCmsMutation, endCmsMutation } = actions

  return {
    updateText: (text) => dispatch(updateArtistStatementForm(text)),
    setAsSaving: () => dispatch(beginCmsMutation()),
    setAsNotSaving: (res) => dispatch(endCmsMutation(res)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementForm)
