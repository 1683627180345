import React from "react"
import { connect } from "react-redux"
import { useQuery, gql } from "@apollo/client"
import StatementForm from "../StatementForm"
import {StatementQuery} from "../../../queries/artists"

const StatementWrapper = ({ id }) => {
  const response = useQuery(StatementQuery, {
    variables: { id }
  })

  const { data } = response || {}
  const { user } = data || {}

  return user ? <StatementForm doc={user.statement} /> : null
}

const mapStateToProps = ({ session }) => {
  const { userId } = session

  return { id: userId }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StatementWrapper)
