import { types } from "../actions"

const settings = (
  state = {
    personalInfo: {
      saving: false,
      error: "",
      open: false,
      firstName: "",
      lastName: "",
      email: "",
    },
    passwordInfo: {
      saving: false,
      error: "",
      currentPassword: "",
      newPassword: "",
    },
    accountInfo: {
      canceling: false,
    },
  },
  { type, payload }
) => {
  switch (type) {
    case types.UPDATE_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          ...payload,
        },
      }
    case types.UPDATE_PASSWORD_INFO:
      return {
        ...state,
        passwordInfo: {
          ...state.passwordInfo,
          ...payload,
        },
      }
    case types.TOGGLE_SETTINGS_FORM:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          open: !state[payload].open,
        },
      }
    case types.TOGGLE_SETTINGS_SAVING:
      const { kind, error } = payload

      return {
        ...state,
        [kind]: {
          ...state[kind],
          saving: !state[kind].saving,
          error,
        },
      }
    case types.TOGGLE_CANCELING:
      return {
        ...state,
        accountInfo: {
          canceling: !state.accountInfo.canceling,
        },
      }
    default:
      return state
  }
}

export default settings
