import React from "react"
import useCouponValidation from "./useCouponValidation"
import FormField from "@/ui/forms/FormField"
import { Coupon } from "./Coupon"
import { LabelSize } from "@/ui/forms/Label"

type CouponUpdatedCallback = (coupon: Coupon | undefined) => void

interface CouponFieldProps {
  onCouponUpdate: CouponUpdatedCallback
}

const CouponField = ({ onCouponUpdate }: CouponFieldProps) => {
  const [validationStatus, validateCoupon] = useCouponValidation(onCouponUpdate)

  return (
    <FormField
      name="coupon"
      label="Member Code (optional)"
      placeholder="Member Code"
      labelSize={LabelSize.NORMAL}
      error={validationStatus === false ? "Invalid member code" : undefined}
      $disableAutocomplete={true}
      $highlight={validationStatus === true}
      $onValueChange={validateCoupon}
    />
  )
}

export default CouponField
