import styled, { css } from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import { ChangeEvent, InputHTMLAttributes } from "react"

interface AdditionalInputProps {
  $highlight?: boolean,
  $disableAutocomplete?: boolean,
  $onValueChange?: (value: string) => void,
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & AdditionalInputProps

const normalBorder = css`
  ${globalStylesCss};

  border: 1px solid var(--color-gray-19);
`

const highlightBorder = css`
  ${globalStylesCss};

  border: 1px solid var(--color-green);
`

// NOTE The styles of this component should be kept in sync with those of CreditCardInput
const Input = styled
  .input
  .attrs<AdditionalInputProps>(
    ({
       $disableAutocomplete,
       $onValueChange = () => {},
     }) => ({
      autoComplete: $disableAutocomplete ? "off" : "on",
      onChange: (ev: ChangeEvent<HTMLInputElement>) => $onValueChange(ev.target.value),
    }),
  )<AdditionalInputProps>`
  ${globalStylesCss};

  ${({ $highlight }) => $highlight ? highlightBorder : normalBorder};
  border-radius: 2px;
  letter-spacing: var(--letter-spacing-default);
  height: var(--input-height-primary);
  padding: 1px var(--padding-primary);
  width: 100%;

  &::placeholder {
    color: var(--font-color-tertiary);
  }

  &:focus {
    outline: none;

    border-color: var(${({ $highlight }) => $highlight ? "--color-green" : "--color-blue"});

    // TODO We need to counteract the global definition setting the blue outline on focused input by forcing it green
    // in case of highlight. Once the migration to the new CSS architecture is done we can simplify to the following:
    // ${({ $highlight }) => !$highlight && css`border-color: var(--color-blue);`}
  }

  &:invalid {
    box-shadow: none;
  }
`

export default Input
