import { types } from "../actions"

const authForm = (
  state = {
    error: null,
    loading: false
  },
  { type, payload }
) => {
  switch (type) {
    case types.RESET_AUTH_FORM:
      return {
        ...state,
        error: null,
        loading: false
      }
    case types.REQUEST_AUTH_FORM_SUBMIT:
      return {
        ...state,
        error: null,
        loading: true
      }
    case types.RECEIVE_AUTH_FORM_SUBMIT:
      return {
        ...state,
        error: null,
        loading: false
      }
    case types.AUTH_FORM_SUBMIT_FAILURE:
      return {
        ...state,
        error: payload.error,
        loading: false
      }
    default:
      return state
  }
}

export default authForm
