import { types } from "../actions"

const artworkForm = (
  state = {
    isOpen: false,
    primaryImage: null,
    imageFiles: [],
    formats: [],
    categories: [],
    error: null,
    saving: false,
    editingArtwork: null
  },
  { type, payload }
) => {
  switch (type) {
    case types.ADD_ARTWORK_IMAGES:
      // TODO I sense an opportunity for simplification here...
      // editingArtwork.artworkImages -> grqphQL artworkImage obj + base64
      // encodings of new images
      var imgs = state.editingArtwork
        ? state.editingArtwork.artworkImages
        : state.imageFiles || []
      imgs = [...imgs, ...payload].slice(0, 6)
      if (state.editingArtwork) state.editingArtwork.artworkImages = imgs
      else state.imageFiles = imgs
      return {
        ...state
      }
    case types.ADD_ARTWORK_VIDEO:
      return {
        ...state,
        editingArtwork: {
          ...state.editingArtwork,
          videoLink: payload
        }
      }
    case types.REMOVE_ARTWORK_IMAGE:
      var imgs = state.editingArtwork
        ? state.editingArtwork.artworkImages
        : state.imageFiles || []
      imgs = imgs.slice(0, payload).concat(imgs.slice(payload + 1))
      if (state.editingArtwork) state.editingArtwork.artworkImages = imgs
      else state.imageFiles = imgs
      return {
        ...state
      }
    case types.REMOVE_ARTWORK_PRIMARY_IMAGE:
      return {
        ...state,
        primaryImage: null
      }
    case types.REMOVE_ARTWORK_VIDEO:
      return {
        ...state,
        editingArtwork: {
          ...state.editingArtwork,
          videoLink: null
        }
      }
    case types.RESET_ARTWORK_FORM:
      return {
        ...state,
        imageFiles: [],
        formats: [],
        categories: [],
        primaryImage: null,
        editingArtwork: null,
        dimensions: []
      }
    case types.ADD_ARTWORK_FORMAT:
      return {
        ...state,
        formats: [...new Set([...state.formats, payload])]
      }
    case types.REMOVE_ARTWORK_FORMAT:
      return {
        ...state,
        formats: [
          ...state.formats.slice(0, payload),
          ...state.formats.slice(payload + 1)
        ]
      }
    case types.ADD_ARTWORK_CATEGORY:
      return {
        ...state,
        categories: [...new Set([...state.categories, payload])]
      }
    case types.REMOVE_ARTWORK_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.slice(0, payload),
          ...state.categories.slice(payload + 1)
        ]
      }
    case types.OPEN_ARTWORK_FORM:
      return {
        ...state,
        isOpen: true
      }
    case types.CLOSE_ARTWORK_FORM:
      return {
        ...state,
        isOpen: false,
        imageFiles: [],
        formats: [],
        categories: [],
        editingArtwork: null,
        videoLink: null,
        dimensions: [],
        primaryImage: null,
        error: null
      }
    case types.BEGIN_ARTWORK_SAVE:
      return {
        ...state,
        error: null,
        saving: true
      }
    case types.FINISH_ARTWORK_SAVE:
      return {
        ...state,
        saving: false
      }
    case types.FAIL_ARTWORK_SAVE:
      return {
        ...state,
        error: payload.error,
        saving: false
      }
    case types.BEGIN_EDITING_ARTWORK:
      return {
        ...state,
        isOpen: true,
        editingArtwork: payload,
        formats: payload.formats, // Separate for editing purposes
        categories: payload.categories, // Separate for editing purposes
        primaryImage: payload.primaryImage
      }
    default:
      return state
  }
}

export default artworkForm
