import request from "superagent"

async function getOEmbedFrom(oembedService, query) {
  try {
    const info = await request.get(oembedService).query(query)
    return { available: true, ...(JSON.parse(info.text)) }
  } catch (error) {
    // NOTE: We could check if error.response.statusCode == 404 here, but I'm
    // not sure we have other cases to check so for now let's keep it real simple
    return { available: false }
  }
}

function noEmbed(url) {
  return ["https://noembed.com/embed", { url }]
}

function vimeoEmbed(url) {
  return ["https://vimeo.com/api/oembed.json", { url }]
}

function youTubeEmbed(url) {
  return ["https://youtube.com/oembed", { url }]
}

export async function videoPreviewInformation(url) {
  let oEmbedCoordinatesFor
  switch ((new URL(url)).host) {
    case "youtube.com":
    case "youtu.be":
      oEmbedCoordinatesFor = youTubeEmbed
      break
    case "vimeo.com":
      oEmbedCoordinatesFor = vimeoEmbed
      break
    default:
      oEmbedCoordinatesFor = noEmbed
      break
  }

  const embedInfo = await getOEmbedFrom(...(oEmbedCoordinatesFor(url)))

  return { available: embedInfo.available, thumbnailUrl: embedInfo.thumbnail_url }
}

