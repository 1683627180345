import React from "react"
import styles from "./login-flow-wrapper"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const LoginFlowWrapper = ({
  title,
  buttonText,
  onSubmit,
  children,
  error,
  loading
}) => (
  <div className={cx("container")}>
    <h2 className={cx("title")}>{title}</h2>

    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit(e.target)
      }}
    >
      {children}
      <button
        className={cx(["btn", "btn-blue", "btn-full-width", "submit"])}
        type="submit"
        disabled={loading}
      >
        {buttonText}
      </button>
    </form>
    {error && <div className={cx("error-message")}>{error}</div>}
  </div>
)

export default LoginFlowWrapper
