import React, {useEffect, useState} from "react"
import {createConsumer} from "@rails/actioncable/src"
import {useDispatch} from "react-redux"
import {useLoggedInUser} from "./loggedInUserContext"
import CurrentUser from "../../currentUser"
import {actions} from "../../actions"

const SuspendedUserListener = ({children}) => {
  const dispatch = useDispatch()
  const consumer = createConsumer("/websocket")

  const {loggedInUser, setLoggedInUser} = useLoggedInUser()

  useEffect(() => {
    if (!loggedInUser) {
      return
    }

    const subscription = consumer.subscriptions.create({channel: "SessionChannel", userId: loggedInUser}, {
      received({action}) {
        switch (action) {
          case "log_out":
            CurrentUser.clear()
            dispatch(actions.logOut())
            setLoggedInUser(null)
            break
          default:
            break
        }
      }
    })

    return () => {
      consumer.subscriptions.remove(subscription)
    }
  }, [loggedInUser])

  return (
    <>
      {children}
    </>
  )
}

export default SuspendedUserListener
