import React from "react"
import styles from "./invoice-list"
import classNames from "classnames/bind"
import {useQuery} from "@apollo/client";
import {LatestInvoicesQuery} from "../../queries/artists";
import {connect} from "react-redux";
import {format, parseISO} from "date-fns";
import ProfileFormHeading from "../ProfileFormHeading";
import CurrentUser from "../../currentUser";

const cx = classNames.bind(styles)

const InvoiceList = ({user: {id}}) => {
  const response = useQuery(LatestInvoicesQuery, {
    variables: {id},
  })

  const latestInvoices = response?.data?.user?.latestInvoices || []

  return (
    <div className={cx("container")}>
      <ProfileFormHeading label={"Invoices"}/>
      {latestInvoices.length > 0 ?
        <ol className={cx("invoice-list")}>
          {latestInvoices.map(({id, number, date, total, url}) => (
              <li key={id}>
                <div className={cx("invoice-number")}>
                  <a href={`${url}?token=${CurrentUser.token()}`} target="_blank">{number}</a>
                </div>
                <div className={cx("invoice-summary")}>
                  <div className={cx("date")}>
                    {format(new Date(parseISO(date)), "MMMM d, yyyy")}
                  </div>
                  <div className={cx("amount")}>
                    ${parseFloat(total).toFixed(2)}
                  </div>
                </div>
              </li>
            )
          )}
        </ol>
        : <EmptyInvoiceList />
      }
    </div>
  )
}

const EmptyInvoiceList = () => (<div className={cx("message")}>There are no invoices available at this time.</div>)

const mapStateToProps = ({session}) => {
  const {userId} = session

  return {user: {id: userId}}
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceList)
