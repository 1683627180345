import React from "react"
import {bool, func, string} from "prop-types"
import styles from "./header-user-menu"
import classNames from "classnames/bind"
import {Link} from "react-router-dom"

import {homeRoute, savedArtistsRoute, settingsRoute} from "../../constants.js"

const cx = classNames.bind(styles)

const HeaderUserMenu = ({username, logOut}) => {
  const classNames = {
    "header-user-menu": true
  }

  return (
    <div>
      <div className={cx(classNames)}>
        <button className={cx("username")}>{username}</button>
        <div className={cx("dropdown")}>
          <Link to={savedArtistsRoute}>Saved Artists</Link>
          <Link to={settingsRoute}>Settings</Link>
          <Link to={homeRoute} onClick={logOut}>
            Log out
          </Link>
        </div>
      </div>
    </div>
  )
}

HeaderUserMenu.propTypes = {
  username: string,
  isActive: bool,
  toggleHeaderUserMenu: func
}

export default HeaderUserMenu
