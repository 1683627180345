import React from "react"
import { connect } from "react-redux"
import { bool, func } from "prop-types"
import { editArtistRouteFn } from "../../../constants.js"
import Header from "../../../components/Header"
import { actions } from "../../../actions.js"

const HeaderContainer = (props, { data: { loading, error, user } = {} }) => (
  <Header {...props} />
)

HeaderContainer.propTypes = {
  loggedIn: bool,
  logOut: func
}

const mapStateToProps = ({ session, router, header }) => {
  const { loggedIn, userSlug, isArtist } = session
  const { location } = router
  const { mobileSearchOpen } = header

  const isCms = location.pathname.includes(editArtistRouteFn(userSlug))

  return {
    loggedIn,
    userSlug,
    isArtist,
    isCms,
    mobileSearchOpen
  }
}

const mapDispatchToProps = dispatch => {
  const { toggleMobileSearchOverlay } = actions

  return {
    handleClick: () => dispatch(toggleMobileSearchOverlay())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    pure: false
  }
)(HeaderContainer)
