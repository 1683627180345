import React, { useRef, useEffect } from "react"
import styles from "../../../components/SearchBox/search-box"
import classNames from "classnames/bind"
import { pxToBottomOfWindow } from "../../../utils"
import { useQuery, gql } from "@apollo/client"

const cx = classNames.bind(styles)

const AutoCompleteMenu = ({ inputValue, highlightedIndex, getItemProps }) => {
  const resultsContainer = useRef(null)

  useEffect(() => {
    if (!resultsContainer.current) {
      return
    }
    const pxToBottom = pxToBottomOfWindow(resultsContainer)

    // add scroll if search results go beyond the window viewport
    if (pxToBottom <= 0) {
      resultsContainer.current.style.maxHeight =
        resultsContainer.current.offsetHeight + pxToBottom + "px"
      resultsContainer.current.style.overflow = "scroll"
    } else {
      resultsContainer.current.style.maxHeight = "auto"
      resultsContainer.current.style.overflow = "visible"
    }
  }, [resultsContainer])

  const response = useQuery(searchQuery, {
    variables: {
      inputValue
    }
  })
  const { data } = response || {}

  const { search, loading } = data || {}

  if (loading) {
    return (
      <div className={cx("results-container")}>
        <div className={cx("results-label")}>Loading...</div>
      </div>
    )
  }

  const artists = search ? search.filter(result => result.type === "User") : []
  return (
    <div ref={resultsContainer} className={cx("results-container")}>
      {artists.length ? (
        <div className={cx("results-label")}>search results</div>
      ) : null}
      {artists
        .filter(
          item =>
            !inputValue ||
            item.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item, index) => {
          const highlighted = highlightedIndex === index

          return (
            <div
              {...getItemProps({
                item,
                index,
                key: `${item.type}${item.slug}`
              })}
              className={cx(["result", `${highlighted ? "highlighted" : ""}`])}
            >
              {item.name}
            </div>
          )
        })}
    </div>
  )
}

const searchQuery = gql`
  query Search($inputValue: String!) {
    search(input: $inputValue) {
      name
      slug
      type
    }
  }
`

export default AutoCompleteMenu
