import React from "react"
import styles from "./page-title-header"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const PageTitleHeader = ({ title }) => (
  <div className={cx("heading-container")}>
    <h1>{title}</h1>
  </div>
)

export default PageTitleHeader
