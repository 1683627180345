import React, { Component, useEffect } from "react"
import { useQuery, gql } from "@apollo/client"
import { connect } from "react-redux"
import { string, array, obj, number } from "prop-types"
import classNames from "classnames/bind"
import { omit } from "lodash"
import styles from "./artwork-search"
import SearchOptions from "../../../containers/explore/SearchOptions"
import SortingOptions from "../../../containers/explore/SortingOptions"
import ArtworkDetailModal from "../../../containers/explore/ArtworkDetailModal"
import ArtworkResults from "../../../containers/explore/ArtworkResults"
import LoadingSpinner from "../../../components/LoadingSpinner"
import Helmet from "../../../components/Helmet"
import { actions } from "../../../actions"

const cx = classNames.bind(styles)

const artworkQuery = gql`
  query ArtworkSearchQuery($params: SearchParams) {
    artworks(params: $params) {
      currentPage
      totalPages
      data {
        id
        title
        formats {
          id
          name
        }
        dimensions
        year
        medium
        primaryImage {
          medium
          large
        }
        artworkImages {
          image {
            thumb
            medium
            large
          }
        }
        artist {
          slug
          name
        }
        videoLink
        videoDurationMinutes
        videoDurationSeconds
        additionalInformation
      }
    }
  }
`

const sortingOptions = [
  {
    name: "Featured",
    value: "-featured,-updated_at",
  },
  {
    name: "Recently Updated",
    value: "-updated_at",
  },
  {
    name: "Newest-Oldest",
    value: "-year",
  },
  {
    name: "Oldest-Newest",
    value: "year",
  },
]

const ArtworkSearch = ({
  previewIndex,
  resetSelectedArtwork,
  order,
  page,
  formats,
  categories,
  location,
  organizations,
  programs,
  years,
  degreeTypes,
}) => {
  useEffect(() => {
    resetSelectedArtwork()
  }, [])

  const response = useQuery(artworkQuery, {
    variables: {
      params: {
        order,
        page,
        formats,
        categories,
        location,
        organizations,
        programs,
        years,
        degreeTypes,
      },
    },
  })
  const { data, loading } = response || {}
  const { artworks } = data || {}

  return (
    <div className={cx("container")}>
      <Helmet title={`Foundwork | Artwork`} />
      <SearchOptions />
      <SortingOptions label={"Sort by"} name={"artwork_sort"} format={"artwork"} options={sortingOptions} />
      {loading && <LoadingSpinner />}
      <ArtworkResults
        artworks={artworks ? artworks.data : []}
        loading={loading}
        currentPage={artworks ? artworks.currentPage : null}
        totalPages={artworks ? artworks.totalPages : null}
      />
      {previewIndex !== null && <ArtworkDetailModal artworks={artworks ? artworks.data : []} />}
    </div>
  )
}

ArtworkSearch.propTypes = {
  order: string,
  page: number,
  formats: array,
  categories: array,
  location: obj,
  programs: array,
  years: array,
  degreeTypes: array,
}

const mapStateToProps = ({ artworkExplore, search }) => {
  const { previewIndex } = artworkExplore
  const {
    order,
    page,
    formats,
    categories,
    location,
    organizations,
    mfaPrograms,
    programYears,
    degreeTypes,
  } = search
  return {
    previewIndex,
    order: order.artwork,
    page,
    formats,
    categories,
    location: omit(location, "place"),
    programs: mfaPrograms.map((a) => a.value) || [],
    organizations: organizations.map((a) => a.value) || [],
    years: programYears.map((a) => parseInt(a, 10)),
    degreeTypes,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { closeArtworkPreview } = actions

  return {
    resetSelectedArtwork: () => {
      const element = document.getElementsByTagName("html")[0]
      element.classList.remove("locked")
      dispatch(closeArtworkPreview())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkSearch)
