import React, { Component } from "react"
import { connect } from "react-redux"
import { getSection } from "../../../services/section"
import { actions } from "../../../actions.js"
import DialoguesDetail from "../../../components/DialoguesDetail"

class DialoguesDetailContainer extends Component {
  componentDidMount() {
    const { slug } = this.props.match.params
    this.props.getSection(`dialogues-${slug}`)
  }

  componentWillUnmount() {
    this.props.clearSection()
  }

  render() {
    return (
      <div>
        {this.props.section && <DialoguesDetail {...this.props.section} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { section } = state
  return { section }
}

const mapDispatchToProps = dispatch => {
  const { getSectionSuccess, clearSection } = actions

  return {
    getSection: slug => {
      getSection(slug, ({ err, section }) => {
        if (!err && section) {
          dispatch(getSectionSuccess({ section }))
        } else {
          console.error(err)
        }
      })
    },
    clearSection: () => dispatch(clearSection())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialoguesDetailContainer)
