import React from "react"
import { Route } from "react-router-dom"
import { connect } from "react-redux"
import { useQuery, gql } from "@apollo/client"
import { Link } from "react-router-dom"
import { actions } from "../../../actions"
import { homeRoute } from "../../../constants.js"
import Profile from "../Profile"
import Cv from "../Cv"
import Statement from "../Statement"
import Works from "../Works"
import styles from "./wrapper"
import classNames from "classnames/bind"

const cmsWrapperQuery = gql`
  query CmsWrapperQuery($id: ID!) {
    user(id: $id) {
      id
      publicProfile
    }
  }
`

const cx = classNames.bind(styles)

const Wrapper = ({ closeNotice, unpublishedNoticeClosed, match, id } = {}) => {
  const response = useQuery(cmsWrapperQuery, {
    variables: { id }
  })

  const { data } = response || {}
  const { user: { publicProfile } = {} } = data || {}

  return (
    <div className={cx("container")}>
      {!publicProfile && !unpublishedNoticeClosed && (
        <div className={cx("unpublished-notice")}>
          <p>
            {
              "To publish your artist profile and artwork on Foundwork: (1) enter the required profile information (marked with an *) and (2) select a profile image."
            }
          </p>
          <button onClick={closeNotice} className={cx("close-notice")}>
            &times;
          </button>
        </div>
      )}
      <div className={cx("desktop")}>
        <Route path={`${match.url}/profile`} component={Profile} />
        <Route path={`${match.url}/cv`} component={Cv} />
        <Route path={`${match.url}/statement`} component={Statement} />
        <Route path={`${match.url}/works`} component={Works} />
      </div>
      <div className={cx("mobile")}>
        <p>
          {
            "Our CMS is designed to be used on computer-sized screens, so it isn’t available on mobile phones or browser windows below a certain size. Please visit CMS on a computer to manage your profile on Foundwork."
          }
        </p>

        <Link to={homeRoute} className={cx(["btn", "btn-blue", "button"])}>
          Go to Foundwork
        </Link>
      </div>
    </div>
  )
}

const mapstatetoprops = ({ session, cms }) => {
  const { userId } = session
  const { unpublishedNoticeClosed } = cms

  return { id: userId, unpublishedNoticeClosed }
}

const mapdispatchtoprops = dispatch => {
  const { closeUnpublishedNotice } = actions

  return {
    closeNotice: () => {
      dispatch(closeUnpublishedNotice())
    }
  }
}

export default connect(mapstatetoprops, mapdispatchtoprops)(Wrapper)
