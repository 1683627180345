import React, { Component } from "react"
import { connect } from "react-redux"
import { string, func, object } from "prop-types"
import classNames from "classnames/bind"
import styles from "./artwork-detail-modal"
import ArtworkDetail from "../../../components/ArtworkDetail"
import { actions } from "../../../actions"

const cx = classNames.bind(styles)

class ArtworkDetailModal extends Component {
  constructor(props) {
    super(props)
    this.onKeydown = this.onKeydown.bind(this)
  }

  componentDidMount() {
    document.removeEventListener("keydown", this.onKeydown)
    document.addEventListener("keydown", this.onKeydown)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeydown)
  }

  onKeydown(e) {
    const { onNext, onPrevious, onClose } = this.props
    switch (e.keyCode) {
      case 37: // Left
        onPrevious()
        break
      case 39: // Right
        onNext()
        break
      case 27: // Escape
        onClose()
        break
      default:
        break
    }
  }

  render() {
    const {
      artwork,
      imageUrl,
      videoUrl,
      artistName,
      artistSlug,
      onNext,
      onPrevious,
      onClose
    } = this.props

    if (!artwork.year) {
      const element = document.getElementsByTagName("html")[0]
      element.classList.remove("locked")
    }

    return (
      <div className={cx("wrapper", { active: !!artwork.year })}>
        <div className={cx("container")}>
          <button
            className={cx("close-button")}
            aria-label={"close-button"}
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
          />
          <div className={cx("content")}>
            <ArtworkDetail
              {...artwork}
              imageUrl={imageUrl}
              videoUrl={videoUrl}
              artistName={artistName}
              artistSlug={artistSlug}
            />
          </div>
        </div>
        <button
          className={cx("previous-button")}
          onClick={e => {
            e.preventDefault()
            onPrevious()
          }}
        >
          ← Prev
        </button>
        <button
          className={cx("next-button")}
          onClick={e => {
            e.preventDefault()
            onNext()
          }}
        >
          Next →
        </button>
      </div>
    )
  }
}

ArtworkDetailModal.propTypes = {
  artwork: object,
  imageUrl: string,
  videoUrl: string,
  artistName: string,
  artistSlug: string,
  onNext: func,
  onPrevious: func,
  onClose: func
}

const mapStateToProps = ({ artworkExplore }, { artworks }) => {
  const { previewIndex } = artworkExplore
  const artwork = artworks[previewIndex] || {}
  let imageUrl = artwork.primaryImage ? artwork.primaryImage.large : ""
  if (!imageUrl || imageUrl.length === 0) {
    imageUrl = artwork.primaryImage ? artwork.primaryImage.thumb : ""
  }
  const artistName = artwork.artist ? artwork.artist.name : ""
  const artistSlug = artwork.artist ? artwork.artist.slug : ""
  const videoUrl = artwork.videoLink ? artwork.videoLink : ""

  return {
    artwork,
    artistName,
    artistSlug,
    imageUrl,
    videoUrl
  }
}

const mapDispatchToProps = dispatch => {
  const { closeArtworkPreview, incrementArtworkPreview } = actions
  return {
    onNext: () => {
      dispatch(incrementArtworkPreview({ increment: 1 }))
    },
    onPrevious: () => {
      dispatch(incrementArtworkPreview({ increment: -1 }))
    },
    onClose: () => {
      const element = document.getElementsByTagName("html")[0]
      element.classList.remove("locked")
      dispatch(closeArtworkPreview())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkDetailModal)
