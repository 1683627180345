class Analytics {
  emitEvent(...args: Gtag.GtagCommands["event"]) {
    this.safeGtag("event", ...args)
  }

  private safeGtag<Command extends keyof Gtag.GtagCommands>(
    command: Command,
    ...args: Gtag.GtagCommands[Command]
  ) {
    if (typeof gtag !== "undefined") {
      gtag(command, ...args)
    }
  }
}

const analytics = new Analytics()

export default analytics
