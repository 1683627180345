import React from "react"
import { string } from "prop-types"
import styles from "./profile-form-heading"
import classNames from "classnames/bind"
import Tooltip from "../../components/Tooltip"

const cx = classNames.bind(styles)

const ProfileFormHeading = ({
  label,
  buttonText,
  buttonStyle = "blue",
  helperText,
  saving,
  tooltip
}) => {
  const buttonClasses = {
    btn: true,
    [`btn-${buttonStyle}`]: true,
    saving
  }

  const submitText = buttonText || "Save"

  return (
    <div className={cx("label")}>
      <div className={cx("label-and-tooltip")}>
        {tooltip && <Tooltip copy={tooltip} /> }
        <h3>
          {label} <span className="helper-text small">{helperText}</span>
        </h3>
      </div>
      {buttonText && (
        <div>
          <button className={cx(buttonClasses)} type="submit">
            {saving ? "Saving..." : submitText}
          </button>
        </div>
      )}
    </div>
  )
}

ProfileFormHeading.propTypes = {
  label: string,
  buttonText: string,
  helperText: string
}

export default ProfileFormHeading
