import React from "react"
import classNames from "classnames/bind"
import { string, bool, func, number, oneOfType } from "prop-types"
import styles from "./radio-filter"

const cx = classNames.bind(styles)

const RadioFilter = ({
  value,
  name,
  isActive,
  onActiveClick,
  onInactiveClick
}) => (
  <li className={cx("container")}>
    {isActive ? (
      <button
        className={cx("active")}
        onClick={e => {
          e.preventDefault()
          onActiveClick(value)
        }}
      >
        {name}
      </button>
    ) : (
      <button
        onClick={e => {
          e.preventDefault()
          onInactiveClick(value)
        }}
      >
        {name}
      </button>
    )}
  </li>
)

RadioFilter.propTypes = {
  name: string,
  value: oneOfType([string, number]),
  isActive: bool,
  onActiveClick: func,
  onInactiveClick: func
}

export default RadioFilter
