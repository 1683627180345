import React from "react"
import classNames from "classnames/bind"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import GuestCard from "../GuestCard"
import LoadingSpinner from "../LoadingSpinner"
import StaticHero from "../StaticHero"
import { sortBy } from "lodash"
import { Link } from "react-router-dom"
import { guestSelectionsDetailRouteFn } from "../../constants"

import styles from "./guests"
const cx = classNames.bind(styles)

const Guests = props => {
  const { title, content, sections: guests, navigateTo } = props

  const names = sortBy(guests, ({ title }) => {
    const arr = title.split(" ")
    return arr[arr.length - 1]
  })

  return content ? (
    <div className={cx("guests")}>
      <StaticHero title={title} content={content}>
        <div className={cx("list-wrapper")}>
          <div className={cx("curator-list")}>
            {names.map(({ title, slug }) => (
              <Link to={guestSelectionsDetailRouteFn(slug)}>{title}</Link>
            ))}
          </div>
          <select
            className={cx("curator-select")}
            onBlur={e => {
              if (e.target.value === "Curators") return
              navigateTo(guestSelectionsDetailRouteFn(e.target.value))
            }}
          >
            <option selected={true} disabled={true}>
              Curators
            </option>
            {names.map(({ title, slug }) => (
              <option key={slug} value={slug}>
                {title}
              </option>
            ))}
          </select>
        </div>
      </StaticHero>
      {guests.map((section, i) => (
        <GuestCard key={i} index={i} {...section} displayExpandToggle={true} />
      ))}
    </div>
  ) : (
    <div className={cx("spinner")}>
      <LoadingSpinner />
    </div>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: route => {
      dispatch(push(route))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Guests)
