import Cookies from "js-cookie"

class CurrentUser {
  static create(data) {
    Cookies.set("userData", JSON.stringify(data))
  }

  static token() {
    return this.get("token")
  }

  static id() {
    return this.get("user_id")
  }

  static slug() {
    return this.get("slug")
  }

  static role() {
    return this.get("role")
  }

  static get(key) {
    let rawData = Cookies.get("userData")
    let data = rawData ? JSON.parse(rawData) : {}
    return data[key]
  }

  static clear() {
    Cookies.remove("userData")
  }

  static isArtist() {
    return this.get("role") === "artist"
  }
}

export default CurrentUser
