import React, { useEffect, useState } from "react"
import FormFieldBase, { FormFieldBaseProps } from "./FormFieldBase"
import styled, { css } from "styled-components"
import Button from "@/ui/elements/Button"
import FormField, { FormFieldProps } from "./FormField"
import _ from "lodash"
import { forTabletPortraitUp, forMobileOnly } from "@/ui/styles/breakpoints"

// NOTE
// Amount are in cents!!!

type AmountSelectorProps = Pick<FormFieldBaseProps, "name" | "label" | "labelSize" | "error"> & {
  amounts: { value: number, label: string }[],
  min: number,
  onAmountUpdated: (amount: number | undefined) => void,
}

const SelectionGroup = styled.div`
  @media screen and (${forTabletPortraitUp}) {
    display: flex;
    flex-direction: row;
      
    & > * {
      flex: 1 1 0;
      
      // First and last elements have rounded corners
      :first-child {
        border-radius: 2px 0 0 2px;
      }

      :last-child {
        border-radius: 0 2px 2px 0;
      }
      
      // Remove double border on adjacent items
      :not(:first-child) {
        border-left: none;
      }
    }
  }
  
  @media screen and (${forMobileOnly}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
    & > * {
      // Corner elements have rounded corners
      :first-child {
        border-radius: 2px 0 0 0;
      }

      :nth-child(3) {
        border-radius: 0 2px 0 0;
      }
      
      :nth-last-child(1) {
        border-radius: 0 0 2px 0;
      }
      
      :nth-last-child(3) {
        border-radius: 0 0 0 2px;
      }
      
      // Remove double horizontal border on adjacent rows
      :not(:nth-child(-n + 3)) {
        border-top: none;
      }
      
      // Remove double vertical border on adjacent columns
      :not(:nth-child(3n - 2)) {
        border-left: none;
      }
    }
  }
`

interface SelectionProps {
  selected?: boolean
}

const selectedStyling = css`
  background-color: var(--font-color-secondary);
  color: var(--color-white);
  border-color: var(--font-color-secondary);
`

const unselectedStyling = css`
  background-color: var(--color-white);
  color: var(--font-color-secondary);
  border-color: var(--color-gray-19);
`

const Selection = styled(Button)<SelectionProps>`
  border: 1px solid;
  border-radius: unset;
  
  ${({ selected = false }) => selected ? selectedStyling : unselectedStyling}

  padding: 1em;
`

type CustomAmountInputProps =
  Pick<AmountSelectorProps, "min" | "onAmountUpdated">
  & Pick<FormFieldProps, "label" | "error">

const CustomAmountInput = (
  {
    min,
    onAmountUpdated,
    label,
    error,
  }: CustomAmountInputProps,
) => {
  const [customAmountError, setCustomAmountError] = useState<string | undefined>(undefined)

  const toCents = (dollars: number) => dollars * 100
  const toDollars = (cents: number) => cents / 100

  const setError = (msg: string) => {
    setCustomAmountError(msg)
    onAmountUpdated(undefined)
  }

  const resetError = () => setCustomAmountError(undefined)

  const parseAmount = (rawAmount: string | null): number => {
    const allowedFormat = /^(?<amount>\d*\.?\d+)\$?$/

    const parsedResult = rawAmount?.match(allowedFormat)

    if (parsedResult === null || parsedResult === undefined) {
      throw Error("Please specify a number")
    } else {
      return toCents(parseFloat(parsedResult.groups!!.amount))
    }
  }

  const amountMeetsRequirements = (amount: number) => amount >= min

  const updateCustomAmount = (rawAmount: string) => {
    try {
      const amount = parseAmount(rawAmount === "" ? null : rawAmount)

      resetError()

      if (!amountMeetsRequirements(amount)) {
        setError(`The amount specified is too low, the minimum donation amount is \$${toDollars(min)}`)
        return
      }

      onAmountUpdated(amount)
    } catch (err) {
      setError("The amount specified is not a number. Please input a number without using letters.")
    }
  }

  return <FormField
    name="customAmount"
    type="text"
    $onValueChange={_.debounce(updateCustomAmount, 400, { leading: false, trailing: true })}
    onBlur={ev => updateCustomAmount(ev.target.value)}
    label={label}
    required={true}
    error={customAmountError || error}
  />
}

const AmountSelector = ({ amounts, min, onAmountUpdated, error, ...baseFieldProps }: AmountSelectorProps) => {
  const [chosenAmountIndex, chooseAmountIndex] = useState<number | null>(null)

  const [isCustomAmountSelected, setCustomAmountSelected] = useState(false)

  // Whenever a user selects the custom donation amount button, we need to treat it as if there's no donation amount
  // anymore. Otherwise, upstream the previous preselected amount might remain stored, with unintended consequences.
  useEffect(() => {
      if (isCustomAmountSelected)
        onAmountUpdated(undefined)
    },
    [isCustomAmountSelected],
  )

  const choosePreselectedAmount = (index: number) => {
    setCustomAmountSelected(false)
    chooseAmountIndex(index)
    onAmountUpdated(amounts[index].value)
  }

  return <>
    <FormFieldBase error={!isCustomAmountSelected ? error : undefined} {...baseFieldProps}>
      <SelectionGroup>
        {amounts.map((amount, index) => (
          <Selection
            key={index}
            value={amount.value}
            onClick={() => {
              choosePreselectedAmount(index)
            }}
            selected={!isCustomAmountSelected && index === chosenAmountIndex}
          >
            {amount.label}
          </Selection>
        ))}
        <Selection
          selected={isCustomAmountSelected}
          onClick={() => setCustomAmountSelected(true)}
        >
          Other
        </Selection>
      </SelectionGroup>
    </FormFieldBase>
    {isCustomAmountSelected &&
      <CustomAmountInput label={baseFieldProps.label} error={error} min={min} onAmountUpdated={onAmountUpdated} />}
  </>
}

export default AmountSelector
