import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const ProgressIndicator = ({
  progress,
  trackProgress,
}) => {
  const styles = makeStyles({
    root: {
      position: "relative",
      marginRight: "-30px",
    },
    bottom: {
      color: "rgba(74, 144, 226, 0.2)",
    },
    top: {
      color: "rgb(74, 144, 226)",
      position: "relative",
      left: "-30px",
    },
  })()

  const progressType = trackProgress ? "determinate" : "indeterminate"

  return (
    <div className={styles.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={30}
        thickness={5.5}
        className={styles.bottom}
      />
      <CircularProgress
        variant={progressType}
        size={30}
        thickness={5.5}
        className={styles.top}
        value={progress}
      />
    </div>
  )
}

ProgressIndicator.defaultProps = {
  trackProgress: true
}

ProgressIndicator.propTypes = {
  progress: PropTypes.number,
  trackProgress: PropTypes.bool,
}

export default ProgressIndicator
