import React, { useState } from "react"
import { Redirect, Route } from "react-router-dom"
import { Switch } from "react-router"
import { ConnectedRouter as Router } from "connected-react-router"
import CurrentUser from "./currentUser.js"
import history from "./fwHistory"
import { connect } from "react-redux"
import { actions } from "./actions"

import ScrollToTop from "./components/ScrollToTop"
import Layout from "./containers/global/Layout"
import Home from "./pages/main/Home"
import Login from "./pages/login/Login"
import Signup from "./pages/login/Signup"
import Welcome from "./pages/login/Welcome"
import ForgotPassword from "./pages/login/ForgotPassword"
import ResetPassword from "./pages/login/ResetPassword"
import ArtistSearch from "./pages/explore/ArtistSearch"
import SavedArtists from "./pages/explore/SavedArtists"
import ArtistDetail from "./pages/artist/ArtistDetail"
import ArtworkSearch from "./pages/explore/ArtworkSearch"
import EditArtistWrapper from "./pages/cms/Wrapper"
import SettingsPage from "./pages/settings/Settings"
import FaqPage from "./pages/misc/Faq"
import AboutPage from "./pages/misc/About"
import GuestSelectionsPage from "./pages/guest-selections/Guests"
import GuestSelectionsDetailPageContainer from "./pages/guest-selections/GuestSelectionsDetailPageContainer"
import TermsPage from "./pages/misc/Terms"
import PrivacyPage from "./pages/misc/Privacy"
import PrizeRulesPage from "./pages/misc/PrizeRules"
import PrizePage from "./pages/misc/Prize"
import DialoguesPage from "./pages/misc/Dialogues"
import DialoguesDetailPage from "./pages/misc/DialoguesDetail"
import App from "./containers/misc/AppContainer"

import "./styles/core"

import {
  aboutRoute,
  artistDetailRoute,
  artistDetailRouteFn,
  artistSearchRoute,
  artworkSearchRoute,
  dialoguesDetailRoute,
  dialoguesRoute,
  editArtistRouteFn,
  faqRoute,
  forgotPasswordRoute,
  guestCuratorsRoute,
  guestSelectionsDetailRoute,
  homeRoute,
  loginRoute,
  privacyRoute,
  prizeRoute,
  prizeRulesRoute,
  resetPasswordRoute,
  savedArtistsRoute,
  settingsRoute,
  signupRoute,
  subscriptionRoute,
  donateRoute,
  termsRoute,
  welcomeRoute,
} from "./constants.js"
import SuspendedUserListener from "./containers/misc/SuspendedUserListener"
import { LoggedInUserContext } from "./containers/misc/loggedInUserContext"
import Donate from "@/routes/Donate"
import CreateArtistMembership from "@/routes/CreateArtistMembership"
import ReactivationPopup from "@/subscription/ReactivationPopup"

const Routes = ({ userSlug, showReactivationModal, closeReactivationModal }) => {
  const [loggedInUser, setLoggedInUser] = useState(CurrentUser.id())

  return (
    <Router history={history}>
      <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <SuspendedUserListener>
          <App>
            <Layout>
              <ScrollToTop />
              {/* TODO See if we can move this closer to where it's used instead of having redux handling it */}
              {showReactivationModal && (
                <ReactivationPopup isOpen={showReactivationModal} onClose={closeReactivationModal} />
              )}
              <Route exact path={homeRoute} component={Home} />
              <AuthRoute path={loginRoute} component={Login} />
              <AuthRoute path={forgotPasswordRoute} component={ForgotPassword} />
              <AuthRoute path={resetPasswordRoute} component={ResetPassword} />
              <AuthRoute path={signupRoute} component={Signup} />
              <Route exact path={artistSearchRoute} component={ArtistSearch} />

              <Route exact path={artworkSearchRoute} component={ArtworkSearch} />
              <MemberRoute path={settingsRoute} component={SettingsPage} />
              <Route path={faqRoute} component={FaqPage} />
              <Route path={aboutRoute} component={AboutPage} />
              <Route path={donateRoute} component={Donate} />
              <Route path={guestSelectionsDetailRoute} component={GuestSelectionsDetailPageContainer} />
              <Route exact path={guestCuratorsRoute} component={GuestSelectionsPage} />
              <Route path={termsRoute} component={TermsPage} />
              <Route path={privacyRoute} component={PrivacyPage} />
              <Route path={prizeRulesRoute} component={PrizeRulesPage} />
              <Route path={prizeRoute} component={PrizePage} />
              <Switch>
                <Route path={dialoguesDetailRoute} component={DialoguesDetailPage} />
                <Route path={dialoguesRoute} component={DialoguesPage} />
              </Switch>

              <Switch>
                <MemberRoute exact path={savedArtistsRoute} component={SavedArtists} />
                <ArtistRoute path={editArtistRouteFn(userSlug)} component={EditArtistWrapper} />
                <AuthRoute path={subscriptionRoute} component={CreateArtistMembership} />
                <ArtistRoute path={welcomeRoute} component={Welcome} />
                <Route exact path={artistDetailRoute} component={ArtistDetail} />
              </Switch>
              {/* Handle legacy artist detail page routing */}
              <Route
                exact
                path={`${artistDetailRoute}/works`}
                render={({ match }) => <Redirect to={`${artistDetailRouteFn(match.params.slug)}`} />}
              />
              <Route
                exact
                path={`${artistDetailRoute}/cv`}
                render={({ match }) => <Redirect to={`${artistDetailRouteFn(match.params.slug)}`} />}
              />
              <Route
                exact
                path={`${artistDetailRoute}/statement`}
                render={({ match }) => <Redirect to={`${artistDetailRouteFn(match.params.slug)}`} />}
              />
            </Layout>
          </App>
        </SuspendedUserListener>
      </LoggedInUserContext.Provider>
    </Router>
  )
}

const mapStateToProps = ({ session }) => {
  const { userSlug, showReactivationModal } = session
  return { userSlug, showReactivationModal }
}

const mapDispatchToProps = (dispatch) => {
  const { closeReactivationModal } = actions
  return {
    closeReactivationModal: () => dispatch(closeReactivationModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)

const MemberRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      CurrentUser.token() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: loginRoute,
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

const ArtistRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      CurrentUser.isArtist() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: homeRoute,
          }}
        />
      )
    }
  />
)

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      CurrentUser.token() ? <Redirect to={{ pathname: homeRoute }} /> : <Component {...props} />
    }
  />
)
