import React from "react"
import {func, object, string, bool} from "prop-types"
import styles from "./profile-image-option"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const ProfileImageOption = (
  {
    title,
    primaryImage,
    selected,
    handleClick
  }
) =>
  <button onClick={handleClick} className={cx("container")}>
    <div className={cx(["flex", `${selected ? "selected" : ""}`])}>
      <div className={cx("img-container")}>
        <img className={cx("")} alt={title} src={primaryImage.thumb}/>
      </div>
      <div className={cx("title-container")}>
        {selected}
        <p className={cx("title")}>{title}</p>
      </div>
    </div>
  </button>

ProfileImageOption.propTypes = {
  title: string.isRequired,
  handleClick: func.isRequired,
  primaryImage: object.isRequired,
  selected: bool.isRequired,
}

export default ProfileImageOption
