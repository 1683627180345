import React, { Component } from "react"
import { connect } from "react-redux"
import ArtistDetailSubheaderActions from "../../../components/ArtistDetailSubheaderActions"
import { actions } from "../../../actions"
import { sendMessage } from "../../../services/artist"
import sendEvent from "../../../services/tracker"

class ArtistDetailSubheaderActionsContainer extends Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.toggleLoginModalOpen = this.toggleLoginModalOpen.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.resetContactForm()
  }

  submitForm() {
    const { subject, message, name, email, handleSubmit, slug } = this.props
    handleSubmit({ subject, message, name, email, slug })
  }

  toggleLoginModalOpen = () => {
    this.lockModalBackground()
    this.props.toggleLoginModal()
  }

  lockModalBackground() {
    // Remove scrolling on html
    if (this.props.open || this.props.openLoginModal) {
      document.querySelector("html").classList.remove("no-scroll")
    } else {
      document.querySelector("html").classList.add("no-scroll")
    }
  }

  render() {
    return (
      <ArtistDetailSubheaderActions
        {...this.props}
        toggleOpen={this.props.toggleOpen}
        handleSubmit={this.submitForm}
        toggleLoginModalOpen={this.toggleLoginModalOpen}
      />
    )
  }
}
const mapStateToProps = ({ contactForm, session, search, auth }) => {
  const { subject, message, name, email, open, inProgress, sent, error } = contactForm
  const { userId } = session
  const { order, page } = search
  const { openLoginModal } = auth
  return {
    subject,
    message,
    name,
    email,
    open,
    inProgress,
    sent,
    error,
    order: order.artists,
    page,
    userId,
    openLoginModal
  }
}

const mapDispatchToProps = dispatch => {
  const {
    toggleContactForm,
    updateContactFormField,
    beginContactFormProgress,
    endContactFormProgress,
    resetContactForm,
    toggleLoginModal
  } = actions

  return {
    toggleOpen: () => {
      dispatch(resetContactForm())
      dispatch(toggleContactForm())
    },
    toggleLoginModal: () => dispatch(toggleLoginModal()),
    resetContactForm: () => dispatch(resetContactForm()),
    handleSubmit: data => {
      dispatch(beginContactFormProgress())
      sendMessage(data, res => {
        const { err, response } = res
        if (err || !response.ok) {
          dispatch(
            endContactFormProgress({
              error: "Something went wrong"
            })
          )
          return
        } else {
          sendEvent("send", "event", {
            eventCategory: "Button",
            eventAction: "click",
            eventLabel: "Contact Form Submit",
            artistSlug: data.slug
          })
          dispatch(updateContactFormField({ sent: true }))
          setTimeout(() => {
            dispatch(toggleContactForm())
          }, 1000)
        }
      })
    },
    handleChange: e =>
      dispatch(updateContactFormField({ [e.target.name]: e.target.value }))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistDetailSubheaderActionsContainer)
