import React from "react"
import classNames from "classnames/bind"
import styles from "./signup-note"

const cx = classNames.bind(styles)

export default ({ subtitle, copy }) => (
  <div className={cx("container")}>
    <h6 className={cx("subtitle")}>{subtitle}</h6>
    <div className={cx("copy")} dangerouslySetInnerHTML={{ __html: copy }} />
  </div>
)
