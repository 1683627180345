import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

interface TextBlockProps {
  $textAlign?: "left" | "center" | "right | justify"
}

const TextBlock = styled.div<TextBlockProps>`
  ${globalStylesCss};

  color: var(--font-color-secondary);
  font-size: calc(0.9375 * var(--font-size-primary));
  text-align: ${({ $textAlign }) => $textAlign || "left"};
  margin: 0 auto;
  max-width: 564px;

  &:not(:first-of-type) {
    margin-top: calc(2 * var(--font-size-primary));
  }

  & li {
    margin: 1em 0;
  }

  & ul {
    padding-left: 1em;
  }
`

export default TextBlock
