import { types } from "../actions"

const cms = (
  state = {
    cv: "",
    statement: "",
    unpublishedNoticeClosed: false,
    saving: false,
    error: null
  },
  { type, payload }
) => {
  switch (type) {
    case types.UPDATE_CV_FORM:
      return {
        ...state,
        cv: payload
      }
    case types.UPDATE_ARTIST_STATEMENT_FORM:
      return {
        ...state,
        statement: payload
      }
    case types.BEGIN_CMS_MUTATION:
      return {
        ...state,
        saving: true,
        error: null
      }
    case types.END_CMS_MUTATION:
      return {
        ...state,
        saving: false,
        error: payload.error
      }
    case types.CLOSE_UNPUBLISHED_NOTICE:
      return {
        ...state,
        unpublishedNoticeClosed: true
      }
    default:
      return state
  }
}

export default cms
