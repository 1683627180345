import React, { Component } from "react"
import ReactMarkdown from "react-markdown"

import styles from "./faq"
import classNames from "classnames/bind"
import Helmet from "../Helmet"

const cx = classNames.bind(styles)

export default ({ sections, title }) => (
  <div className={cx("faq")}>
    <Helmet title={`Foundwork | FAQ`} />
    <nav className={cx("menu")}>
      <div className={cx("menuInner")}>
        <h1>{title}</h1>
        {sections.map(section => (
          <MenuItem {...section} key={section.id} />
        ))}
      </div>
    </nav>
    <div className={cx("questionGroups")}>
      {sections.map(section => (
        <QuestionGroup {...section} key={section.id} />
      ))}
    </div>
  </div>
)

const MenuItem = ({ slug, title }) => <a href={`#${slug}`}>{title}</a>

const QuestionGroup = ({ slug, title, sections }) => (
  <div id={slug} className={cx("questionGroup")}>
    <h2>{title}</h2>
    <dl>
      {sections.map(section => (
        <QuestionAnswer key={section.id} {...section} />
      ))}
    </dl>
  </div>
)

class QuestionAnswer extends Component {
  constructor(props) {
    super(props)
    // On initialization for mobile, the first GuestCard renders in expanded state and the rest are collapsed
    this.state = {
      expanded: false
    }
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { title, content } = this.props
    return (
      <div>
        <dt>
          <button onClick={this.toggleExpanded}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M.93 8L8 15.07 15.072 8" stroke="#4A90E2" fill="none" />
            </svg>
            {title}
          </button>
        </dt>
        {this.state.expanded && (
          <dd>
            <ReactMarkdown children={content} />
          </dd>
        )}
      </div>
    )
  }
}
