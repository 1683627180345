import React, {useState} from "react"
import styles from "./billing-settings"
import classNames from "classnames/bind"
import ProfileFormHeading from "../ProfileFormHeading"
import Input from "../Input"

const cx = classNames.bind(styles)

const BillingSettings = (
  {
    user,
    onSave,
  },
) => {
  const [isSaving, setIsSaving] = useState(false)

  const [billingAddress, setBillingAddress] = useState(user.billingAddress)
  const [vatId, setVatId] = useState(user.vatId)

  const handleSubmit = async (event) => {
    event.preventDefault()

    setIsSaving(true)
    await onSave({billingAddress, vatId})
    setIsSaving(false)
  }

  return (
    <div className={cx("container")}>
      <form onSubmit={handleSubmit}>
        <ProfileFormHeading
          label={"Billing Information"}
          saving={isSaving}
          buttonText={"Save"}
          buttonStyle={"gray"}
        />
        <div className={cx("message")}>
          The following details will be included on your invoices if you add them below.
        </div>
        <div className={cx("inputs-container")}>
          <Input
            id="billingAddress"
            name="billingAddress"
            type="textarea"
            value={billingAddress}
            handleChange={ev => setBillingAddress(ev.target.value)}
            label="Billing Address"
            required={false}
          />
          <Input
            id="vatId"
            name="vatId"
            type="text"
            value={vatId}
            handleChange={ev => setVatId(ev.target.value)}
            label="VAT ID"
            required={false}
          />
        </div>
      </form>
    </div>
  )
}

export default BillingSettings
