import React from "react"
import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import { forTabletLandscapeUp } from "@/ui/styles/breakpoints"

interface PageSectionProps {
  children: React.ReactNode
}

const WideContainer = styled.div`
  ${globalStylesCss};

  display: flex;
  justify-content: center;

  padding: calc(4 * var(--margin-primary)) var(--page-minimal-gutter);

  &:nth-child(even) {
    background-color: var(--color-gray-4);
  }
  
  @media screen and (${forTabletLandscapeUp}) {
    padding-inline: var(--page-gutter);
  }
`

const InnerContainer = styled.div`
  ${globalStylesCss};

  max-width: var(--inner-width);
  min-width: 0;
`

const PageSection = ({ children }: PageSectionProps) => (
  <WideContainer>
    <InnerContainer>
      {children}
    </InnerContainer>
  </WideContainer>
)

export default PageSection
