import { actions } from "../actions"
import { serializeForm } from "../utils.js"

export const addImageFiles = files => {
  return actions.addArtworkImages(files)
}

export const removeImageFile = index => {
  return actions.removeArtworkImage(index)
}

export const removePrimaryImage = () => {
  return actions.removeArtworkPrimaryImage()
}

export const addVideo = payload => {
  return actions.addArtworkVideo(payload)
}

export const removeVideo = () => {
  return actions.removeArtworkVideo()
}

export const resetForm = () => {
  return actions.resetArtworkForm()
}

export const closeForm = () => {
  return actions.closeArtworkForm()
}

export const beginSave = () => {
  return actions.beginArtworkSave()
}

export const finishSave = () => {
  return actions.finishArtworkSave()
}

export const failSave = ({ error }) => {
  return actions.failArtworkSave({
    error: error.message ? error.message : error
  })
}

export const formatFormData = ({
  form,
  formats,
  categories,
  imageFiles,
  primaryImage,
  videoLink,
  id
}) => {
  const data = serializeForm(form)
  // Format dimensions into an array
  const { height, width, depth } = data
  if (height && width) {
    data.dimensions = [height, width]
    if (depth) {
      data.dimensions = [height, width, depth]
    }
  }
  // Add 'formats' to data
  data.formats = formats
  data.categories = categories
  data.videoLink = videoLink

  if (id) {
    data.id = id
  }

  if (!primaryImage && !data.videoLink) {
    data.primaryImageBase64 = imageFiles[0]
  }
  data.images = imageFiles

  return data
}

const findMissingProps = (data, primaryImage) => {
  // Check for required fields
  const { videoLink } = data
  const validVideo =
    videoLink &&
    (videoLink.includes("youtube.com") ||
      videoLink.includes("youtu.be") ||
      videoLink.includes("vimeo.com"))
  let requiredFields = ["formats", "year"]
  if (!primaryImage && !validVideo) {
    requiredFields.push("primaryImageBase64")
  }

  return requiredFields.filter((prop, index) => {
    return !data[prop] || data[prop].length < 1
  })
}

export const validateForm = (data, primaryImage) => {
  const missingProps = findMissingProps(data, primaryImage)

  if (missingProps.length > 0) {
    const missingProp =
      missingProps[0] === "primaryImageBase64"
        ? "image or valid video link"
        : missingProps[0]
    return `Artwork '${missingProp}' is required`
  }
}

export const editArtwork = artwork => {
  const formats = artwork.formats.map(format => format.id)
  const categories = artwork.categories.map(category => category.id)
  return actions.beginEditingArtwork({
    ...artwork,
    formats,
    categories
  })
}

export const addFormat = format => {
  return actions.addArtworkFormat(format)
}

export const removeFormat = index => {
  return actions.removeArtworkFormat(index)
}

export const addCategory = category => {
  return actions.addArtworkCategory(category)
}

export const removeCategory = index => {
  return actions.removeArtworkCategory(index)
}
