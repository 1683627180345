import { combineReducers, compose, createStore, applyMiddleware } from "redux"
import session from "./session"
import auth from "./auth"
import authForm from "./authForm"
import artworkForm from "./artworkForm"
import artworksListPage from "./artworksListPage"
import artworkExplore from "./artworkExplore"
import cms from "./cms"
import contactForm from "./contactForm"
import schoolForm from "./schoolForm"
import funnel from "./funnel"
import profile from "./profile"
import search from "./search"
import section from "./section"
import settings from "./settings"
import profileImageForm from "./profileImageForm"
import header from "./header"
import artistDetail from "./artistDetail"
import { reducer as burgerMenu } from "redux-burger-menu"
import { connectRouter, routerMiddleware } from "connected-react-router"
import history from "../fwHistory"
// import { composeWithDevTools } from "redux-devtools-extension"
import { client } from "../services/apolloEnv"

// Combine reducers from adjacent files here
const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    session,
    auth,
    authForm,
    schoolForm,
    artworkForm,
    artworksListPage,
    artworkExplore,
    cms,
    contactForm,
    funnel,
    profile,
    section,
    search,
    settings,
    profileImageForm,
    header,
    artistDetail,
    burgerMenu
    // apollo: client.reducer()
  })

export default createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history) // for dispatching history actions
    )
  )
)
