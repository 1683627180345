import React from "react"
import styles from "./artist-detail-subheader"
import classNames from "classnames/bind"
import ArtistDetailSubheaderActions from "../../containers/artist/ArtistDetailSubheaderActions"

const cx = classNames.bind(styles)

const ArtistDetailSubheader = ({
  slug,
  savedByUser,
  cv,
  statement,
  artworks,
  toggleArtistDetailTab,
  activeTab
}) => (
  <div className={cx("container")}>
    <div className={cx("inner-container")}>
      <div className={cx("link-container")}>
        {!!artworks.length && (
          <button
            className={cx({ active: activeTab === "works" })}
            onClick={() => toggleArtistDetailTab("works")}
          >
            Works
          </button>
        )}
        {cv && (
          <button
            className={cx({ active: activeTab === "cv" })}
            onClick={() => toggleArtistDetailTab("cv")}
          >
            CV
          </button>
        )}
        {statement && (
          <button
            className={cx({ active: activeTab === "statement" })}
            onClick={() => toggleArtistDetailTab("statement")}
          >
            Artist Statement
          </button>
        )}
      </div>
      <ArtistDetailSubheaderActions slug={slug} savedByUser={savedByUser} />
    </div>
  </div>
)

export default ArtistDetailSubheader
