import React from "react"
import classNames from "classnames/bind"
import styles from "./carousel"
import { SwiperPrev, SwiperNext } from "./Buttons"
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper-bundle.css"

SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

const cx = classNames.bind(styles)

const Carousel = ({ children }) => {
  return (
    <div className={cx("carousel")}>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false
        }}
        loop={true}
        navigation={{
          prevEl: ".swiper-btn-prev",
          nextEl: ".swiper-btn-next"
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true
        }}
      >
        {children.map((Child, i) => (
          <SwiperSlide key={`slide-${i}`}>{Child}</SwiperSlide>
        ))}
        {children.length > 1 && (
          <React.Fragment>
            <SwiperPrev className="swiper-btn swiper-btn-prev" />
            <SwiperNext className="swiper-btn swiper-btn-next" />
            <div className="swiper-pagination"></div>
          </React.Fragment>
        )}
      </Swiper>
    </div>
  )
}

export default Carousel
