import couponValidator from "./couponValidator"
import { useState } from "react"
import debounce from "lodash/debounce"
import { Coupon } from "./Coupon"

const useCouponValidation = (
  onCouponValidated: (coupon: Coupon | undefined) => void
): [boolean | undefined, (code: string) => void] => {
  const [validationStatus, setValidationStatus] = useState<boolean | undefined>(undefined)

  const validateCoupon = debounce(
    async (code: string) => {
      const validatedCoupon = await couponValidator.validate(code)
      setValidationStatus(validatedCoupon ? validatedCoupon.isValid : undefined)

      onCouponValidated(validatedCoupon)
    },
    300,
    { leading: false, trailing: true }
  )

  return [validationStatus, validateCoupon]
}

export default useCouponValidation
