import React from "react"
import styles from "./footer"
import classNames from "classnames/bind"
import { Link } from "react-router-dom"

const cx = classNames.bind(styles)

const Footer = ({ isCms, fixedFooter }) => (
  <div className={cx({ container: true, isCms, fixedFooter })}>
    <div className={cx("inner")}>
      <div className={cx("links")}>
        <Link to="/about">About</Link>
        <Link to="/faq">FAQ</Link>
        <Link to="/donate">Donate</Link>
        <a href="//www.instagram.com/foundwork.art" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
        <Link to="/terms">Terms</Link>
        <Link to="/privacy">Privacy</Link>
      </div>
      <div className={cx("copyright")}>&copy;{new Date().getFullYear()} Foundwork</div>
    </div>
  </div>
)

export default Footer
