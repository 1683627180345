import styled from "styled-components"
import Button from "@/ui/elements/Button"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

const Form = styled.div`
  ${globalStylesCss};
  
  display: flex;
  flex-direction: column;
  gap: var(--padding-primary);
  max-width: var(--form-width);
`

export default Form

export const FormButton = styled(Button)`
  margin: calc(var(--line-height-default) * 1em) 0;
`
