import { types } from "../actions"

const profileImageForm = (
  state = {
    isOpen: false,
    selectedArtworkId: null
  },
  { type, payload }
) => {
  switch (type) {
    case types.TOGGLE_PROFILE_IMAGE_FORM:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    case types.CLOSE_PROFILE_IMAGE_FORM:
      return {
        ...state,
        isOpen: false
      }
    case types.SELECT_PROFILE_IMAGE:
      return {
        ...state,
        selectedArtworkId: payload
      }
    default:
      return state
  }
}

export default profileImageForm
