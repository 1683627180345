import React, { Component } from "react"
import { connect } from "react-redux"
import MembershipSettings from "../../../components/MembershipSettings"

class MembershipSettingsContainer extends Component {
  render() {
    return <MembershipSettings {...this.props} />
  }
}

const mapStateToProps = ({ session: { isArtist } }) => ({ isArtist })

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipSettingsContainer)
