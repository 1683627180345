import { createGlobalStyle, css } from "styled-components"

// TODO This is a workaround to prevent injecting global styles that will clash with the existing CSS code. If an when
// the migration is done, we should remove injecting this CSS snippets into every components and just streamline it as
// global rules
export const resetCss = css`  
  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background-color: var(--background-color-primary);
  }
`

const Reset = createGlobalStyle`${resetCss}`

export default Reset
