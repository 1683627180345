// TODO Reindent this file

import React, { Component } from "react"
import { connect } from "react-redux"
import { number, string, array, func } from "prop-types"
import styles from "./artwork-form"
import classNames from "classnames/bind"

import {
  addImageFiles,
  removeImageFile,
  removePrimaryImage,
  removeVideo,
  resetForm,
  closeForm,
  beginSave,
  finishSave,
  failSave,
  formatFormData,
  validateForm,
  addFormat,
  removeFormat,
  addCategory,
  removeCategory,
  addVideo
} from "../../../services/artworkForm"
import { getYears } from "../../../utils"
import Dropzone from "react-dropzone"
import Logo from "../../../components/Logo"
import Input from "../../../components/Input"
import Dropdown from "../../../components/Dropdown"
import SelectedTags from "../../../components/SelectedTags"
import Video from "../../../components/Video"

const cx = classNames.bind(styles)

const ArtworkForm = ({
  // From form wrapper
  allFormats,
  allCategories,

  // From artworkForm state slice
  formats,
  categories,
  primaryImage,
  imageFiles,
  error,
  saving,

  // From artworkFrom.editingArtwork state slice
  id,
  title,
  medium,
  year,
  dimensions,
  videoLink,
  videoDurationMinutes,
  videoDurationSeconds,
  additionalInformation,

  // From session state slice
  userId,

  // From mapDispatchToProps
  handleDropError,
  handleFiles,
  handleChange,
  onRemoveFile,
  onRemoveVideo,
  addFormat,
  removeFormat,
  addCategory,
  removeCategory,
  closeModal,
  finishSave,
  beginSave,
  failSave,
  editingArtwork,

  // External handler
  onArtworkSave,
}) => {
  const handleSubmit = form => {
    const data = formatFormData({
      form,
      formats,
      categories,
      imageFiles,
      primaryImage,
      videoLink,
      id
    })

    beginSave()

    const formError = validateForm(data, primaryImage)

    if (formError) {
      failSave({ error: { message: formError } })
      return
    }

    // TODO
    // Here we have editingArtwork passed down from the state, but we send back
    // this `data` which is a stripped down version of the model with some
    // fields in a different type (e.g. year is a string instead of a number).
    // I'm toying with the idea of sending back the updated editingArtwork, or
    // maybe the original editingArtwork and a diff structure with the things
    // tha were changed (this data is neither, as it contains all the fields the
    // form lets you edit, including those that didn't change)
    // For now I'm merging in the position in the list because I need it to keep
    // visual consistency in the parent list component.
    onArtworkSave(_.merge({ position: (editingArtwork ? editingArtwork.position : 0) }, data))

    closeModal()
    finishSave()
  }

  const imgSrc = primaryImage ? primaryImage.thumb : null
  const validVideo =
    videoLink &&
    (videoLink.includes("youtube.com") || videoLink.includes("youtu.be") || videoLink.includes("vimeo.com"))

  return (
    <div className={cx("form-modal")}>
      <div className={cx("container")}>
        <form
        id="artwork_form"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit(e.target)
        }}
      >
          <div className={cx("top")}>
            <Logo />
            <div className={cx("actions")}>
              <button
              className={cx(["close","btn", "btn-white"])}
              onClick={e => {
                e.preventDefault()
                closeModal()
              }}
            >
                Close
            </button>
            <button className={cx(saving ? "saving" : "", "btn")} type={"submit"}>
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>

        <div className={cx("error-message")}>{error}</div>
        <div className={cx("fields")}>
          <div className={cx("image-field")}>
            {imageFiles.length > 0 && (
              <div className={cx("files")}>
                {imageFiles.map((file, index) => (
                  <div className={cx("image-file")} key={index}>
                    <div className={cx("image-info")}>
                      <span className={cx("image-info-index")}>
                        {index + 1}
                      </span>
                      <button
                      className={cx("remove-button")}
                      onClick={e => {
                        e.preventDefault()
                        onRemoveFile(index)
                      }}
                    >
                        &times;
                    </button>
                  </div>
                  <div className={cx("image-wrapper")}>
                    {file && !file.image && (
                      <img src={file} alt={file.name} />
                    )}
                      {file.image && file.image.thumb && (
                        <img src={file.image.thumb} alt="Primary work" />
                      )}
                      </div>
                    </div>
                ))}
              </div>
            )}

            {validVideo && (
              <div className={cx("files")}>
                <div className={cx("image-file")}>
                  <div className={cx("image-info")}>
                    <button
                    className={cx("remove-button")}
                    onClick={e => {
                      e.preventDefault()
                      onRemoveVideo()
                    }}
                  >
                      &times;
                  </button>
                </div>

                <Video width="160px" height="90px" url={videoLink} />
              </div>
            </div>
            )}
                  {!validVideo && imageFiles.length < 6 && (
                    <div>
                      <p>
                        <b>Add Photos</b>
                      </p>
                      <Dropzone
                      multiple={true}
                      onDrop={handleFiles}
                      onDropRejected={handleDropError}
                      maxFiles={6}
                      maxSize={3e6}
                      accept="image/jpeg, image/png"
                    >
                        {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()}
                          className={cx("dropzone")}
                        >
                            <input {...getInputProps()} />
                            Click to add file(s)
                            <br />
                            Or drag and drop images here
                          </div>
                        )}
                        </Dropzone>
                      </div>
                  )}

                      {imageFiles && imageFiles.length === 0 && (
                        <div className={cx("video")}>
                          <div>
                            <p>
                              <b>Add Video (enter a link to your video on Vimeo or Youtube)</b>
                            </p>
                            <div className={cx("video-input-wrapper")}>
                              <Input
                              className={cx("video-input")}
                              name="videoLink"
                              placeholder={
                                "e.g. https://www.youtube.com/watch?v=C0DPdy98e4c"
                              }
                              label={""}
                              type="text"
                              value={videoLink || ""}
                              handleChange={handleChange}
                              short={true}
                            />
                                {videoLink && (
                                  <button
                                  className={cx("remove-button")}
                                  onClick={e => {
                                    e.preventDefault()
                                    onRemoveVideo()
                                  }}
                                >
                                    &times;
                                </button>
                                )}
                              </div>
                            </div>
                          </div>
                      )}

                          <div className={cx("guidelines")}>
                            <p>
                              <b>Image Guidelines</b>
                            </p>
                            <p>
                              - Image file type should be JPEG or PNG.
                              <br />
                              - Image should be at least 1200 pixels in length or width.
                              <br />- If your work isn't saving, try using a smaller image
                              file.
                            </p>
                          </div>
                        </div>
                        <div className={cx("text-fields")}>
                          <Dropdown
                          name="formats"
                          label="Formats* (add up to 3)"
                          options={allFormats.map(format => ({
                            name: format.name,
                            value: format.id
                          }))}
                          onChange={val => addFormat(val, formats)}
                          placeholder="Formats"
                          className={"tall"}
                          resets
                        >
                            {formats.length > 0 && (
                              <div className={cx("selected-tags")}>
                                <SelectedTags
                                tags={formats.map((format, index) => ({
                                  name: allFormats.find(f => f.id === format.toString())
                                  .name
                                }))}
                                onRemove={index => removeFormat(index)}
                              />
                                </div>
                            )}
                              </Dropdown>
                              <Dropdown
                              name="categories"
                              label="Attributes (add up to 5)"
                              options={allCategories.map(category => ({
                                name: category.name,
                                value: category.id
                              }))}
                              onChange={val => addCategory(val, categories)}
                              placeholder="Attributes"
                              className={"tall"}
                              resets
                            >
                                {categories.length > 0 && (
                                  <div className={cx("selected-tags")}>
                                    <SelectedTags
                                    tags={categories.map((category, index) => ({
                                      name: allCategories.find(
                                        c => c.id === category.toString()
                                      ).name
                                    }))}
                                    onRemove={index => removeCategory(index)}
                                  />
                                    </div>
                                )}
                                  </Dropdown>
                                  <Input
                                  name="title"
                                  placeholder={"Title"}
                                  label={"Title"}
                                  type="text"
                                  defaultValue={title}
                                  short={true}
                                />
                                    <label htmlFor={"year"}>Date*</label>

                                    <select name="year" defaultValue={year}>
                                      <option value={""}>Year</option>
                                      {getYears(68).map((val, i) => (
                                        <option key={i}>{val}</option>
                                      ))}
                                      </select>
                                      <Input
                                      name="medium"
                                      placeholder={"Medium"}
                                      label={"Medium"}
                                      type="text"
                                      defaultValue={medium}
                                      short={true}
                                    />
                                        <div className={cx("dimensions")}>
                                          <div className={cx("dimension")}>
                                            <Input
                                            label="Dimensions (in.)"
                                            name="height"
                                            placeholder={"Height"}
                                            type="number"
                                            step="0.01"
                                            defaultValue={dimensions ? dimensions[0] : ""}
                                            short={true}
                                          />
                                            </div>
                                            <div className={cx("dimension")}>
                                              <Input
                                              name="width"
                                              placeholder={"Width"}
                                              type="number"
                                              step="0.01"
                                              label="&nbsp;"
                                              defaultValue={dimensions ? dimensions[1] : ""}
                                              short={true}
                                            />
                                              </div>
                                              <div className={cx("dimension")}>
                                                <Input
                                                label="&nbsp;"
                                                name="depth"
                                                placeholder={"Depth"}
                                                type="number"
                                                step="0.01"
                                                defaultValue={dimensions ? dimensions[2] : ""}
                                                short={true}
                                              />
                                                </div>
                                              </div>
                                              <div className={cx("durations")}>
                                                <div className={cx("duration")}>
                                                  <Input
                                                  label="Duration (for video)"
                                                  name="videoDurationMinutes"
                                                  placeholder={"Minutes"}
                                                  defaultValue={videoDurationMinutes}
                                                  type="number"
                                                  step="1"
                                                  short={true}
                                                />
                                                  </div>
                                                  <div className={cx("duration")}>
                                                    <Input
                                                    label="&nbsp;"
                                                    name="videoDurationSeconds"
                                                    placeholder={"Seconds"}
                                                    defaultValue={videoDurationSeconds}
                                                    type="number"
                                                    step="1"
                                                    short={true}
                                                  />
                                                    </div>
                                                    <div className={cx("duration")}></div>
                                                  </div>
                                                  <Input
                                                  name="additionalInformation"
                                                  placeholder={"Additional Information"}
                                                  label={"Additional Information"}
                                                  defaultValue={additionalInformation}
                                                  short={true}
                                                  type="text"
                                                />
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
  )
}

ArtworkForm.propType = {
  currentImages: array,
  imageFiles: array,
  formats: array,
  dimensions: array,
  id: string,
  title: string,
  medium: string,
  year: number,
  videoLink: string,
  videoDurationMinutes: number,
  videoDurationSeconds: number,
  additionalInformation: string,
  error: string,
  onSubmit: func.isRequired,
  handleFiles: func.isRequired,
  handleDropError: func.isRequired,
  onRemoveFile: func.isRequired,
  onRemovePrimaryImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  addFormat: func.isRequired,
  removeFormat: func.isRequired,
  closeModal: func,
  onArtworkSave: func.isRequired,
}

const mapStateToProps = ({ artworkForm, session }) => {
  const {
    formats,
    categories,
    primaryImage,
    imageFiles,
    error,
    saving,
    editingArtwork
  } = artworkForm

  const {
    id,
    title,
    medium,
    year,
    dimensions,
    videoLink,
    videoDurationMinutes,
    videoDurationSeconds,
    additionalInformation
  } = editingArtwork || {}

  const { userId } = session

  return {
    userId,
    imageFiles:
    (editingArtwork ? editingArtwork.artworkImages : imageFiles) || [],
    formats: formats || [],
    categories: categories || [],
    id,
    title,
    medium,
    year,
    dimensions,
    primaryImage,
    videoLink,
    videoDurationMinutes,
    videoDurationSeconds,
    additionalInformation,
    editingArtwork,
    error,
    saving,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    beginSave: () => dispatch(beginSave()),
    finishSave: () => dispatch(finishSave()),
    failSave: (response) => dispatch(failSave({ error: response.error })),
    handleFiles(files) {
      files.forEach(f => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(f)
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result)
            } else {
              reject(Error("Failed converting to base64"))
            }
          }
        })
        promise.then(
          result => {
            dispatch(addImageFiles([result]))
          },
          err => {
            failSave({ error: err })
          }
        )
      })
    },
    handleDropError: () => dispatch(
      failSave({
        error:
        "Failed to attach image. Image file must be a JPEG or PNG and no more than 3MB."
      })
    ),
    onRemoveFile: index => {
      dispatch(removeImageFile(index))
    },
    onRemovePrimaryImage: () => {
      dispatch(removePrimaryImage())
    },
    onRemoveVideo: () => {
      dispatch(removeVideo())
    },
    addFormat: (format, selected) => {
      if (!format || selected.length === 3) {
        return
      }
      dispatch(addFormat(format))
    },
    removeFormat: index => {
      dispatch(removeFormat(index))
    },
    addCategory: (category, selected) => {
      if (!category || selected.length === 5) {
        return
      }
      dispatch(addCategory(category))
    },
    removeCategory: index => {
      dispatch(removeCategory(index))
    },
    closeModal: () => {
      dispatch(resetForm())
      dispatch(closeForm())
    },
    handleChange: e => {
      dispatch(addVideo(e.target.value))
    },
    hideSavePopup: (response) => {
      dispatch(hideSavePopup())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtworkForm)
