// I'm sorry for the state of this page. The intention was to collect all data
// changes in here in order to be able to refactor things with some proper
// encapsulation. On the way things got more messy though and now I don't have
// time to work on refactoring the mess.
// If I ever come back here I'll try to make it better but for now it'll have to
// live as it is.
import React, { useEffect } from "react"
import { connect } from "react-redux"
import styles from "./works"
import classNames from "classnames/bind"
import { bool, func } from "prop-types"
import { useQuery } from "@apollo/client"
import Header from "../../../containers/cms/Header"
import SortableArtworkList from "../../../containers/cms/SortableArtworkList"
import ArtworkFormWrapper from "../../../containers/cms/ArtworkFormWrapper"
import ArtworkList from "../../../components/ArtworkList"
import ArtworkListItem from "../../../components/ArtworkListItem"
import { actions } from "../../../actions"
import {
  WorksQuery,
  WorksSubscription,
  CreateArtwork,
  UpdateArtwork,
  PublishArtwork,
  DeleteArtwork,
  ReorderArtwork,
} from "../../../queries/works"
import { useMutation, useSubscription, gql } from "@apollo/client"
import { editArtwork } from "../../../services/artworkForm"
import sizeof from "object-sizeof"
import { client } from "../../../services/apolloEnv"
import { estimateUploadSpeed } from "../../../utils"

const cx = classNames.bind(styles)

const Works = ({
  formIsOpen,
  openModal,
  onEdit,
  userId,
  userArtworks,
  updateArtworkList,
  partiallyUpdateUserArtwork,
  removeUserArtworkFromList,
  changeArtworkPosition,
}) => {
  useQuery(WorksQuery, {
    variables: { id: userId },
    onCompleted: ({ user: { artworks } }) => {
      updateArtworkList(artworks)
    }
  })

  useSubscription(WorksSubscription, {
    variables: { id: userId },
    onSubscriptionData: ({ subscriptionData: { data: { userArtworkUpdated } } }) => {
      updateArtworkList(userArtworkUpdated ? [userArtworkUpdated] : [])
    }
  })

  // From list component

  const [publishMutation] = useMutation(PublishArtwork)

  const handlePublish = (id, published) => {
    publishMutation({
      variables: { id, published },
      refetchQueries: [
        {
          query: WorksQuery,
          variables: { id: userId },
        },
      ],
    }).catch((error) => {
      console.error("Error publishing artwork: ", error)
    })

    partiallyUpdateUserArtwork({ id, published })
  }

  const [deleteMutation] = useMutation(DeleteArtwork)

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to remove this artwork?")) {
      deleteMutation({
        variables: { id },
        refetchQueries: [
          {
            query: WorksQuery,
            variables: { id: userId },
          },
        ],
      }).catch((error) => {
        console.error("Error deleting artwork: ", error)
        return
      })

      removeUserArtworkFromList(id)
    }
  }

  const [reorderMutation] = useMutation(ReorderArtwork)

  const handleReorder = (artwork, newPosition) => {
    reorderMutation({
      variables: { id: artwork.id, position: newPosition + 1 }, // NOTE Position in the backend is 1-based
      refetchQueries: [
        {
          query: WorksQuery,
          variables: { id: userId },
        },
      ],
    }).catch((error) => {
      console.error("Error reordering artwork: ", error)
    })

    changeArtworkPosition(artwork, newPosition)
  }

  // From artwork form

  const [createArtwork] = useMutation(CreateArtwork)
  const [updateArtwork] = useMutation(UpdateArtwork)

  const handleArtworkSave = (artwork) => {
    // TODO needs refactoring
    // images to base64
    var imgs = []
    var idx = 0
    const p = new Promise((reslv, rej) => {
      if (!artwork.images || artwork.images.length === 0) reslv(null)
      artwork.images.forEach((f, i) => {
        const promise = new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest()
          xhr.onload = function() {
            var reader = new FileReader()
            reader.onloadend = function() {
              if (!!reader.result) {
                var res = reader.result
                res = res.replace("text/plain", "image/jpeg")
                resolve(res)
              } else {
                reject(Error("Failed converting to base64"))
              }
            }
            reader.readAsDataURL(xhr.response)
          }
          // If image is an image obj
          // then load the URL
          // else it's already in base64, so do nothing
          if (f.image && f.image.large) {
            // TODO Let's fix this?
            var url = `${process.env.NODE_ENV === 'development' ? '' : 'https://'}${f.image.large}`
            url += "?" + new Date().getTime()
            xhr.open("GET", url)
            xhr.responseType = "blob"
            xhr.send()
          } else {
            resolve(f)
          }
        })
        promise.then(
          result => {
            imgs[i] = result
            idx++
            if (idx === artwork.images.length) {
              reslv(imgs)
            }
          },
          err => {
            rej(err)
          }
        )
      })
    })

    p.then(
      result => {
        artwork.images = result

        // TODO I should store this fragment somewhere and reuse it across
        // read, create and update queries
        // NOTE There's a nice issue if I move this to another file. For some
        // reason I start to get errors when running the Apollo cache update
        // below. Go figure
        const fragment = gql`
          fragment UserArtwork on User {
            artworks(includeNotReady: true) {
              id
              title
              formats {
                id
                name
              }
              categories {
                id
                name
              }
              medium
              year
              dimensions
              primaryImage {
                thumb
                large
              }
              artworkImages {
                image {
                  thumb
                  large
                }
                sort
              }
              published
              readyToShow
              additionalInformation
              videoLink
              videoDurationMinutes
              videoDurationSeconds
              position
            }
          }
        `

        // TODO Make it so that the artwork passed to this handler better
        // resembles a GraphQL type and not just a bunch of data we need to
        // manipulate here.
        // NOTE When some fields are null everything seems to fall apart, i.e.
        // the cache doesn't return. We need to have both somehow: null fields
        // to (not) send to the backend, and some default values to make
        // optimistic UI work. How to do this?
        const prepareOptimisticResponse = (artwork) => {
          return {
            __typename: "Artwork",
            id: artwork.id || `${Math.round(Math.random() * -100000)}`,
            title: artwork.title,
            formats: artwork.formats.map((id) => {
              return { __typename: "Format", id: id, name: "Undefined" }
            }),
            categories: artwork.categories.map((id) => {
              return { __typename: "Category", id: id, name: "Undefined" }
            }),
            year: parseInt(artwork.year, 10),
            dimensions: (artwork.dimensions || []).map((dim) => parseInt(dim, 10)),
            medium: artwork.medium,
            primaryImage: {
              __typename: "Image",
              thumb: (artwork.primaryImageBase64 || ""),
              large: (artwork.primaryImageBase64 || ""),
            },
            artworkImages: (artwork.images || []).map((img, index) => {
              return {
                __typename: "ArtworkImage",
                image: {
                  __typename: "Image",
                  thumb: img,
                  large: img,
                },
                sort: index,
              }
            }),
            published: true,
            readyToShow: false,
            additionalInformation: artwork.additionalInformation,
            videoLink: artwork.videoLink || "",
            videoDurationMinutes: artwork.videoDurationMinutes,
            videoDurationSeconds: artwork.videoDurationSeconds,
            position: artwork.position || 1,
          }
        }

        if (artwork.id) {
          const updatedArtwork = artwork

          const optimisticResponse = prepareOptimisticResponse(updatedArtwork)

          updateArtwork({
            variables: {
              ...artwork
            },
            optimisticResponse: { updateArtwork: { artwork: optimisticResponse } },
            update: (cache, { data: { updateArtwork: { artwork } } }) => {
              const userArtworks = cache.readFragment({
                id: `User:${userId}`,
                fragment,
              })

              cache.writeFragment({
                id: `User:${userId}`,
                fragment,
                data: _.unionBy([artwork], userArtworks, _.property("id")),
              })

              partiallyUpdateUserArtwork(artwork)
            },
            context: { useWebsocket: true },
          }).then(res => {
            if (res.error) {
              // NOTE: This will need to be displayed differently in the artwork
              // list page
              console.log(`failSave(${res})`)
              return
            }
          })
        } else {
          const addedArtwork = artwork

          const optimisticResponse = prepareOptimisticResponse(addedArtwork)

          let currentInProgressID = optimisticResponse.id

          createArtwork({
            variables: {
              userId,
              ...artwork
            },
            optimisticResponse: { createArtwork: { artwork: optimisticResponse } },
            update: (cache, { data: { createArtwork: { artwork } } }) => {
              const userArtworks = cache.readFragment({
                id: `User:${userId}`,
                fragment,
              })

              const newUserArtworks = {
                ...userArtworks,
                artworks: [artwork, ...userArtworks.artworks],
              }

              cache.writeFragment({
                id: `User:${userId}`,
                fragment,
                data: newUserArtworks,
              })

              partiallyUpdateUserArtwork(artwork)
            },
            context: { useWebsocket: true },
          }).then(res => {
            removeUserArtworkFromList(currentInProgressID)

            currentInProgressID = res.data.createArtwork.artwork.id

            if (res.error) {
              // NOTE: This will need to be displayed differently in the artwork
              // list page
              console.log(`failSave(${res})`)
              return
            }
          })

          estimateUploadSpeed()
            .then(uploadSpeed => {
              const TICK = 500 // ms
              const transferSize = sizeof(optimisticResponse) / 8 // B
              const estTotalTime = transferSize / uploadSpeed * 1.15 // Add 15% to account for processing delays
              const TOTAL_TICKS = Math.ceil(estTotalTime / (TICK * 1e-3))
              let countdown = TOTAL_TICKS

              const timerID = setInterval(
                () => {
                  const uploadProgress = (TOTAL_TICKS - countdown) / TOTAL_TICKS * 100

                  partiallyUpdateUserArtwork({ id: currentInProgressID, uploadProgress })

                  if (countdown === 0) {
                    partiallyUpdateUserArtwork({ id: currentInProgressID, uploadProgress: null })
                    clearInterval(timerID)
                    return
                  }

                  countdown--
                },
                TICK
              )
            })
        }
      },
      err => {
        // NOTE: This will need to be displayed differently in the artwork
        // list page
        console.log(`failSave(${res})`)
      }
    )
  }

  const isAnythingBeingProcessed = userArtworks.some(a => !a.readyToShow)

  return (
    <div className={cx("container")}>
      <Header title={"Works"} />
      <div className={cx("items")}>
        <div className={cx("header")}>
          <span className={cx("artwork-count")}>
            {`${userArtworks ? userArtworks.length : 0} Artworks`} {!isAnythingBeingProcessed && "(drag and drop works to reorder)"}
          </span>
          <button
            onClick={(e) => {
              e.preventDefault()
              openModal()
            }}
            className="btn btn-blue"
          >
            + Add a Work
          </button>
        </div>

        {userArtworks &&
            <SortableArtworkList
              userId={userId}
              artworks={userArtworks}
              onPublish={handlePublish}
              onDelete={handleDelete}
              onEdit={onEdit}
              onOrderChange={handleReorder}
              sortingDisabled={isAnythingBeingProcessed}
            />}

      </div>
      {formIsOpen && <ArtworkFormWrapper onArtworkSave={handleArtworkSave} />}
    </div>
  )
}

Works.propTypes = {
  formIsOpen: bool,
  openModal: func,
}

const mapStateToProps = (state) => {
  const { isOpen } = state.artworkForm
  const { userId } = state.session
  const { userArtworks } = state.artworksListPage
  return {
    userId,
    formIsOpen: isOpen,
    userArtworks,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: () => {
      dispatch(actions.openArtworkForm())
    },
    onEdit: (artwork) => {
      // Open modal with artwork to edit
      dispatch(editArtwork(artwork))
    },
    updateArtworkList: (artworks) => {
      dispatch(actions.updateUserArtworkList(artworks))
    },
    partiallyUpdateUserArtwork: (payload) => {
      dispatch(actions.partiallyUpdateUserArtwork(payload))
    },
    removeUserArtworkFromList: (id) => {
      dispatch(actions.removeUserArtworkFromList(id))
    },
    changeArtworkPosition: (artwork, position) => {
      dispatch(actions.changeArtworkPosition({ artwork, position }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Works)
