import { types } from "../actions"

const artworkExplore = (
  state = {
    error: "",
    shouldUpdate: true,
    previewIndex: null
  },
  { type, payload }
) => {
  switch (type) {
    case types.RECEIVE_EXPLORE_ARTWORK:
      return {
        ...state,
        error: "",
        shouldUpdate: false
      }
    case types.INVALIDATE_EXPLORE_ARTWORK:
      return {
        ...state,
        shouldUpdate: true
      }
    case types.OPEN_ARTWORK_PREVIEW:
      return {
        ...state,
        previewIndex: payload.index
      }
    case types.CLOSE_ARTWORK_PREVIEW:
      return {
        ...state,
        previewIndex: null
      }
    case types.INCREMENT_ARTWORK_PREVIEW:
      return {
        ...state,
        previewIndex: state.previewIndex + payload.increment
      }
    default:
      return state
  }
}

export default artworkExplore
