import Cookies from "js-cookie"
import { SignupResponse } from "@/api"

class UserSession {
  static save(data: SignupResponse) {
    Cookies.set("userData", JSON.stringify(data))
  }

  static token() {
    return this.get("token")
  }

  static id() {
    return this.get("user_id")
  }

  static slug() {
    return this.get("slug")
  }

  static role() {
    return this.get("role")
  }

  static get(key: string) {
    let rawData = Cookies.get("userData")
    let data = rawData ? JSON.parse(rawData) : {}
    return data[key]
  }

  static set(key: string, value: string) {
    let rawData = Cookies.get("userData")
    let data = rawData ? JSON.parse(rawData) : {}
    data[key] = value
    Cookies.set("userData", JSON.stringify(data))
  }

  static clear() {
    Cookies.remove("userData")
  }

  static isArtist() {
    return this.get("role") === "artist"
  }
}

export default UserSession
