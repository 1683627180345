import React from "react"
import { artistDetailRouteFn } from "../../constants"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "../Carousel/carousel"

const cx = classNames.bind(styles)

const ArtworkCarouselSlide = ({
  src,
  link,
  artist,
  title,
  year,
  medium,
  dimensions
}) => (
  <div className={cx("carousel-card")}>
    <div className={cx("card-content")}>
      <Link
        to={artistDetailRouteFn(link)}
        aria-label={artist}
        className={cx("display-wrapper")}
      >
        <img className={cx("display")} src={src} alt={title} />
      </Link>
      <div className={cx("caption")}>
        <p className={cx("artist")}>
          <Link to={artistDetailRouteFn(link)}>{artist}</Link>
        </p>
        <p className={cx("title-date")}>
          <span className={cx("title")}>{title || "Untitled"}</span>
          {", "}
          {year}
        </p>
        <p>
          {medium && <span className={cx("medium")}>{medium}</span>}
          {medium && dimensions && <span>{` `}</span>}
        </p>
        <p>
          {dimensions && <span className={cx("dimensions")}>{dimensions}</span>}
        </p>
      </div>
    </div>
  </div>
)

export default ArtworkCarouselSlide
