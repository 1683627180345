import React from "react"
import ReactMarkdown from "react-markdown"
import Helmet from "../Helmet"

import styles from "./privacy"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

export default ({ content, sections, title }) => (
  <div className={cx("privacy")}>
    <Helmet title={`Foundwork | Privacy Policy`} />
    <div className={cx("intro")}>
      <h1>{title}</h1>
    </div>
    <ReactMarkdown className={cx("content")} children={content} />
    <ol className={cx("sections")}>
      {sections.map(section => (
        <Section key={section.id} {...section} />
      ))}
    </ol>
  </div>
)

const Section = ({ id, title, content, sections }) => (
  <li key={id} className={cx("section")}>
    <span className={cx("title")}>{title}</span>
    <ReactMarkdown className={cx("content")} children={content} />
    <ol>
      {sections.map(section => (
        <Section key={section.id} {...section} />
      ))}
    </ol>
  </li>
)
