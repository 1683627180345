import React from "react"
import styles from "./login"
import classNames from "classnames/bind"
import LoginForm from "../../../containers/login/LoginForm"
import Helmet from "../../../components/Helmet"

const cx = classNames.bind(styles)

const Login = props => (
  <div className={cx("container")}>
    <Helmet title={`Foundwork | Log In`} />
    <LoginForm />
  </div>
)

export default Login
