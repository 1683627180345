import ApiClient from "../ApiClient.js"

export function getSection(slug, cb) {
  ApiClient.get(`/sections/${slug}`).end((err, response) => {
    if (!err && response) {
      const section = response.body.section
      cb({ err, section })
    } else {
      console.error(err)
      cb({ err, section: {} })
    }
  })
}
