import React, { Component } from "react"
import { connect } from "react-redux"
import { actions } from "../../../actions"
import { useMutation, gql } from "@apollo/client"
import PasswordUpdateForm from "../../../components/PasswordUpdateForm"

const ChangePassword = gql`
  mutation ChangePassword(
    $id: ID!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      id: $id
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`

const PasswordUpdateFormWrapper = ({
  id,
  toggleOpen,
  toggleSaving,
  currentPassword,
  newPassword,
  handleChange,
  error,
  saving,
}) => {
  const [mutate] = useMutation(ChangePassword)
  const changePassword = (e) => {
    e.preventDefault()

    toggleSaving()
    mutate({
      variables: {
        id,
        currentPassword,
        newPassword
      }
    })
      .then(res => {
        toggleSaving()
        toggleOpen()
      })
      .catch(e => {
        toggleSaving(e.graphQLErrors[0].message)
      })
  }

  return (
    <PasswordUpdateForm
      changePassword={changePassword}
      handleChange={handleChange}
      currentPassword={currentPassword}
      newPassword={newPassword}
      error={error}
      saving={saving}
    />
  )
}
const mapStateToProps = ({ session, settings }) => {
  const { userId: id } = session
  const {
    passwordInfo: { currentPassword, newPassword, saving, error }
  } = settings

  return { id, currentPassword, newPassword, saving, error }
}

const mapDispatchToProps = dispatch => {
  const { updatePasswordInfo, toggleSettingsSaving } = actions

  return {
    handleChange: e =>
      dispatch(updatePasswordInfo({ [e.target.name]: e.target.value })),
    toggleSaving: error =>
      dispatch(
        toggleSettingsSaving({
          kind: "passwordInfo",
          error
        })
      )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordUpdateFormWrapper)
