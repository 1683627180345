import React from "react"
import ReactMarkdown from "react-markdown"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./dialogues-section"
import slugify from "slugify"

const cx = classNames.bind(styles)

const createUrl = name => slugify(name, { remove: /[']/}).toLowerCase()
const dialogueUrlFor = (title) => `/dialogues/${createUrl(title)}`

export default ({ title, content, image }) => (
  <div className={cx("section")}>
    <div className={cx("details-container")}>
      <Link
        className={cx("header-link")}
        to={dialogueUrlFor(title)}
      >
        <h2>{title}</h2>
      </Link>

      <ReactMarkdown children={content} className={cx("content")} />

      <Link
        className={`btn btn-white ${cx("button")}`}
        to={dialogueUrlFor(title)}
      >
        Read Interview
      </Link>
    </div>
    <div className={cx("image-container")}>
      <Link to={dialogueUrlFor(title)}>
        <img alt={image.title} src={image.url} />
      </Link>
    </div>
  </div>
)
