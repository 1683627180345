import { types } from "../actions"

const defaultState = {
  year: null,
  degreeType: null,
  schoolId: null,
  error: null,
  saving: false,
  active: false
}

const schoolForm = (state = defaultState, { type, payload }) => {
  switch (type) {
    case types.OPEN_SCHOOL_FORM:
      return {
        ...state,
        active: true
      }
    case types.CLOSE_SCHOOL_FORM:
      return {
        ...state,
        active: false
      }
    case types.UPDATE_SCHOOL:
      return {
        ...state,
        ...payload
      }
    case types.BEGIN_SCHOOL_MUTATION:
      return {
        ...state,
        saving: true
      }
    case types.END_SCHOOL_MUTATION:
      return {
        ...state,
        saving: false,
        error: !!payload.error
      }
    case types.RESET_SCHOOL:
      return defaultState

    default:
      return state
  }
}

export default schoolForm
