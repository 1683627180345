// import { RAILS_ENV } from "./railsEnv.js.erb"

export const graphqlUrl = process.env.GRAPHQL_URL

export const websocketUrl = process.env.WEBSOCKET_URL

export const appUrl = process.env.APP_URL

export const stripeKey = process.env.STRIPE_PUBLISHABLE_KEY

const basicAuthEnabled = process.env.BASIC_AUTH_ENABLED == "true"
const basicAuthCredentials = `${process.env.HTTP_BASIC_USER}:${process.env.HTTP_BASIC_PASSWORD}`

export const basicAuth = {
  enabled: basicAuthEnabled,
  header: basicAuthEnabled ? `Basic ${btoa(basicAuthCredentials)}` : "",
}

export const paypalPlanWithTrialId = process.env.PAYPAL_PLAN_ID
