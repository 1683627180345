import React from "react"
import ArtistDetailHeader from "../../../components/ArtistDetailHeader"
import ArtistDetailSubheader from "../../../components/ArtistDetailSubheader"

const DetailHeader = ({ user, toggleArtistDetailTab, activeTab }) => (
  <div>
    <ArtistDetailHeader {...user} />
    <ArtistDetailSubheader
      {...user}
      activeTab={activeTab}
      toggleArtistDetailTab={toggleArtistDetailTab}
    />
  </div>
)

export default DetailHeader
