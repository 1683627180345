import React from "react"
import HeaderInner from "../HeaderInner"
import Logo from "../Logo"
import Menu from "../../containers/global/Menu"
import Search from "../../containers/global/Search"
import MobileSearchOverlay from "../MobileSearchOverlay"
import MediaQuery from "react-responsive"
import classNames from "classnames/bind"
import styles from "./header"

const cx = classNames.bind(styles)

const Header = ({
  isCms,
  loggedIn,
  isArtist,
  mobileSearchOpen,
  logOut,
  userName,
  handleClick,
  userSlug,
  closeHeaderMenu,
}) => {
  const customBurgerIcon = isCms ? cmsBurgerIcon : burgerIcon
  const containerClasses = {
    container: true,
    isArtist,
    isCms,
    loggedIn,
  }

  return (
    <div>
      <div className={cx(containerClasses)} role="navigation">
        <div className={cx("inner")}>
          <Logo />
          {!isCms && (
            <div>
              <MediaQuery minWidth={937} maxWidth={1294}>
                <div className={cx("tablet-search-btn")}>
                  <button aria-label={"search"} onClick={handleClick} />
                </div>
              </MediaQuery>
              <MediaQuery minWidth={1295}>
                <Search />
              </MediaQuery>
            </div>
          )}
          <MediaQuery minWidth={937}>
            <HeaderInner
              userSlug={userSlug}
              logOut={logOut}
              isCms={isCms}
              isArtist={isArtist}
              userName={userName}
              loggedIn={loggedIn}
            />
          </MediaQuery>
        </div>
      </div>

      <MediaQuery maxWidth={936}>
        <div className={cx("mobile-search-btn")}>
          <button onClick={handleClick} />
        </div>
        <Menu
          className="menu-menu"
          right
          width={272}
          customBurgerIcon={customBurgerIcon}
          customCrossIcon={customCrossIcon}
        >
          <HeaderInner
            userSlug={userSlug}
            logOut={logOut}
            isCms={isCms}
            isArtist={isArtist}
            userName={userName}
            loggedIn={loggedIn}
          />
        </Menu>
      </MediaQuery>
      {mobileSearchOpen && (
        <MobileSearchOverlay customCrossIcon={customCrossIcon} handleClick={handleClick} />
      )}
    </div>
  )
}

export default Header

const burgerIcon = (
  <svg width="26" height="22" viewBox="0 0 26 22" xmlns="http://www.w3.org/2000/svg">
    <path fill="#4A4A4A" d="M0 0h26v2H0zM0 10h26v2H0zM0 20h26v2H0z" />
  </svg>
)

const cmsBurgerIcon = (
  <svg width="26" height="22" viewBox="0 0 26 22" xmlns="http://www.w3.org/2000/svg">
    <path fill="#4A4A4A" d="M0 0h26v2H0zM0 10h26v2H0zM0 20h26v2H0z" />
  </svg>
)

const customCrossIcon = (
  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="2" stroke="#4A4A4A" fill="none" fillRule="evenodd">
      <path d="M20.808 20.808l-9.836-9.836 9.836-9.836M1.136 1.136l9.836 9.836-9.836 9.836" />
    </g>
  </svg>
)
