import {
  isObject,
  isArray,
  camelCase,
  snakeCase,
  isFunction,
  isError,
  repeat,
} from "lodash"

import { appUrl } from "./services/env"

export function flog(arg) {
  console.log.apply(null, arguments)
  return arg
}

export function createActions(mapping) {
  let types = {},
    actions = {},
    rest = Array.prototype.slice.call(arguments, 1)

  Object.keys(mapping).forEach(property => {
    types[property] = property
    actions[camelCase(property)] = function() {
      const result = mapping[property].apply(mapping[property], arguments)
      if (isFunction(result)) {
        return result
      } else {
        const { payload, meta } = result
        return { payload, meta, type: property, error: isError(payload) }
      }
    }
  })

  rest.forEach(property => {
    types[property] = property
    actions[camelCase(property)] = x => ({ type: property, payload: x })
  })

  return { types, actions }
}

export function snakeKeys(object) {
  if (!isObject(object)) {
    return object
  }

  return deepObjectReduce(object, snakeCase)
}

export function camelKeys(object) {
  if (!isObject(object)) {
    return object
  }

  return deepObjectReduce(object, camelCase)
}

function deepObjectReduce(object, transform) {
  return Object.entries(object).reduce(mapValue, {})

  function mapValue(obj, [k, v]) {
    obj[transform(k)] = toValue(v)
    return obj
  }

  function toValue(v) {
    if (isArray(v)) {
      return v.map(toValue)
    } else if (isObject(v)) {
      return deepObjectReduce(v, transform)
    } else {
      return v
    }
  }
}

export function serializeForm(form) {
  let data = {}
  const inputs = [].slice.call(
    form.querySelectorAll(
      'input[type="text"], input[type="email"], input[type="password"], input[type="number"],  select, textarea, input[type="radio"]:checked'
    )
  )
  inputs.forEach(input => {
    if (input.name) {
      data[input.name] = input.value
    }
  })

  return data
}

export function checkForCoupon(form) {
  const input = form.querySelector("input[name=coupon]")
  if (!input) {
    return null
  }

  return input.value
}

export function getYears(count) {
  const currentYear = new Date().getFullYear()
  const years = Array.from(new Array(count), (val, index) => {
    return currentYear - index
  })
  return years
}

export function displayDimensions(dimensions) {
  // Transforms array [h, w, d] or [h, w] to "h x w in." or "h x w x d in."
  // Input: Array; Output: String
  // @note: doesn't work on old factory artwork
  if (dimensions && dimensions.length) {
    return dimensions.reduce((sum, val, i) => {
      return `${sum} x ${val} ${i === dimensions.length - 1 ? "in." : ""}`
    })
  } else {
    return ""
  }
}

export function displayVideoDuration(minutes, seconds) {
  // Transforms video duration values to "mm:ss" with leading zeros
  // Input: Array; Output: String
  if (minutes && seconds) {
    return `${minutes}:${seconds.padStart(2, "0")}`
  } else {
    return ""
  }
}

export function toGraduationString({ year, degreeType, school }) {
  const isCandidate = new Date() < new Date(`${year}-05-31`)
  return `${year} ${degreeType}${isCandidate ? " Candidate" : ""}, ${
    school.name
  }`
}

export function pxToBottomOfWindow(elem) {
  const viewport = window.innerHeight
  const bottom = elem.offsetTop + elem.offsetHeight
  return viewport - bottom
}

export function estimateUploadSpeed(transferByteSize = 1e5) {
  return new Promise((resolve, reject) => {
    const startTime = (new Date()).getTime()
    let endTime
    const dataSample = repeat(".", transferByteSize)

    fetch(`${appUrl}/test_upload`, {
      method: "POST",
      cache: "no-cache",
      body: dataSample,
    }).then(() => {
      endTime = (new Date()).getTime()

      const elapsed = (endTime - startTime) / 1000

      resolve(transferByteSize / elapsed)
    })
  })
}
