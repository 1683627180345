import React, { useRef } from "react"
import { connect } from "react-redux"
import { useMutation, gql } from "@apollo/client"
import classNames from "classnames/bind"
import { actions } from "../../../actions.js"
import SchoolSelect from "../../../components/SchoolSelect"
import ProfileFormHeading from "../../../components/ProfileFormHeading"
import Tooltip from "../../../components/Tooltip"
import styles from "./school-form"

const cx = classNames.bind(styles)

const CreateGraduation = gql`
  mutation CreateGraduation($id: ID!, $degreeType: String!, $schoolId: String!, $year: String!) {
    createGraduation(id: $id, degreeType: $degreeType, schoolId: $schoolId, year: $year) {
      id
    }
  }
`

const copy = `
If you are a student or alumni of a graduate or undergraduate studio art program, 
you can add that school to your artist profile. Adding a school enables your profile 
and works to appear in search results when people search Foundwork by school, degree type, or class year.
`

const SchoolForm = ({
  onCancel,
  userId,
  schoolId,
  degreeType,
  year,
  setAsSaving,
  setAsNotSaving,
  setErrors,
  schools,
  onChange,
  onAddSchool,
  saving,
  active,
}) => {
  const [mutate] = useMutation(CreateGraduation)
  const form = useRef(null)

  const handleCancel = (e) => {
    e.preventDefault()
    form.current.reset()
    onCancel()
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    setAsSaving()

    mutate({
      variables: {
        id: userId,
        schoolId,
        degreeType,
        year,
      },
      refetchQueries: ["ProfileWrapperQuery"],
    })
      .then((...args) => {
        form.current.reset()
        setAsNotSaving.call(null, args)
      })
      .catch(setErrors)
  }

  return (
    <div className={cx({ "school-form": true, "is-active": active })}>
      <div className={cx("add-school")}>
        <Tooltip copy={copy} />
        <button onClick={onAddSchool} className={cx("btn-blue", "btn", "add-school-button")}>
          Add a School
        </button>
      </div>

      <form ref={form} onSubmit={handleSubmit}>
        <div className={cx("heading")}>
          <ProfileFormHeading label={"Add School Information"} buttonText={"save"} saving={saving} />
        </div>
        <SchoolSelect onChange={onChange} onCancel={handleCancel} schools={schools} />
      </form>
    </div>
  )
}

const mapStateToProps = ({ schoolForm, session: { userId } }) => {
  return { userId, ...schoolForm }
}

const mapDispatchToProps = (dispatch) => {
  const {
    openSchoolForm,
    closeSchoolForm,
    updateSchool,
    resetSchool,
    beginSchoolMutation,
    endSchoolMutation,
  } = actions

  return {
    onAddSchool: () => dispatch(openSchoolForm()),
    onChange: (payload) => dispatch(updateSchool(payload)),
    setErrors: (payload) => dispatch(endSchoolMutation({ error: payload })),
    setAsSaving: () => dispatch(beginSchoolMutation()),
    setAsNotSaving: (res) => {
      dispatch(endSchoolMutation(res))
      if (!res.error) {
        dispatch(resetSchool())
        dispatch(closeSchoolForm())
      }
    },
    onCancel: () => {
      dispatch(resetSchool())
      dispatch(closeSchoolForm())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolForm)
