import { types } from "../actions"

const defaultState = {
  activeTab: "works"
}

const artistDetail = (state = defaultState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_ARTIST_DETAIL_TAB:
      return {
        ...state,
        activeTab: payload
      }
    default:
      return state
  }
}

export default artistDetail
