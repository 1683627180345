import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

const Subtitle = styled.h2`
  ${globalStylesCss};

  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-large);
  color: var(--font-color-secondary);
  text-align: center;
  line-height: normal;
`

export default Subtitle
