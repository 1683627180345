import React from "react"
import Input from "../Input"
import ProfileFormHeading from "../ProfileFormHeading"
import PasswordUpdateFormWrapper from "../../containers/settings/PasswordUpdateForm"
import { string, bool, func } from "prop-types"
import styles from "./personal-settings"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const PersonalSettings = ({
  firstName,
  lastName,
  email,
  open,
  saving,
  handleSubmit,
  toggleOpen,
  handleChange
}) => (
  <div>
    <form onSubmit={handleSubmit}>
      <ProfileFormHeading
        label={"Personal Information"}
        saving={saving}
        buttonText={"Save"}
        buttonStyle={"gray"}
      />
      <div className={cx("inputs-container")}>
        <Input
          id="firstName"
          name="firstName"
          type="text"
          value={firstName}
          handleChange={handleChange}
          label="First Name"
          required={true}
          className={"narrow"}
        />
        <Input
          id="lastName"
          name="lastName"
          type="text"
          value={lastName}
          handleChange={handleChange}
          label="Last Name"
          required={true}
          className={"narrow"}
        />
        <Input
          id="email"
          name="email"
          type="email"
          value={email}
          handleChange={handleChange}
          label="Email Address"
          required={true}
        />
        <div className={cx("fake-input-container")}>
          <div className={cx("label")}>Password</div>
          <div className={cx("input")}>
            <div>••••••••</div>
            <button
              className={"btn btn-gray"}
              onClick={e => {
                e.preventDefault()
                toggleOpen()
              }}
            >
              {open ? "Close" : "Edit"}
            </button>
          </div>
        </div>
        {open && <PasswordUpdateFormWrapper toggleOpen={toggleOpen} />}
      </div>
    </form>
  </div>
)

PersonalSettings.propTypes = {
  firstName: string,
  lastName: string,
  email: string,
  open: bool,
  saving: bool,
  handleSubmit: func,
  toggleOpen: func,
  handleChange: func
}

export default PersonalSettings
