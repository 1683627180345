import React from "react"
import { string, oneOfType, number, func } from "prop-types"
import { InputWrapper } from "../Input"
import styles from "../Input/input"
import classNames from "classnames/bind"
import PlacesAutocomplete from "react-places-autocomplete"

const cx = classNames.bind(styles)

const InputAddress = ({
  name,
  label,
  value,
  handleChange,
  handleBlur,
  handleSelect,
  required,
  className,
}) => {
  const AutocompleteItem = ({ formattedSuggestion }) => {
    let { mainText, secondaryText } = formattedSuggestion
    secondaryText =
      secondaryText && secondaryText.includes("United States") ? secondaryText.split(",")[0] : secondaryText

    return `${mainText}${secondaryText ? `, ${secondaryText}` : ""}`
  }

  return (
    <InputWrapper name={name} label={label} className={className}>
      <PlacesAutocomplete
        searchOptions={{
          types: ["(regions)"],
          location: new window.google.maps.LatLng(41, 74),
          radius: 200000,
        }}
        autocompleteItem={AutocompleteItem}
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              id={name}
              name={name}
              placeholder={"Enter city or town"}
              required={required}
            />
            {
              (loading || !!suggestions.length) && (

              <div className={cx("autocomplete-dropdown-container")}>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        </PlacesAutocomplete>
    </InputWrapper>
  )
}

InputAddress.propTypes = {
  name: string,
  label: string,
  className: string,
  value: oneOfType([string, number]),
  handleChange: func.isRequired,
}

export default InputAddress
