import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"

const useCreditCardProcessing = () => {
  const stripe = useStripe()
  const stripeElements = useElements()

  if (!stripe || !stripeElements) {
    return () => { return Promise.resolve(null)}
  }

  return () => {
    const stripeElement = stripeElements.getElement(CardElement)

    if (!stripeElement) {
      throw new Error("Cannot find credit card input element in DOM descendants")
    }

    return stripe.createToken(stripeElement)
  }
}

export default useCreditCardProcessing
