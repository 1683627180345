import React from "react"
import ReactMarkdown from "react-markdown"
import Helmet from "../Helmet"

import styles from "./terms"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

export default ({ content, sections, title }) => (
  <div className={cx("terms")}>
    <Helmet title={`Foundwork | Terms of Use`} />
    <div className={cx("intro")}>
      <h1>{title}</h1>
      <ReactMarkdown children={content} />
    </div>

    <ol className={cx("sections")}>
      {sections.map(section => (
        <Section key={section.id} {...section} />
      ))}
    </ol>
  </div>
)

const Section = ({ id, title, content, sections }) => (
  <li key={id}>
    <span className={cx("title")}>{title}</span>

    <ReactMarkdown className={cx("content")} children={content} />

    <ol className="section">
      {sections.map(section => (
        <Section key={section.id} {...section} />
      ))}
    </ol>
  </li>
)
