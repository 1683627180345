import React from "react"
import {gql, useQuery} from "@apollo/client"
import {connect} from "react-redux"
import {actions} from "../../../actions"
import CurrentUser from "../../../currentUser"
import {push} from "connected-react-router"
import {homeRoute} from "../../../constants"
import {cancelAccount} from "../../../services/auth"
import CancelMembership from "../../../components/CancelMembership"

export const currentUserQuery = gql`
    query CurrentUserQuery($id: ID!) {
        user(id: $id) {
            id
        }
    }
`

const CancelMembershipContainer = ({id, ...cancelingProps}) => {
  const response = useQuery(currentUserQuery, {
    variables: {id},
  })
  const {data} = response || {}
  const {user} = data || {}

  return user ? <CancelMembership {...cancelingProps} /> : null
}

const mapStateToProps = ({settings, session}) => {
  const {
    accountInfo: {canceling}
  } = settings

  return {
    id: session.userId,
    canceling,
  }
}
const mapDispatchToProps = dispatch => {
  const {
    logOut,
    toggleCanceling,
  } = actions

  return {
    cancelAccount: () => {
      if (
        window.confirm(
          "Click the OK button below to confirm your account cancelation. You can reactivate your account any time by logging back in to Foundwork."
        )
      ) {
        const cb = res => {
          const {err, response} = res
          if (err || !response.ok) {
            dispatch(toggleCanceling())
            return
          }

          dispatch(toggleCanceling())
          CurrentUser.clear()
          dispatch(logOut())
          dispatch(push(homeRoute))
        }

        dispatch(toggleCanceling())
        cancelAccount(cb)
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelMembershipContainer)
