import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./dialogues-carousel"
import ReactMarkdown from "react-markdown"

const cx = classNames.bind(styles)

const DialoguesCarouselSlide = ({ image, slug, title, content }) => (
  <div className={cx("dialogue-card")}>
    <div className={cx("caption")}>
      <h1 className={cx("interview-name")}>
        <Link to={slug}>{title}</Link>
      </h1>
      <ReactMarkdown className={cx("interview-details")} children={content} escapeHtml={false} />
      <p className={cx("interview-link")}>
        <Link to={slug}>Read the Interview</Link>
      </p>
    </div>

    <div className={cx("dialogue-image")}>
      <Link to={slug} aria-label={title}>
        <img src={`${image && image.url}`} alt={image && image.title} />
      </Link>
    </div>
  </div>
)

export default DialoguesCarouselSlide
