const config = {
  graphqlUrl: process.env.GRAPHQL_URL,
  websocketUrl: process.env.WEBSOCKET_URL,
  appUrl: process.env.APP_URL || "APP_URL not set!!!",
  stripe: {
    subscriptionsKey: process.env.STRIPE_PUBLISHABLE_KEY,
    donationsKey: process.env.STRIPE_DONATIONS_PUBLISHABLE_KEY,
  },
  basicAuth: {
    enabled: process.env.BASIC_AUTH_ENABLED == "true",
    credentials: `${process.env.HTTP_BASIC_USER}:${process.env.HTTP_BASIC_PASSWORD}`,
  },
  paypal: {
    clientId: process.env.PAYPAL_CLIENT_ID || "PAYPAL_CLIENT_ID not set!!!",
    subscriptionPlanId: process.env.PAYPAL_PLAN_ID || "PAYPAL_PLAN_ID not set!!!",
  },
}

export default config
