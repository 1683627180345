import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./guest-curator-carousel"

const cx = classNames.bind(styles)

const CuratorCarouselSlide = ({ image, slug, title, content }) => (
  <div className={cx("guest-curator-card")}>
    <div className={cx("guest-curator-image")}>
      <Link to={slug} aria-label={title}>
        <img src={`${image && image.url}`} alt={image && image.title} />
      </Link>
    </div>

    <div className={cx("caption")}>
      <h1 className={cx("curator-name")}>
        <Link to={slug}>{title}</Link>
      </h1>
      <p className={cx("curator-details")}>{content}</p>
      <p className={cx("curator-link")}>
        <Link to={slug}>See Featured Artists</Link>
      </p>
    </div>
  </div>
)

export default CuratorCarouselSlide
