import React from "react"
import styles from "./full-overlay"
import { object } from "prop-types"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const FullOverlay = ({ children }) => (
  <div className={cx("container")}>
    <div className={cx("inner")}>{children}</div>
  </div>
)

FullOverlay.propTypes = {
  children: object
}

export default FullOverlay
