import api, { SectionResponse } from "@/api"
import { useEffect } from "react"

const useContentfulSection = (slug: string, onUpdate: (section: SectionResponse) => void) => {
  useEffect(() => {
    ;(async () => {
      const { error, section } = await api.fetchSection(slug)

      if (error) {
        console.error(`Couldn't fetch "${slug}" page content: ${error}`)
        return
      }

      onUpdate(section)
    })()
  }, [])
}

export default useContentfulSection
