import React, { Component, useEffect } from "react"
import { connect } from "react-redux"
import { useQuery } from "@apollo/client"
import classNames from "classnames/bind"
import DetailHeader from "../DetailHeader"
import Helmet from "../../../components/Helmet"
import styles from "./detail-wrapper"
import { ArtistDetailQuery } from "../../../queries/artists.js"
import DetailWorks from "../DetailWorks"
import DetailCv from "../DetailCv"
import DetailStatement from "../DetailStatement"
import { actions } from "../../../actions"

const cx = classNames.bind(styles)

const DetailWrapper = ({
  toggleArtistDetailTabAction,
  closeArtworkPreview,
  slug,
  activeTab,
  userId,
  previewIndex
}) => {
  useEffect(() => {
    toggleArtistDetailTabAction("works")
    closeArtworkPreview()
  }, [])

  const response = useQuery(ArtistDetailQuery, {
    variables: { slug, userId }
  })
  const { data } = response || {}
  const { artist } = data || {}

  return (
    <div className={cx("container")}>
      {artist ? (
        <React.Fragment>
          <Helmet
            title={`${artist.name} | Foundwork`}
            description={`View the artist profile and artwork of ${artist.name} on Foundwork.`}
          />
          <DetailHeader
            user={artist}
            activeTab={activeTab}
            toggleArtistDetailTab={toggleArtistDetailTabAction}
          />
        </React.Fragment>
      ) : null}
      {artist ? (
        <div className={cx("content")}>
          {activeTab === "works" && (
            <DetailWorks slug={slug} previewIndex={previewIndex} />
          )}
          {activeTab === "cv" && <DetailCv user={artist} />}
          {activeTab === "statement" && <DetailStatement user={artist} />}
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = ({ session, artistDetail, artworkExplore }) => {
  const { userId } = session
  const { previewIndex } = artworkExplore
  const { activeTab } = artistDetail

  return { previewIndex, userId, activeTab }
}

const mapDispatchToProps = dispatch => {
  const { toggleArtistDetailTab } = actions
  const { closeArtworkPreview } = actions

  return {
    toggleArtistDetailTabAction: tab => dispatch(toggleArtistDetailTab(tab)),
    closeArtworkPreview: () => {
      const element = document.getElementsByTagName("html")[0]
      element.classList.remove("locked")
      dispatch(closeArtworkPreview())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailWrapper)
