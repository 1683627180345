import React from "react"
import ReactMarkdown from "react-markdown"
import LoadingSpinner from "../LoadingSpinner"

import styles from "./about"
import classNames from "classnames/bind"
import Helmet from "../Helmet"

const cx = classNames.bind(styles)

const AboutPage = ({ content, sections, title }) => (
  <div>
    <Helmet title={`Foundwork | About`} />
    {content ? (
      <div className={cx("about")}>
        <div className={cx("intro")}>
          <h1>{title}</h1>
          <ReactMarkdown className={cx("intro-content")} children={content} />
        </div>
        <div className={cx("sections")}>
          {sections.map((section, index) => (
            <Section key={index} {...section} />
          ))}
        </div>
      </div>
    ) : (
      <div className={cx("spinner")}>
        <LoadingSpinner />
      </div>
    )}
  </div>
)

const Section = ({ title, content, image }) => (
  <div className={cx("section")}>
    <div className={cx("inner")}>
      <div className={cx(["column", "text"])}>
        <div className={cx("content")}>
          <h2>{title}</h2>
          <ReactMarkdown className={cx("section-content")} children={content} />
        </div>
      </div>
      <div className={cx(["column", "image"])}>
        <img src={image.url} alt={image.title} />
      </div>
    </div>
  </div>
)

export default AboutPage
