import styled, { css } from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

export enum LabelSize {
  SMALL,
  NORMAL,
}

const labelSizeRules = {
  [LabelSize.SMALL]: css`font-size: var(--font-size-small);`,
  [LabelSize.NORMAL]: css`font-size: var(--font-size-primary);`,
}
export const Label = styled.label<{ size: LabelSize }>`
  ${globalStylesCss};

  ${({ size }) => labelSizeRules[size]}
  color: var(--font-color-secondary);
  letter-spacing: var(--letter-spacing-large);
  margin-top: var(--margin-primary); // TODO Do we even need this?
`
