import React, { ComponentType } from "react"
import StripeDonationsConnector from "./StripeDonationsConnector"

const withStripeDonationConnector =
  <P,>(Component: ComponentType<P>) =>
  (props: P & React.JSX.IntrinsicAttributes) =>
    (
      <StripeDonationsConnector>
        <Component {...props} />
      </StripeDonationsConnector>
    )

export default withStripeDonationConnector
