import React from "react"
import { connect } from "react-redux"
import { actions } from "../../../actions"
import styles from "./profile-image-wrapper"
import classNames from "classnames/bind"
import ProfileImageFormContainer from "../../../containers/cms/ProfileImageFormContainer"
import ProfileFormHeading from "../../../components/ProfileFormHeading"

const cx = classNames.bind(styles)

const copy = `
   Your profile image is part of your profile preview card on Foundwork. It must be an artwork image and can be any of 
   the works with still images (not videos) that you add in CMS. If you click Select and don't see any options, this is 
   because you haven't added any works with still images yet.
`

const ProfileImageWrapper = ({
  formIsOpen,
  toggleModal,
  firstName,
  lastName,
  profileImage
}) => {
  const imgSrc = profileImage ? profileImage.thumb : null
  const hasProfileImage = imgSrc !== "/profile_images/thumb/missing.png"

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault()
          toggleModal()
        }}
      >
        <ProfileFormHeading label={"Profile Image"} buttonText={"select"} tooltip={copy} />
        <div className={cx("body")}>
          {formIsOpen && <ProfileImageFormContainer />}
          {hasProfileImage && (
            <img
              className={cx("profile-img")}
              alt={`${firstName} ${lastName}`}
              src={imgSrc}
            />
          )}
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  const { isOpen } = state.profileImageForm
  return {
    formIsOpen: isOpen
  }
}

const mapDispatchToProps = dispatch => {
  const { toggleProfileImageForm } = actions
  return {
    toggleModal: () => dispatch(toggleProfileImageForm())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileImageWrapper)
