import React, { useState, useEffect } from "react"
import styles from "./video"
import classNames from "classnames/bind"
import ReactPlayer from "react-player"
import { videoPreviewInformation } from "../../services/videoEmbed"

const cx = classNames.bind(styles)

const Video = ({
  url,
  width,
  height,
  controls,
  showVideoThumbnail = true,
  playing = false
}) => {
  if (!url) return null

  const [light, setLight] = useState(showVideoThumbnail)

  useEffect(() => {
    const setVideoThumbnail = async () => {
      const { available, thumbnailUrl } = await videoPreviewInformation(url)

      setLight(
        available ?
        showVideoThumbnail && (thumbnailUrl || "/video_preview_placeholder.svg") :
        "/video_unavailable.svg"
      )
    }

    setVideoThumbnail()
  })

  return (
    <div style={{ width, height, margin: "auto" }}>
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        controls={controls}
        light={light}
        playing={playing}
      />
    </div>
  )
}

export default Video
